import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { ApplicationCluster } from 'src/app/core/models/applicationCluster.mode';
import { Cluster } from 'src/app/core/models/cluster.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
  AddApplicationAction,
  AddClusterAction,
  DeleteApplicationAction,
  DeleteClusterAction,
  LoadClustersAction,
  ResetApplicationMessagesAction,
  SaveClusterAction,
} from 'src/app/core/store/application/actions';
import { State } from 'src/app/core/store/state';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { DialogEditApplicationComponent } from '../dialog-edit-application/dialog-edit-application.component';
import { DialogEditClusterComponent } from '../dialog-edit-cluster/dialog-edit-cluster.component';

@Component({
  selector: 'rh-admincenter-applications',
  templateUrl: './applications.component.html',
  styleUrls: ['./applications.component.scss'],
  imports: [TranslateModule, LoadingComponent],
})
export class ApplicationsComponent implements OnInit, OnDestroy {
  clusters$!: Observable<Cluster[]>;
  clusters?: Cluster[];
  isLoading$!: Observable<boolean>;
  isLoading?: boolean;
  error$!: Observable<string>;
  success$!: Observable<string>;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<State>,
    public dialog: DialogService,
    private snackbar: SnackbarService,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store$.select((state) => {
      return state.Application.isLoading as boolean;
    });

    this.subscriptions.add(
      this.isLoading$?.subscribe((data: boolean) => {
        this.isLoading = data;
      }),
    );

    this.error$ = this.store$.select((state) => {
      return state.Application.error as string;
    });

    this.subscriptions.add(
      this.error$?.subscribe((error: string) => {
        if (error) {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `${error}`,
          });
        }
      }),
    );

    this.success$ = this.store$.select((state) => {
      return state.Application.success as string;
    });

    this.subscriptions.add(
      this.success$?.subscribe((success: string) => {
        if (success) {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: `${success}`,
          });
        }
      }),
    );

    this.clusters$ = this.store$.select((state) => {
      return state.Application.clusters as Cluster[];
    });

    this.subscriptions.add(
      this.clusters$?.subscribe((data: Cluster[]) => {
        if (data) {
          this.clusters = data.sort((a, b) => a.name.localeCompare(b.name));

          this.clusters.forEach((cluster) => {
            cluster.applications = cluster.applications.sort((a, b) =>
              a.name.localeCompare(b.name),
            );
          });
        }
      }),
    );

    this.store$.dispatch(new LoadClustersAction());
  }

  async addCluster(): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditClusterComponent, {
      data: {
        applicationCluster: {
          id: 0,
          name: '',
        } as ApplicationCluster,
        title: 'clusterPopup.addClusterTitle',
      },
    });

    const result = (await lastValueFrom(
      dialogRef.afterClosed(),
    )) as ApplicationCluster;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new AddClusterAction(result.name));
    }
  }

  async addApplication(): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditApplicationComponent, {
      data: {
        applicationName: '',
        clusters: this.clusters,
        title: 'applicationPopup.addApplicationTitle',
      },
    });
    const result = (await lastValueFrom(dialogRef.afterClosed())) as {
      clusterId: number;
      name: string;
    };

    if (!result) {
      return;
    } else {
      this.store$.dispatch(
        new AddApplicationAction(result.clusterId, result.name),
      );
    }
  }

  async deleteApplication(
    clusterId: number,
    applicationId: number,
  ): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'deletePopup.deleteApplication.title',
        message: 'deletePopup.deleteApplication.message',
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    } else {
      this.store$.dispatch(
        new DeleteApplicationAction(clusterId, applicationId),
      );
    }
  }

  async editCluster(cluster: ApplicationCluster): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditClusterComponent, {
      data: {
        applicationCluster: cluster,
        title: 'clusterPopup.editClusterTitle',
      },
    });

    const result = (await lastValueFrom(
      dialogRef.afterClosed(),
    )) as ApplicationCluster;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new SaveClusterAction(result));
    }
  }

  openApplicationDetails(applicationId: number): void {
    this.router.navigate(['/applications', applicationId]);
  }

  async deleteCluster(cluster: ApplicationCluster): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'deletePopup.deleteCluster.title',
        message: 'deletePopup.deleteCluster.message',
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new DeleteClusterAction(cluster.id));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dialog.close();
    this.store$.dispatch(new ResetApplicationMessagesAction());
  }
}
