import { ApplicationRoles } from '../../models/applicationRoles.model';
import { Cluster } from '../../models/cluster.model';
import { Feature } from '../../models/feature.model';
import { Role } from '../../models/role.model';

export interface State {
  clusters?: Cluster[];
  applicationsRoles?: ApplicationRoles[];
  roles?: Role[];
  features?: Feature[];
  isLoading?: boolean;
  error?: string;
  success?: string;
}

export const initialState: State = {};
