import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { State } from 'src/app/core/store/state';
import {
  LoadUsersAction,
  ResetUserStoreAction,
} from 'src/app/core/store/user/actions';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

@Component({
  selector: 'rh-admincenter-user',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  imports: [LoadingComponent, CommonModule],
})
export class UsersComponent implements OnInit, OnDestroy {
  users$!: Observable<User[]>;
  users?: User[] = [];
  isLoading$!: Observable<boolean>;
  isLoading?: boolean;
  error$!: Observable<string>;
  error?: string;

  sortNameReverse = false;
  sortEmailReverse = false;
  sortCompanyReverse = false;
  sortUnitReverse = false;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<State>,
    public dialog: DialogService,
    private snackbar: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store$.select((state) => {
      return state.User.isLoading as boolean;
    });

    this.subscriptions.add(
      this.isLoading$?.subscribe((data: boolean) => {
        this.isLoading = data;
      }),
    );

    this.error$ = this.store$.select((state) => {
      return state.User.error as string;
    });

    this.subscriptions.add(
      this.error$?.subscribe((data: string) => {
        if (data) {
          this.error = data;
          console.error(this.error);
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `${this.error}`,
          });
        }
      }),
    );

    this.users$ = this.store$.select((state) => {
      return state.User.users as User[];
    });

    this.subscriptions.add(
      this.users$?.subscribe((data: User[]) => {
        if (data) {
          this.users = data;

          this.sortUsersByName();
        }
      }),
    );

    this.store$.dispatch(new LoadUsersAction());
  }

  sortUsersByName() {
    this.users = this.users?.sort((a, b) =>
      (b.givenName + ' ' + b.familyName).localeCompare(
        a.givenName + ' ' + a.familyName,
      ),
    );

    if (!this.sortNameReverse) {
      this.users = this.users?.reverse();
    }
    this.sortNameReverse = !this.sortNameReverse;
    this.sortEmailReverse = false;
    this.sortCompanyReverse = false;
    this.sortUnitReverse = false;
  }

  sortUsersByEmail() {
    this.users = this.users?.sort((a, b) => a.email.localeCompare(b.email));

    if (!this.sortEmailReverse) {
      this.users = this.users?.reverse();
    }
    this.sortEmailReverse = !this.sortEmailReverse;
    this.sortNameReverse = false;
    this.sortCompanyReverse = false;
    this.sortUnitReverse = false;
  }

  sortUsersByUnit() {
    this.users = this.users?.sort((a, b) =>
      a.unitName.localeCompare(b.unitName),
    );

    if (!this.sortUnitReverse) {
      this.users = this.users?.reverse();
    }
    this.sortUnitReverse = !this.sortUnitReverse;
    this.sortUnitReverse = false;
    this.sortNameReverse = false;
  }

  sortUsersByCompany() {
    this.users = this.users?.sort((a, b) =>
      a.companyName.localeCompare(b.companyName),
    );

    if (!this.sortCompanyReverse) {
      this.users = this.users?.reverse();
    }
    this.sortCompanyReverse = !this.sortCompanyReverse;
    this.sortUnitReverse = false;
    this.sortNameReverse = false;
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dialog.close();
    this.store$.dispatch(new ResetUserStoreAction());
  }
}
