import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { UserService } from '../../services/user/user.service';
import {
  ActionTypes,
  LoadUsersAction,
  LoadUsersFailureAction,
  LoadUsersSuccessAction,
} from './actions';

@Injectable()
export class UserStoreEffects {
  constructor(
    private userService: UserService,
    private actions$: Actions,
  ) {}

  loadUsersEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadUsersAction>(ActionTypes.LOAD_USERS),
      exhaustMap(() =>
        this.userService.GetUsers().pipe(
          map((items) => new LoadUsersSuccessAction(items)),
          catchError((error) => of(new LoadUsersFailureAction(error.error))),
        ),
      ),
    ),
  );
}
