<div class="flex w-full flex-col gap-4">
    <div class="flex w-fit gap-4">
      <div class="w-44">
        <rh-dropdown
          [items]="filterCompanyNames"
          [model]="filterCompany?.name"
          (modelChange)="filterByCompany($event)"
          [title]="'machines.company' | translate"
          [placeholder]="'machines.company-filter-placeholder' | translate"
          [invalidIfEmpty]="false"
          [clearable]="true"
        />
      </div>
      @if (filterCompany) {
        <div class="w-44">
          <rh-dropdown
            [items]="filterUnitNames"
            [model]="filterUnit?.name"
            (modelChange)="filterByUnit($event)"
            [title]="'machines.unit' | translate"
            [placeholder]="'machines.unit-filter-placeholder' | translate"
            [invalidIfEmpty]="false"
            [clearable]="true"
          />
        </div>
      }
    </div>

  <ol
    class="grid w-full gap-x-2 divide-y divide-brand-gray-light overflow-x-auto grid-global"
  >
    <li
      class="col-span-full grid grid-cols-subgrid text-left font-brand-thin text-sm font-bold text-brand-blue-360"
    >
      <button
        (click)="sort('machineNumber')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">M-Nr.</p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('machineNumber')"
        />
      </button>

      <button
        (click)="sort('machineAlias')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ "machines.client-alias" | translate }}
        </p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('machineAlias')"
        />
      </button>

      <button
        (click)="sort('company')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ "machines.company" | translate }}
        </p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('company')"
        />
      </button>

      <button
        (click)="sort('unit')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ "machines.unit" | translate }}
        </p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('unit')"
        />
      </button>

      <button
        (click)="sort('type')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ "machines.type" | translate }}
        </p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('type')"
        />
      </button>

      <button
        (click)="sort('ip')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ "machines.ip" | translate }}
        </p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('ip')"
        />
      </button>

      <button
        (click)="sort('proxy')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ "machines.proxy-id" | translate }}
        </p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('proxy')"
        />
      </button>

      <p class="overflow-hidden text-ellipsis whitespace-nowrap">
        {{ "machines.mobile" | translate }}
      </p>

      <button
        (click)="sort('argus')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">Argus</p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('argus')"
        />
      </button>

      <button
        (click)="sort('maas')"
        class="flex items-center gap-1 hover:text-brand-blue-600"
      >
        <p class="overflow-hidden text-ellipsis whitespace-nowrap">
          {{ "machines.maas" | translate }}
        </p>
        <img
          src="/assets/icons/chevron-down.svg"
          width="20px"
          alt=""
          [ngClass]="getSortIconClass('maas')"
        />
      </button>

      <p></p>
    </li>

    @for (machine of filteredMachines; track machine.id) {
      @let editMode =
        (editingMachine && machine.id === editingMachine.id) || false;
      @let machineModel = editMode ? editingMachine! : machine;
      <li
        [ngClass]="{
          'bg-brand-blue-550 text-white': editMode,
          'hover:bg-brand-blue-lighter': !editMode,
        }"
        class="col-span-full grid grid-cols-subgrid items-center py-1 text-base text-brand-blue-800"
      >
        <input
          [disabled]="!editMode"
          type="number"
          [(ngModel)]="machineModel.machineNumber"
          [title]="machineModel.machineNumber"
          [ngClass]="{
            border: editMode,
            'border-red-500': !machineModel.machineNumber && editMode,
          }"
          class="h-8 w-full min-w-12 max-w-16 text-ellipsis border border-brand-blue-240 bg-inherit focus:outline-none"
        />

        <input
          [disabled]="!editMode"
          type="text"
          [(ngModel)]="machineModel.machineAlias"
          [title]="machineModel.machineAlias"
          [ngClass]="{
            border: editMode,
            'border-red-500': !machineModel.machineAlias && editMode,
          }"
          class="h-8 w-full min-w-12 max-w-40 text-ellipsis border border-brand-blue-240 bg-inherit focus:outline-none"
        />

        <rh-dropdown
          [blueMode]="editMode"
          [disabled]="!editMode"
          [invalidIfEmpty]="true"
          [items]="companyNames"
          [model]="machineModel.organizationalContext.companyName"
          (modelChange)="selectCompany($event)"
          class="w-full min-w-14 max-w-40"
        />

        <rh-dropdown
          [blueMode]="editMode"
          [disabled]="!editMode"
          [invalidIfEmpty]="true"
          [items]="unitNames"
          [model]="machineModel.organizationalContext.unitName"
          (modelChange)="selectUnit($event)"
          class="w-full min-w-14 max-w-40"
        />

        <rh-dropdown
          [blueMode]="editMode"
          [disabled]="!editMode"
          [invalidIfEmpty]="true"
          [items]="machineTypes"
          [(model)]="machineModel.type"
          class="w-full min-w-14 max-w-36"
        />

        <input
          [disabled]="!editMode"
          type="text"
          [(ngModel)]="machineModel.ipAddress"
          [title]="machineModel.ipAddress || ''"
          class="h-8 w-full min-w-12 max-w-32 text-ellipsis border border-brand-blue-240 bg-transparent focus:bg-transparent focus:outline-none"
          [ngClass]="{ border: editMode }"
        />

        <rh-dropdown
          [blueMode]="editMode"
          [disabled]="!editMode"
          [items]="getProxyItems(machineModel.organizationalContext.unitId)"
          [(model)]="machineModel.proxyId!"
          [withInput]="true"
          class="w-full min-w-14 max-w-40"
        />

        <div class="w-full min-w-8 max-w-10">
          <button
            [disabled]="!editMode"
            (click)="updateIsMobile()"
            class="flex aspect-square w-6 shrink-0 items-center border border-brand-blue-240 bg-white p-1 focus:outline-none focus:ring-0 has-[img]:border-none has-[img]:bg-brand-warning"
          >
            @if (machineModel.isMobile) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </button>
        </div>

        <div class="flex w-52 items-center gap-2">
          <input
            [disabled]="!editMode"
            type="date"
            id="startDate"
            class="h-8 w-32 rounded-md border border-brand-blue-240 bg-inherit px-2 text-left text-base focus:outline-none focus:ring-0"
            [value]="machineModel.argusLicenseValidTo | date: 'yyyy-MM-dd'"
            (change)="editArgusValidTo($event)"
            required
          />

          <button
            [disabled]="!editMode"
            (click)="updateArgusInvoice()"
            class="flex h-6 w-6 shrink-0 items-center border border-brand-blue-240 bg-white p-1 focus:outline-none focus:ring-0 has-[img]:border-none has-[img]:bg-brand-warning"
          >
            @if (machineModel.argusInvoice) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </button>

          @switch (getArgusStatus(machineModel)) {
            @case ("DANGER") {
              <div
                class="aspect-square w-6 bg-brand-danger"
                title="DANGER"
              ></div>
            }
            @case ("WARNING") {
              <div
                class="aspect-square w-6 bg-brand-warning"
                title="WARNING"
              ></div>
            }
            @case ("OK") {
              <div class="aspect-square w-6 bg-brand-ok" title="OK"></div>
            }
            @default {
              <div
                class="aspect-square w-6 bg-brand-default"
                title="DEFAULT"
              ></div>
            }
          }
        </div>

        <div class="flex w-52 items-center gap-2">
          <input
            [disabled]="!editMode"
            type="date"
            id="startDate"
            class="h-8 w-32 rounded-md border border-brand-blue-240 bg-inherit px-2 text-left text-base focus:outline-none focus:ring-0"
            [value]="machineModel.maasLicenseValidTo | date: 'yyyy-MM-dd'"
            (change)="editMaasValidTo($event)"
            required
          />

          <button
            [disabled]="!editMode"
            (click)="updateMaasInvoice()"
            class="flex h-6 w-6 shrink-0 items-center border border-brand-blue-240 bg-white p-1 focus:outline-none focus:ring-0 has-[img]:border-none has-[img]:bg-brand-warning"
          >
            @if (machineModel.maasInvoice) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </button>

          @switch (getMaasStatus(machineModel)) {
            @case ("DANGER") {
              <div
                class="aspect-square w-6 bg-brand-danger"
                [title]="'machines.machine-status.danger' | translate"
              ></div>
            }
            @case ("WARNING") {
              <div
                class="aspect-square w-6 bg-brand-warning"
                [title]="'machines.machine-status.warning' | translate"
              ></div>
            }
            @case ("OK") {
              <div
                class="aspect-square w-6 bg-brand-ok"
                [title]="'machines.machine-status.ok' | translate"
              ></div>
            }
            @default {
              <div
                class="aspect-square w-6 bg-brand-default"
                [title]="'machines.machine-status.default' | translate"
              ></div>
            }
          }
        </div>

        <div class="flex gap-2">
          @if (editMode) {
            <button
              (click)="cancelEdit()"
              class="flex h-[38px] w-12 items-center justify-center rounded-md border border-brand-gray-light p-1 px-1 hover:bg-brand-blue-800"
              [title]="'machines.operation.cancel' | translate"
            >
              <img
                class="aspect-auto w-[22px]"
                src="/assets/icons/Close.svg"
                [alt]="'machines.operation.cancel' | translate"
              />
            </button>
            <button
              (click)="saveMachine()"
              class="flex h-[38px] w-12 items-center justify-center rounded-md border border-brand-gray-light p-1 px-1 hover:bg-brand-blue-800"
              [title]="'machines.operation.save' | translate"
            >
              <img
                class="aspect-auto w-[22px]"
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                [alt]="'machines.operation.save' | translate"
              />
            </button>
          } @else {
            <button
              (click)="enterEditMode(machine)"
              class="flex h-[38px] w-12 items-center justify-center rounded-md border border-brand-gray-light bg-white p-1 px-1 hover:bg-brand-blue-light"
              [title]="'machines.operation.edit' | translate"
            >
              <img
                src="/assets/icons/Reishauer_Icon_Edit.svg"
                [alt]="'machines.operation.edit' | translate"
                class="aspect-auto w-[22px] fill-brand-blue-900"
              />
            </button>
            <button
              (click)="deleteMachine(machine)"
              class="flex h-[38px] w-12 items-center justify-center rounded-md border border-brand-gray-light bg-white p-1 px-1 hover:bg-brand-blue-light"
              [title]="'machines.operation.delete' | translate"
            >
              <img
                src="/assets/icon-delete.svg"
                [alt]="'machines.operation.delete' | translate"
                class="aspect-auto w-[22px] fill-brand-red"
              />
            </button>
          }
        </div>
      </li>
    }
  </ol>
</div>
