import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { User } from '@auth0/auth0-angular';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import {
  ChangeLanguageAction,
  LogOutUserAction,
} from 'src/app/core/store/auth/actions';
import { State } from 'src/app/core/store/state';
import { LanguageDropdownMobileComponent } from '../language-dropdown-mobile/language-dropdown-mobile.component';
import { TranslateModule } from '@ngx-translate/core';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'rh-admincenter-user-menu',
  templateUrl: './user-menu.component.html',
  styleUrls: ['./user-menu.component.css'],
  imports: [LanguageDropdownMobileComponent, TranslateModule, CommonModule],
})
export class UserMenuComponent implements OnInit {
  private subscriptions: Subscription = new Subscription();

  @Output() toggleUserMenuEvent = new EventEmitter<void>();

  user$?: Observable<User | undefined>;
  user?: User;

  isPortrait = false;
  isLandscape = false;

  language$!: Observable<string>;
  selectedLanguage = 'en';

  constructor(
    private store$: Store<State>,
    private responsive: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isPortrait =
            breakpoints[Breakpoints.HandsetPortrait] ||
            breakpoints[Breakpoints.TabletPortrait];
          this.isLandscape = breakpoints[Breakpoints.HandsetLandscape];
        }),
    );

    this.user$ = this.store$.select((state) => {
      return state.Auth.user;
    });

    this.subscriptions.add(
      this.user$?.subscribe((user: User | undefined) => {
        if (user) {
          this.user = user;
        }
      }),
    );
  }

  toggleUserMenu(): void {
    this.toggleUserMenuEvent.emit();
  }

  changeLanguage(lang: string): void {
    if (!this.user) return;
    this.store$.dispatch(new ChangeLanguageAction(lang));
  }

  logout(): void {
    localStorage.clear;
    this.store$.dispatch(new LogOutUserAction());
  }
}
