<div
  class="fixed bottom-[5%] left-1/2 z-50 flex -translate-x-1/2 items-center rounded-md p-4"
  [ngClass]="{
    'bottom-16 bg-red-300 text-black': data.type === snackbarTypes.Warning,
    'bottom-16 bg-red-600 text-white': data.type === snackbarTypes.Error,
    'bottom-16 bg-brand-blue-800 text-white': data.type === snackbarTypes.Info
  }"
>
  <p class="mr-4">{{ data.text | translate }}</p>
</div>
