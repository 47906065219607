import { Action } from '@ngrx/store';
import { Machine } from '../../models/machine.model';

export enum ActionTypes {
  LOAD_MACHINES = '[Machine] Load Machines',
  LOAD_MACHINES_SUCCESS = '[Machine] Load Machines Success',
  LOAD_MACHINES_FAILURE = '[Machine] Load Machines Failure',
  RESET_MACHINE_STORE = '[Machine] Reset Machine Store',
}

export class LoadMachinesAction implements Action {
  readonly type = ActionTypes.LOAD_MACHINES;
}

export class LoadMachinesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MACHINES_SUCCESS;
  constructor(public machines: Machine[]) {}
}

export class LoadMachinesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MACHINES_FAILURE;
  constructor(public error: string) {}
}

export class ResetUserStoreAction implements Action {
  readonly type = ActionTypes.RESET_MACHINE_STORE;
}

export type Actions =
  | LoadMachinesAction
  | LoadMachinesSuccessAction
  | LoadMachinesFailureAction
  | ResetUserStoreAction;
