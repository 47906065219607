import { Auth0User } from '../../models/auth0User.model';
import { LoggedUserPermissionsMisc } from '../../models/loggedUserPermissionsMisc.model';
export interface State {
  user?: Auth0User;
  error?: string;
  isLoading?: boolean;
  permissions?: LoggedUserPermissionsMisc;
}

export const initialState: State = {
  error: '',
  isLoading: true,
};
