import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { EffectsModule } from '@ngrx/effects';
import { StoreModule } from '@ngrx/store';
import { StoreDevtoolsModule } from '@ngrx/store-devtools';
import { environment } from 'src/environments/environment';
import { SharedModule } from '../shared/shared.module';
import {
  ApplicationStoreModule,
  AuthStoreModule,
  BreadcrumbStoreModule,
  OrganizationStoreModule,
  UserStoreModule,
} from './store';

@NgModule({
  imports: [
    CommonModule,
    StoreModule.forRoot(
      {},
      {
        runtimeChecks: {
          strictStateImmutability: false,
          strictActionImmutability: false,
        },
      },
    ),
    AuthStoreModule,
    OrganizationStoreModule,
    ApplicationStoreModule,
    BreadcrumbStoreModule,
    UserStoreModule,
    EffectsModule.forRoot([]),
    StoreDevtoolsModule.instrument({
      maxAge: 25, // Retains last 25 states
      logOnly: environment.production, // Restrict extension to log-only mode
    }),
    SharedModule,
  ],
})
export class CoreModule {}
