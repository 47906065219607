import { inject, Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { catchError, exhaustMap, map, of } from 'rxjs';
import { MachineService } from '../../services/machine/machine.service';
import {
  ActionTypes,
  LoadMachinesAction,
  LoadMachinesFailureAction,
  LoadMachinesSuccessAction,
} from './actions';

@Injectable()
export class MachineStoreEffects {
  private machineService = inject(MachineService);
  private actions$ = inject(Actions);

  loadMachinesEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadMachinesAction>(ActionTypes.LOAD_MACHINES),
      exhaustMap(() =>
        this.machineService.GetMachines().pipe(
          map((items) => new LoadMachinesSuccessAction(items)),
          catchError((error) => of(new LoadMachinesFailureAction(error.error))),
        ),
      ),
    ),
  );
}
