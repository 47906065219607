import { Action } from '@ngrx/store';
import { User } from '../../models/user.model';

export enum ActionTypes {
  LOAD_USERS = '[User] Load Users',
  LOAD_USERS_SUCCESS = '[User] Load Users Success',
  LOAD_USERS_FAILURE = '[User] Load Users Failure',
  RESET_USER_STORE = '[User] Reset User Store',
}

export class LoadUsersAction implements Action {
  readonly type = ActionTypes.LOAD_USERS;
}

export class LoadUsersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_USERS_SUCCESS;
  constructor(public users: User[]) {}
}

export class LoadUsersFailureAction implements Action {
  readonly type = ActionTypes.LOAD_USERS_FAILURE;
  constructor(public error: string) {}
}

export class ResetUserStoreAction implements Action {
  readonly type = ActionTypes.RESET_USER_STORE;
}

export type Actions =
  | LoadUsersAction
  | LoadUsersSuccessAction
  | LoadUsersFailureAction
  | ResetUserStoreAction;
