@let isLoading = isLoading$ | async;
@if (!isLoading && application) {
  <div class="flex flex-col gap-12">
    <div class="flex flex-col rounded-lg bg-white p-10 shadow-md">
      <div class="flex justify-between">
        <h2
          class="font-brand-thin text-[34px] leading-[34px] text-brand-blue-800"
        >
          {{ application.name }}
        </h2>
        <div class="flex gap-4">
          <div class="flex items-center">
            <button
              (click)="addFeature()"
              class="flex items-center gap-2 rounded-md border bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
            >
              <div>{{ "applicationDetails.addFeatureButton" | translate }}</div>
              <div>
                <img
                  class="h-6 w-6"
                  src="/assets/icons/Reishauer_Icons-Add.svg"
                  alt="Icon add"
                />
              </div>
            </button>
          </div>
          <button
            (click)="editApplication()"
            class="relative flex h-9 items-center rounded-md border border-brand-gray-light bg-white px-3 py-2 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
          >
            <img
              src="/assets/icons/Reishauer_Icon_Edit.svg"
              alt="Edit Application"
              class="fill-brand-primary h-6 w-auto"
            />
          </button>
          <button
            (click)="deleteApplication()"
            class="relative flex h-9 items-center rounded-md border border-brand-gray-light bg-white px-3 py-2 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
          >
            <img
              src="/assets/icon-delete.svg"
              alt="Delete Application"
              class="h-6 w-auto"
            />
          </button>
        </div>
      </div>
      <div class="flex gap-2">
        <button
          (click)="toggleHasMachines()"
          class="flex h-full w-fit items-center justify-between gap-2 p-2 text-[#949494] has-[img]:bg-[#E3F1D1] has-[img]:text-[#69932f]"
        >
          <div
            class="flex aspect-square w-[34px] shrink-0 items-center rounded-sm border border-black bg-white p-2 has-[img]:border-none has-[img]:bg-[#86a337]"
          >
            @if (application.hasMachines) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </div>
          <p class="w-full text-left">
            {{ "applicationDetails.hasMachines" | translate }}
          </p>
        </button>
        <button
          (click)="toggleMultipleRolesAllowed()"
          class="flex h-full w-fit items-center justify-between gap-2 p-2 text-[#949494] has-[img]:bg-[#E3F1D1] has-[img]:text-[#69932f]"
        >
          <div
            class="flex aspect-square w-[34px] shrink-0 items-center rounded-sm border border-black bg-white p-2 has-[img]:border-none has-[img]:bg-[#86a337]"
          >
            @if (application.multiRolesAllowed) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </div>
          <p class="w-full text-left">
            {{ "applicationDetails.multipleRolesAllowed" | translate }}
          </p>
        </button>
      </div>
    </div>
    @for (feature of features; track feature) {
      <div class="flex flex-col gap-12 rounded-lg bg-white p-10 shadow-md">
        <div class="flex justify-between">
          <h2
            class="flex gap-3 font-brand-light text-[26px] uppercase leading-[26px] text-brand-title"
          >
            {{ feature.name }}
            <p class="text-[11px]">[{{ feature.key }}]</p>
          </h2>
          <div class="flex gap-4">
            <div class="flex items-center">
              <button
                (click)="addSubFeature(feature.id)"
                class="flex items-center gap-2 rounded-md border bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
              >
                <div>
                  {{ "applicationDetails.addSubFeatureButton" | translate }}
                </div>
                <div>
                  <img
                    class="h-6 w-6"
                    src="/assets/icons/Reishauer_Icons-Add.svg"
                    alt="Icon add"
                  />
                </div>
              </button>
            </div>
            <button
              (click)="editFeature(feature)"
              class="relative flex h-9 items-center rounded-md border border-brand-gray-light bg-white px-3 py-2 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
            >
              <img
                src="/assets/icons/Reishauer_Icon_Edit.svg"
                alt="Edit Feature"
                class="fill-brand-primary h-6 w-auto"
              />
            </button>
            <button
              (click)="deleteFeature(feature.id)"
              class="relative flex h-9 items-center rounded-md border border-brand-gray-light bg-white px-3 py-2 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
            >
              <img
                src="/assets/icon-delete.svg"
                alt="Delete Feature"
                class="h-6 w-auto"
              />
            </button>
          </div>
        </div>
        <div>
          @if (feature.subFeatures && feature.subFeatures.length > 0) {
            <table
              class="min-w-full divide-y divide-brand-gray-light rounded-md font-brand-regular text-[13px] leading-[13px]"
            >
              <thead>
                <tr>
                  <th
                    class="w-3/12 border-b border-brand-gray-light pl-2 pr-3 text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue sm:w-1/6 sm:px-2"
                  >
                    {{ "shared.tables.tableHeaderDisplayName" | translate }}
                  </th>
                  <th
                    class="w-3/12 border-b border-brand-gray-light pl-2 pr-3 text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue sm:w-1/6 sm:px-2"
                  >
                    {{ "shared.tables.tableHeaderTechnicalName" | translate }}
                  </th>
                  <th
                    class="w-full border-b-0 text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue"
                  ></th>
                </tr>
              </thead>
              <tbody
                class="divide-y divide-brand-gray-light align-middle text-brand-blue-800"
              >
                @for (subFeature of feature.subFeatures; track subFeature) {
                  <tr class="cursor-pointer hover:bg-brand-blue-lighter">
                    <td class="w-3/12 px-2 py-1">
                      {{ subFeature.name }}
                    </td>
                    <td class="w-3/12 px-2 py-1">
                      {{ subFeature.key }}
                    </td>
                    <td class="flex w-full items-center justify-end py-1">
                      <div class="flex gap-4 pl-4">
                        <button
                          (click)="editSubFeature(subFeature)"
                          class="relative flex h-8 w-12 items-center justify-center rounded-md border border-brand-gray-light bg-white py-4 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
                        >
                          <img
                            src="/assets/icons/Reishauer_Icon_Edit.svg"
                            alt="Edit Application"
                            class="fill-brand-primary h-6 w-auto"
                          />
                        </button>
                        <button
                          (click)="deleteSubFeature(feature.id, subFeature.id)"
                          class="relative flex h-8 w-12 items-center justify-center rounded-md border border-brand-gray-light bg-white py-4 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
                        >
                          <img
                            src="/assets/icon-delete.svg"
                            alt="Delete SubFeature"
                            class="h-6 w-auto"
                          />
                        </button>
                      </div>
                    </td>
                  </tr>
                }
              </tbody>
            </table>
          }
          @if (!feature.subFeatures || feature.subFeatures.length === 0) {
            <div class="w-full text-center">
              {{ "errors.tables.noSubFeatures" | translate }}
            </div>
          }
        </div>
      </div>
    }
    @if (roles) {
      <div>
        <div class="flex flex-col gap-12 rounded-lg bg-white p-10 shadow-md">
          <div class="flex justify-between">
            <h2
              class="font-brand-light text-[26px] uppercase leading-[26px] text-brand-title"
            >
              {{ "applicationDetails.roles" | translate }}
            </h2>
            <div class="flex items-center">
              <button
                (click)="addRole()"
                class="flex items-center gap-2 rounded-md border bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
              >
                <div>{{ "applicationDetails.addRoleButton" | translate }}</div>
                <div>
                  <img
                    class="h-6 w-6"
                    src="/assets/icons/Reishauer_Icons-Add.svg"
                    alt="Icon add"
                  />
                </div>
              </button>
            </div>
          </div>
          <div>
            @if (roles && roles.length > 0) {
              <table
                class="min-w-full divide-y divide-brand-gray-light rounded-md font-brand-regular text-[13px] leading-[13px]"
              >
                <thead>
                  <tr>
                    <th
                      scope="col"
                      class="w-3/12 text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue"
                    >
                      {{ "shared.tables.tableHeaderDisplayName" | translate }}
                    </th>
                    <th
                      scope="col"
                      class="w-3/12 text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue"
                    >
                      {{ "shared.tables.tableHeaderDescription" | translate }}
                    </th>
                    <th
                      scope="col"
                      class="w-3/12 text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue"
                    >
                      {{ "shared.tables.tableHeaderTechnicalName" | translate }}
                    </th>
                    <th
                      scope="col"
                      class="w-full text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue"
                    ></th>
                  </tr>
                </thead>
                <tbody
                  class="divide-y divide-brand-gray-light align-middle text-brand-blue-800"
                >
                  @for (role of roles; track role) {
                    <tr class="cursor-pointer hover:bg-brand-blue-lighter">
                      <td class="w-3/12 px-2 py-1">
                        {{ role.name }}
                      </td>
                      <td class="w-3/12 py-1 pr-2">
                        {{ role.description }}
                      </td>
                      <td class="w-3/12 py-1 pr-2">
                        {{ role.key }}
                      </td>
                      <td class="flex w-full items-center justify-end py-1">
                        <div class="flex gap-4 pl-4">
                          <button
                            (click)="editRole(role)"
                            class="relative flex h-8 w-12 items-center justify-center rounded-md border border-brand-gray-light bg-white py-4 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
                          >
                            <img
                              src="/assets/icons/Reishauer_Icon_Edit.svg"
                              alt="Edit Application"
                              class="fill-brand-primary h-6 w-auto"
                            />
                          </button>
                          <button
                            (click)="deleteRole(role.id)"
                            class="relative flex h-8 w-12 items-center justify-center rounded-md border border-brand-gray-light bg-white py-4 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
                          >
                            <img
                              src="/assets/icon-delete.svg"
                              alt="Delete Feature"
                              class="h-6 w-auto"
                            />
                          </button>
                        </div>
                      </td>
                    </tr>
                  }
                </tbody>
              </table>
            }
            @if (!roles || roles.length === 0) {
              <div class="w-full text-center">
                {{ "errors.tables.noRoles" | translate }}
              </div>
            }
          </div>
        </div>
      </div>
    }
  </div>
}

@if (isLoading) {
  <div class="loading-spinner-container">
    <rh-admincenter-loading></rh-admincenter-loading>
  </div>
}
