import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Router, RouterLink, RouterLinkActive } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Auth0User } from 'src/app/core/models/auth0User.model';
import { LoggedUserPermissionsMisc } from 'src/app/core/models/loggedUserPermissionsMisc.model';
import {
  ChangeLanguageAction,
  LogOutUserAction,
} from 'src/app/core/store/auth/actions';
import { State } from 'src/app/core/store/state';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'rh-admincenter-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss'],
  imports: [TranslateModule, CommonModule, RouterLink, RouterLinkActive],
})
export class SidebarComponent implements OnInit, OnDestroy {
  user$!: Observable<Auth0User>;
  user?: Auth0User;
  language$!: Observable<string>;
  selectedLanguage = 'en';
  myReishauerUrl = environment.applicationUrl;

  private subscriptions: Subscription = new Subscription();

  @Output() toggleSidebarEvent = new EventEmitter<void>();
  @Input() sidebarOpen = false;
  permissions$?: Observable<LoggedUserPermissionsMisc>;

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  constructor(
    private store$: Store<State>,
    private responsive: BreakpointObserver,
    private router: Router,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];
        }),
    );

    this.user$ = this.store$.select((state) => {
      return state.Auth.user as Auth0User;
    });

    this.permissions$ = this.store$.select((state) => {
      return state.Auth.permissions as LoggedUserPermissionsMisc;
    });

    this.subscriptions.add(
      this.user$?.subscribe((user: Auth0User) => {
        if (user) {
          this.user = user;
          this.selectedLanguage = user.language || 'en';
        }
      }),
    );
  }

  onLogoClick(): void {
    if (
      this.router.url === '/' ||
      this.router.url === '/organizations' ||
      this.router.url === '/applications'
    ) {
      window.location.href = this.myReishauerUrl;
    } else {
      this.router.navigate(['/']);
    }
  }

  toggleSidebar(): void {
    this.toggleSidebarEvent.emit();
  }

  changeLanguage(lang: string): void {
    if (!this.user) return;
    this.store$.dispatch(new ChangeLanguageAction(lang));
  }

  logout(): void {
    this.store$.dispatch(new LogOutUserAction());
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
