@if (!isLoading) {
  <div class="flex flex-col gap-8">
    @if (organization?.permissions?.canCreateUnit) {
      <button
        (click)="addUnit()"
        class="flex w-fit items-center gap-2 rounded-md border border-brand-gray-light bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
      >
        <div>
          {{ "companyDetail.addUnitButton" | translate }}
        </div>
        <div>
          <img
            class="h-6 w-6"
            src="/assets/icons/Reishauer_Icons-Add.svg"
            alt="Icon add"
          />
        </div>
      </button>
    }
    @if (organization) {
      <!-- Unit Block -->
      @for (unit of units; track unit) {
        <div class="flex flex-col divide-y rounded-lg bg-white shadow-xl">
          <!-- Unit Header -->
          <div class="flex min-h-16 items-baseline py-3.5 pl-8 pr-4">
            <div class="flex w-2/12 shrink-0 items-center gap-5 pr-4">
              <img
                src="/assets/icons/Reishauer_Icon_Company.svg"
                width="30px"
                alt="company"
              />
              <h2 class="break-all font-brand-regular text-xl text-[#3c5792]">
                {{ unit.name }}
              </h2>
            </div>
            <div class="flex grow gap-5">
              <div class="w-1/2">
                <p class="text-sm uppercase text-[#3c5792]">Academy</p>
              </div>
              <div class="w-1/2">
                <p class="text-sm uppercase text-[#3c5792]">Monitoring</p>
              </div>
            </div>
            <div class="shrink-0 items-center">
              <div class="flex w-full justify-end">
                @if (organization.permissions.canCreateUnit) {
                  <button
                    (click)="editUnit(unit)"
                    class="flex h-9 items-center rounded-md border border-brand-gray-light bg-white px-3 py-2 hover:bg-brand-blue-light"
                  >
                    <img
                      class="h-6 w-auto"
                      src="/assets/icons/Reishauer_Icons-Edit.svg"
                      alt="Icon edit"
                    />
                  </button>
                }
              </div>
            </div>
          </div>
          <rh-admincenter-unit-clusters
            [organizationId]="organizationId"
            [clusters]="clusters"
            [unitId]="unit.id"
            [canUpdateLicences]="organization.permissions.canUpdateLicences"
            [machines]="unit.machines"
          />
          <rh-admincenter-unitusers
            [organizationId]="organizationId"
            [unitId]="unit.id"
            [clusters]="clusters"
            [rolesByApplication]="rolesByApplication"
            [canAddUsers]="unit.permissions.canAddUsers"
          />
        </div>
      }
    }
  </div>
}

@if (isLoading) {
  <div class="loading-spinner-container">
    <rh-admincenter-loading></rh-admincenter-loading>
  </div>
}
