<div class="relative z-10">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div
      class="flex min-h-full items-center justify-center p-4 text-center sm:p-0"
      >
      <div
        class="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-3xl"
        >
        <!--Box side-->
        <div
          class="absolute left-0 top-0 z-40 h-full w-[30px] bg-brand-blue-550"
        ></div>

        <button
          (click)="onClose()"
          class="absolute right-[18px] top-[18px] z-[100]"
          >
          <img
            src="/assets/icons/Reishauer_Icons_Close_Blue.svg"
            alt="Close"
            class="h-[20px] w-[20px] fill-brand-gray-dark"
            />
          </button>
          <form [formGroup]="form" (submit)="onSubmit()" class="relative">
            <div
              class="px-4 pb-4 pt-5 sm:pb-[60px] sm:pl-[90px] sm:pr-[60px] sm:pt-10"
              >
              <legend
                class="mb-[55px] flex items-center justify-center text-center font-brand-light text-2xl uppercase leading-6 text-brand-blue-700"
                >
                <img
                  src="/assets/icons/Reishauer_Icon_Edit.svg"
                  alt="Edit"
                  class="fill-brand-primary-selected mr-2 h-8 w-8"
                  />

                  {{ title | translate }}
                </legend>
                <div class="flex flex-col">
                  <label
                    for="name"
                    class="mb-0.5 text-[11px] uppercase leading-[11px] text-brand-blue"
                    >
                    {{ "rolePopup.roleName" | translate }}
                  </label>
                  <input
                    formControlName="name"
                    type="name"
                    name="name"
                    id="name"
                    class="h-9 w-full rounded-none border border-brand-input-primary px-2 py-2 text-sm leading-[17px] text-brand-title hover:border-brand-input-primary-hover focus:rounded-none focus:border-2 focus:outline-brand-input-primary-focus"
                    placeholder=""
                    />
                    @if (name?.errors?.['required']) {
                      <p
                        class="mt-2 text-red-600"
                        id="name-error"
                        >
                        {{ "errors.forms.invalidName" | translate }}
                      </p>
                    }
                  </div>
                  <div class="mt-2 flex flex-col">
                    <label
                      for="description"
                      class="mb-0.5 text-[11px] uppercase leading-[11px] text-brand-blue"
                      >
                      {{ "rolePopup.description" | translate }}
                    </label>
                    <input
                      formControlName="description"
                      type="description"
                      name="description"
                      id="description"
                      class="h-9 w-full rounded-none border border-brand-input-primary px-2 py-2 text-sm leading-[17px] text-brand-title hover:border-brand-input-primary-hover focus:rounded-none focus:border-2 focus:outline-brand-input-primary-focus"
                      placeholder=""
                      />
                      @if (description?.errors?.['required']) {
                        <p
                          class="mt-2 text-red-600"
                          id="description-error"
                          >
                          {{ "errors.forms.invalidDescription" | translate }}
                        </p>
                      }
                    </div>
                    <!-- isDefault not used yet -->
                    <!-- <div class="mt-2 flex flex-col">
                    <div class="flex gap-2">
                      <label
                        for="isDefault"
                        class="mb-0.5 text-[18px] uppercase leading-[18px] text-brand-blue"
                        >
                        {{ "rolePopup.isDefault" | translate }}
                      </label>
                      <input
                        formControlName="isDefault"
                        type="checkbox"
                        name="isDefault"
                        id="isDefault"
                        />
                      </div>
                      <p
                        *ngIf="isDefault?.errors?.['required']"
                        class="mt-2 text-red-600"
                        id="isDefault-error"
                        >
                        {{ "errors.forms.invalidIsDefault" | translate }}
                      </p>
                    </div> -->
                  </div>
                  <div
                    class="mt-5 flex gap-4 border-t border-brand-gray py-6 sm:mt-0 sm:justify-center"
                    >
                    <button
                      (click)="onClose()"
                      type="button"
                      class="flex h-9 w-full items-center justify-between rounded-md bg-brand-red pl-5 pr-2 font-brand-regular text-sm uppercase leading-[14px] text-white md:w-auto"
                      >
                      {{ "shared.cancelButton" | translate }}
                      <img
                        src="/assets/icons/Reishauer_Icons_Close_White.svg"
                        alt="Close"
                        class="ml-[70px] inline-block h-4 w-4 fill-white"
                        />
                      </button>
                      <button
                        [disabled]="!form.valid"
                        [ngClass]="form.valid ? '' : 'cursor-not-allowed opacity-50'"
                        type="submit"
                        class="flex h-9 w-full items-center justify-between rounded-md bg-brand-green pl-5 pr-2 font-brand-regular text-sm uppercase leading-[14px] text-white md:w-auto"
                        >
                        {{ "shared.saveButton" | translate }}
                        <img
                          src="/assets/icons/Reishauer_Icons_Check_White.svg"
                          alt="Check"
                          class="ml-[70px] inline-block h-5 w-5 fill-white"
                          />
                        </button>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
