import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { SubFeature } from 'src/app/core/models/subFeature.model';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-admincenter-dialog-edit-sub-feature',
  templateUrl: './dialog-edit-sub-feature.component.html',
  styleUrls: ['./dialog-edit-sub-feature.component.scss'],
  imports: [TranslateModule, CommonModule, ReactiveFormsModule],
})
export class DialogEditSubFeatureComponent implements OnInit {
  form!: FormGroup;
  subFeature?: SubFeature;
  title!: string;

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      subFeature: SubFeature;
      title: string;
    },
  ) {
    if (data) {
      this.subFeature = Object.assign({}, data.subFeature);
      this.title = data.title;
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.subFeature?.name, Validators.required],
    });
  }

  get name() {
    return this.form.get('name');
  }

  get key() {
    return this.form.get('key');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const subFeature = {
        ...this.subFeature,
        name: this.name?.value,
      } as SubFeature;
      this.dialogRef.close(subFeature);
    }
  }
}
