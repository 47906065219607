import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Organization } from 'src/app/core/models/organization.model';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-admincenter-dialog-edit-company',
  templateUrl: './dialog-edit-company.component.html',
  styleUrls: ['./dialog-edit-company.component.scss'],
  imports: [TranslateModule, ReactiveFormsModule, CommonModule],
})
export class DialogEditCompanyComponent implements OnInit {
  title!: string;
  organization?: Organization;
  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      organization: Organization;
      title: string;
    },
  ) {
    if (data) {
      this.organization = Object.assign({}, data.organization);
      this.title = data.title;
    }
  }

  get name(): AbstractControl | null {
    return this.form.get('name');
  }

  get clientNr(): AbstractControl | null {
    return this.form.get('clientNr');
  }

  get address(): AbstractControl | null {
    return this.form.get('address');
  }

  get addressState(): AbstractControl | null {
    return this.form.get('addressState');
  }

  get addressCountry(): AbstractControl | null {
    return this.form.get('addressCountry');
  }

  get salesContact(): AbstractControl | null {
    return this.form.get('salesContact');
  }

  get salesPhone(): AbstractControl | null {
    return this.form.get('salesContact');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: new FormControl(this.organization?.name, Validators.required),
      clientNr: new FormControl(this.organization?.clientNr),
      address: new FormControl(this.organization?.addressStreet),
      addressState: new FormControl(this.organization?.addressState),
      addressCountry: new FormControl(this.organization?.addressCountry),
      salesContact: new FormControl(this.organization?.salesContact),
      salesPhone: new FormControl(this.organization?.salesPhone),
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const organization = {
        ...this.organization,
        name: this.name?.value,
        clientNr: this.clientNr?.value,
        addressStreet: this.address?.value,
        addressState: this.addressState?.value,
        addressCountry: this.addressCountry?.value,
        salesContact: this.salesContact?.value,
        salesPhone: this.salesPhone?.value,
      } as Organization;
      this.dialogRef.close(organization);
    }
  }
}
