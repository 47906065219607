import { AsyncPipe } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, lastValueFrom, of, switchMap } from 'rxjs';
import { Application } from 'src/app/core/models/application.model';
import { Cluster } from 'src/app/core/models/cluster.model';
import { Feature } from 'src/app/core/models/feature.model';
import { Role } from 'src/app/core/models/role.model';
import { SubFeature } from 'src/app/core/models/subFeature.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
  AddFeatureAction,
  AddRoleAction,
  AddSubFeatureAction,
  DeleteApplicationAction,
  DeleteFeatureAction,
  DeleteRoleAction,
  DeleteSubFeatureAction,
  LoadClustersAction,
  LoadFeaturesByApplicationIdAction,
  LoadRolesByApplicationIdAction,
  ResetApplicationMessagesAction,
  SaveApplicationAction,
  SaveFeatureAction,
  SaveRoleAction,
  SaveSubFeatureAction,
} from 'src/app/core/store/application/actions';
import { State } from 'src/app/core/store/state';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { DialogEditApplicationComponent } from '../dialog-edit-application/dialog-edit-application.component';
import { DialogEditFeatureComponent } from '../dialog-edit-feature/dialog-edit-feature.component';
import { DialogEditRoleComponent } from '../dialog-edit-role/dialog-edit-role.component';
import { DialogEditSubFeatureComponent } from '../dialog-edit-sub-feature/dialog-edit-sub-feature.component';

@Component({
  selector: 'rh-admincenter-applicationdetail',
  templateUrl: './applicationdetail.component.html',
  styleUrls: ['./applicationdetail.component.scss'],
  imports: [LoadingComponent, TranslateModule, AsyncPipe],
})
export class ApplicationDetailComponent implements OnInit, OnDestroy {
  clusterId?: number;
  applicationId!: number;
  application?: Application;
  roles$!: Observable<Role[]>;
  roles?: Role[];
  clusters$!: Observable<Cluster[]>;
  clusters?: Cluster[];
  features$!: Observable<Feature[]>;
  features?: Feature[];
  isLoading$!: Observable<boolean>;
  error$!: Observable<string>;
  success$!: Observable<string>;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    private dialog: DialogService,
    private snackbar: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.route.paramMap
        .pipe(
          switchMap((params) => {
            this.applicationId = Number(params.get('id'));

            this.features = [];
            this.roles = [];

            this.store$.dispatch(
              new LoadFeaturesByApplicationIdAction(this.applicationId),
            );
            this.store$.dispatch(
              new LoadRolesByApplicationIdAction(this.applicationId),
            );
            this.findApplication();

            return of(null);
          }),
        )
        .subscribe(),
    );

    this.isLoading$ = this.store$.select((state) => {
      return state.Application.isLoading as boolean;
    });

    this.error$ = this.store$.select((state) => {
      return state.Application.error as string;
    });

    this.subscriptions.add(
      this.error$?.subscribe((error: string) => {
        if (error) {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `${error}`,
          });
        }
      }),
    );

    this.success$ = this.store$.select((state) => {
      return state.Application.success as string;
    });

    this.subscriptions.add(
      this.success$?.subscribe((success: string) => {
        if (success) {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: `${success}`,
          });
        }
      }),
    );

    this.roles$ = this.store$.select((state) => {
      return state.Application.roles as Role[];
    });

    this.subscriptions.add(
      this.roles$?.subscribe((data: Role[]) => {
        if (data) {
          this.roles = data.sort((a, b) => a.name.localeCompare(b.name));
        }
      }),
    );

    this.features$ = this.store$.select((state) => {
      return state.Application.features as Feature[];
    });

    this.subscriptions.add(
      this.features$?.subscribe((data: Feature[]) => {
        if (data) {
          this.features = data.sort((a, b) => a.name.localeCompare(b.name));

          this.features.forEach((feature) => {
            feature.subFeatures = feature.subFeatures.sort((a, b) =>
              a.name.localeCompare(b.name),
            );
          });
        }
      }),
    );

    this.clusters$ = this.store$.select((state) => {
      return state.Application.clusters as Cluster[];
    });

    this.subscriptions.add(
      this.clusters$?.subscribe((data: Cluster[]) => {
        if (data) {
          this.clusters = data;
          this.findApplication();
        }
      }),
    );

    this.store$.dispatch(new LoadClustersAction());
  }

  findApplication(): void {
    if (!this.clusters) {
      return;
    }

    // Find the cluster and application
    this.clusters?.some((cluster) =>
      cluster.applications.some((app) => {
        if (app.id === this.applicationId) {
          this.clusterId = cluster.id;
          this.application = app;
          return true;
        }
        return false;
      }),
    );

    // If application is not found, handle accordingly
    if (!this.application) {
      this.router.navigate(['/applications']);
      // Handle the case where the application is not found
    }
  }

  async addFeature(): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditFeatureComponent, {
      data: {
        feature: {
          id: 0,
        } as Feature,
        title: 'featurePopup.addFeatureTitle',
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as Feature;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(
        new AddFeatureAction(this.applicationId, result.name),
      );
    }
  }

  async editFeature(feature: Feature): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditFeatureComponent, {
      data: {
        feature: feature,
        title: 'featurePopup.editFeatureTitle',
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as Feature;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new SaveFeatureAction(result));
    }
  }

  async deleteFeature(featureId: number): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: `deletePopup.deleteFeature.title`,
        message: `deletePopup.deleteFeature.message`,
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new DeleteFeatureAction(featureId));
    }
  }

  async addSubFeature(featureId: number): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditSubFeatureComponent, {
      data: {
        subFeature: {
          id: featureId,
          name: '',
          licenceId: 0,
          subFeatures: [],
        } as Feature,
        title: 'subFeaturePopup.addSubFeatureTitle',
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as SubFeature;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(
        new AddSubFeatureAction(this.applicationId, featureId, result.name),
      );
    }
  }

  async editSubFeature(subFeature: SubFeature): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditSubFeatureComponent, {
      data: {
        subFeature: subFeature,
        title: 'subFeaturePopup.editSubFeatureTitle',
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as SubFeature;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new SaveSubFeatureAction(result));
    }
  }

  async deleteSubFeature(
    featureId: number,
    subFeatureId: number,
  ): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: `deletePopup.deleteSubFeature.title`,
        message: `deletePopup.deleteSubFeature.message`,
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new DeleteSubFeatureAction(featureId, subFeatureId));
    }
  }

  async addRole(): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditRoleComponent, {
      data: {
        applicationId: this.applicationId,
        role: {
          id: 0,
          name: '',
          description: '',
          isDefault: false,
        } as Role,
        title: 'rolePopup.addRoleTitle',
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as Role;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new AddRoleAction(this.applicationId, result));
    }
  }

  async editRole(role: Role): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditRoleComponent, {
      data: {
        role,
        title: 'rolePopup.editRoleTitle',
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as Role;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new SaveRoleAction(result));
    }
  }

  async deleteRole(roleId: number): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'deletePopup.deleteRole.title',
        message: 'deletePopup.deleteRole.message',
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new DeleteRoleAction(roleId));
    }
  }

  async deleteApplication(): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: 'deletePopup.deleteApplication.title',
        message: 'deletePopup.deleteApplication.message',
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    } else {
      if (!this.clusterId) {
        return;
      }
      this.store$.dispatch(
        new DeleteApplicationAction(this.clusterId, this.applicationId),
      );
    }
  }

  async editApplication(): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditApplicationComponent, {
      data: {
        applicationName: this.application?.name || '',
        clusters: this.clusters,
        title: 'applicationPopup.editApplicationTitle',
        selectedClusterId: this.clusterId,
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as {
      clusterId: number;
      name: string;
    };

    if (!result) {
      return;
    }

    this.store$.dispatch(
      new SaveApplicationAction(
        this.applicationId,
        result.clusterId,
        result.name,
        this.application?.multiRolesAllowed || false,
        this.application?.hasMachines || false,
      ),
    );
  }

  toggleMultipleRolesAllowed(): void {
    if (this.application) {
      this.store$.dispatch(
        new SaveApplicationAction(
          this.applicationId,
          this.clusterId || 0,
          this.application.name,
          !this.application.multiRolesAllowed,
          this.application.hasMachines,
        ),
      );
    }
  }

  toggleHasMachines(): void {
    if (this.application) {
      this.store$.dispatch(
        new SaveApplicationAction(
          this.applicationId,
          this.clusterId || 0,
          this.application.name,
          this.application.multiRolesAllowed,
          !this.application.hasMachines,
        ),
      );
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dialog.close();
    this.store$.dispatch(new ResetApplicationMessagesAction());
  }
}
