import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Department } from '../../models/department.model';
import { Organization } from '../../models/organization.model';
import { OrganizationFolded } from '../../models/organizationFolded.model';
import { Role } from '../../models/role.model';
import { UnitLicences } from '../../models/unitLicences.model';
import { UnitUsers } from '../../models/unitUsers.model';
import { User } from '../../models/user.model';

@Injectable({
  providedIn: 'root',
})
export class OrganizationService {
  constructor(private http: HttpClient) {}

  // Organization

  GetOrganizations(): Observable<OrganizationFolded[]> {
    return this.http
      .get<
        OrganizationFolded[]
      >(`${environment.backendApiUrl}/api/organizations`)
      .pipe(
        map((response: OrganizationFolded[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetOrganization(organizationId: number): Observable<Organization> {
    return this.http
      .get<Organization>(
        `${environment.backendApiUrl}/api/organizations/${organizationId}`,
      )
      .pipe(
        map((response: Organization) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  AddOrganization(organizationName: string) {
    return this.http
      .post(`${environment.backendApiUrl}/api/organizations`, {
        name: organizationName,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveOrganization(organization: Organization) {
    return this.http
      .put<{
        id: number;
        name: string;
      }>(
        `${environment.backendApiUrl}/api/organizations/${organization.id}/detail`,
        {
          name: organization.name,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  // Unit

  GetUnits(organizationId: number): Observable<UnitLicences[]> {
    return this.http
      .get<
        UnitLicences[]
      >(`${environment.backendApiUrl}/api/organizations/${organizationId}/units`)
      .pipe(
        map((response: UnitLicences[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  AddUnit(organizationId: number, unitName: string) {
    return this.http
      .post(
        `${environment.backendApiUrl}/api/organizations/${organizationId}/units`,
        {
          name: unitName,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveUnit(unit: UnitLicences) {
    return this.http
      .put<{
        id: number;
        name: string;
      }>(`${environment.backendApiUrl}/api/organizations/${unit.id}/units`, {
        name: unit.name,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  // Licences

  AddLicence(
    unitId: number,
    validTo: string,
    featureId: number,
    applicationId: number,
  ) {
    return this.http
      .post<{
        id: number;
        unitId: number;
        clusterId: number;
        applicationId: number;
        featureId: number;
        subFeatureId: number | null;
        isFeatureLicence: boolean;
        validTo: string;
        status: string;
      }>(`${environment.backendApiUrl}/api/organizations/${unitId}/licences`, {
        validTo,
        featureId,
        applicationId,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  EditLicences(
    unitId: number,
    validTo: string,
    isInvoiceSent: boolean,
    applicationId: number,
  ) {
    return this.http
      .put<{
        id: number;
        unitId: null;
        clusterId: number;
        applicationId: number;
        validTo: string;
        isInvoiceSent: boolean;
        status: string;
      }>(`${environment.backendApiUrl}/api/organizations/${unitId}/licences`, {
        validTo,
        isInvoiceSent,
        applicationId,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteLicence(licenceId: number) {
    return this.http
      .delete<undefined>(
        `${environment.backendApiUrl}/api/organizations/licences/${licenceId}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  // Members

  AddMember(unitId: number, member: User) {
    return this.http
      .post<{
        id: number;
        userCount: number;
        email: string;
        givenName: string;
        familyName: string;
        unitId: number;
        department: Department;
        companyName: string;
        unitName: string;
      }>(`${environment.backendApiUrl}/api/organizations/${unitId}/members`, {
        departmentId: member.department.id,
        email: member.email,
        givenName: member.givenName,
        familyName: member.familyName,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveMember(unitId: number, user: User) {
    return this.http
      .put<{
        id: number;
        userCount: number;
        email: string;
        givenName: string;
        familyName: string;
        unitId: number;
        department: Department;
      }>(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${user.id}`,
        {
          departmentId: user.department.id,
          email: user.email,
          givenName: user.givenName,
          familyName: user.familyName,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteMember(unitId: number, userId: number) {
    return this.http
      .delete(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/members`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  SaveMemberRoles(
    unitId: number,
    userId: number,
    applicationId: number,
    roles: number[],
  ) {
    return this.http
      .put<{
        unitId: number;
        userId: number;
        applicationId: number;
        roles: Role[];
      }>(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/roles`,
        { roles, applicationId },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetUsersFromOrganization(companyId: number): Observable<UnitUsers[]> {
    return this.http
      .get<
        UnitUsers[]
      >(`${environment.backendApiUrl}/api/organizations/${companyId}/users`)
      .pipe(
        map((response: UnitUsers[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  // Departments

  GetDepartments(organizationId: number): Observable<Department[]> {
    return this.http
      .get<
        Department[]
      >(`${environment.backendApiUrl}/api/organizations/${organizationId}/departments`)
      .pipe(
        map((response: Department[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  AddDepartment(organizationId: number, departmentName: string) {
    return this.http
      .post<Department>(
        `${environment.backendApiUrl}/api/organizations/${organizationId}/departments`,
        {
          name: departmentName,
        },
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteDepartment(departmentId: number) {
    return this.http
      .delete(
        `${environment.backendApiUrl}/api/organizations/department/${departmentId}`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  // Users

  Reset2fa(unitId: number, userId: number) {
    return this.http
      .delete<undefined>(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/reset-totp`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  BlockUser(unitId: number, userId: number) {
    return this.http
      .put(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/block-user`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  UnblockUser(unitId: number, userId: number) {
    return this.http
      .put(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/unblock-user `,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  ChangePassword(unitId: number, userId: number) {
    return this.http
      .post(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/change-password`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
