import { Machine } from '../../models/machine.model';

export interface State {
  machines?: Machine[];
  isLoading: boolean;
  error?: string;
}

export const initialState: State = {
  isLoading: true,
};
