import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-admincenter-dialog-edit-department',
  templateUrl: './dialog-edit-department.component.html',
  styleUrls: ['./dialog-edit-department.component.scss'],
  imports: [TranslateModule, CommonModule, ReactiveFormsModule],
})
export class DialogEditDepartmentComponent implements OnInit {
  title!: string;
  form!: FormGroup;

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      title: string;
    },
  ) {
    if (data) {
      this.title = data.title;
    }
  }

  get departmentName(): AbstractControl | null {
    return this.form.get('departmentName');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      departmentName: new FormControl(undefined, Validators.required),
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      this.dialogRef.close(this.departmentName?.value);
    }
  }
}
