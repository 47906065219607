@if (!isLoading) {
  <section class="flex flex-col gap-12 bg-white p-10">
    <header>
      <h1
        class="font-brand-light text-2xl uppercase leading-6 text-brand-blue-800"
      >
        Users
      </h1>
    </header>
    <table class="table-fixed border-collapse divide-y">
      <thead>
        <tr>
          <th>
            <div class="flex items-center gap-2 text-start">
              <p>Name</p>
              <button (click)="sortUsersByName()">
                <img
                  src="/assets/icons/chevron-down.svg"
                  width="20px"
                  alt=""
                  class="cursor-pointer"
                  [ngClass]="sortNameReverse ? 'rotate-180' : 'rotate-0'"
                />
              </button>
            </div>
          </th>
          <th>
            <div class="flex items-center gap-2 text-start">
              <p>Email</p>
              <button (click)="sortUsersByEmail()">
                <img
                  src="/assets/icons/chevron-down.svg"
                  width="20px"
                  alt=""
                  class="cursor-pointer"
                  [ngClass]="sortEmailReverse ? 'rotate-180' : 'rotate-0'"
                />
              </button>
            </div>
          </th>
          <th>
            <div class="flex items-center gap-2 text-start">
              <p>Company</p>
              <button (click)="sortUsersByCompany()">
                <img
                  src="/assets/icons/chevron-down.svg"
                  width="20px"
                  alt=""
                  class="cursor-pointer"
                  [ngClass]="sortCompanyReverse ? 'rotate-180' : 'rotate-0'"
                />
              </button>
            </div>
          </th>
          <th>
            <div class="flex items-center gap-2 text-start">
              <p>Unit</p>
              <button (click)="sortUsersByUnit()">
                <img
                  src="/assets/icons/chevron-down.svg"
                  width="20px"
                  alt=""
                  class="cursor-pointer"
                  [ngClass]="sortUnitReverse ? 'rotate-180' : 'rotate-0'"
                />
              </button>
            </div>
          </th>
        </tr>
      </thead>
      <tbody class="divide-y">
        @for (user of users$ | async; track user) {
          <tr>
            <td class="py-1">{{ user.givenName }} {{ user.familyName }}</td>
            <td class="py-1">{{ user.email }}</td>
            <td class="py-1">{{ user.companyName }}</td>
            <td class="py-1">{{ user.unitName }}</td>
          </tr>
        }
      </tbody>
    </table>
  </section>
}

@if (isLoading) {
  <div class="loading-spinner-container">
    <rh-admincenter-loading></rh-admincenter-loading>
  </div>
}
