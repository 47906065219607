import { Component } from '@angular/core';

@Component({
  selector: 'rh-admincenter-loading',
  templateUrl: './loading.component.html',
  styleUrls: ['./loading.component.scss']
})
export class LoadingComponent {

}
