import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AuthService } from '@auth0/auth0-angular';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Auth0User } from '../../models/auth0User.model';
import { LoggedUserPermissionsMisc } from '../../models/loggedUserPermissionsMisc.model';

@Injectable({
  providedIn: 'root',
})
export class AuthenticateService {
  constructor(
    public auth: AuthService,
    private http: HttpClient,
  ) {}

  login(): void {
    this.auth.loginWithRedirect();
  }

  isAuthenticated(): Observable<boolean> {
    return this.auth.isAuthenticated$;
  }

  getUser(): Observable<Auth0User> {
    return this.auth.user$.pipe(
      map((user) => {
        if (!user) {
          return {} as Auth0User;
        }
        return {
          name: user.name,
          email: user.email,
          company: 'reishauer',
          language: user['ui_locales'],
          role: user?.['https://ciam_role'],
        } as Auth0User;
      }),
    );
  }

  logout(): void {
    this.auth.logout({
      logoutParams: {
        returnTo: window.location.origin,
      },
    });
  }

  GetLoggedUserPermissions(): Observable<LoggedUserPermissionsMisc> {
    return this.http
      .get<LoggedUserPermissionsMisc>(
        `${environment.backendApiUrl}/api/permissions/misc`,
      )
      .pipe(
        map((response: LoggedUserPermissionsMisc) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  ChangeLanguage(languageKey: string) {
    return this.http
      .put(`${environment.backendApiUrl}/api/users/change-language`, {
        languageKey,
      })
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
