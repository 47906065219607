import { CommonModule } from '@angular/common';
import { Component, Inject, OnDestroy } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { Department } from 'src/app/core/models/department.model';
import { User } from 'src/app/core/models/user.model';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
  AddDepartmentAction,
  BlockUserAction,
  ChangePasswordAction,
  DeleteMemberAction,
  Reset2faAction,
  UnblockUserAction,
} from 'src/app/core/store/organization/actions';
import { State } from 'src/app/core/store/state';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';
import { DialogEditDepartmentComponent } from '../dialog-edit-department/dialog-edit-department.component';

@Component({
  selector: 'rh-admincenter-dialog-edit-user',
  templateUrl: './dialog-edit-user.component.html',
  styleUrls: ['./dialog-edit-user.component.scss'],
  imports: [TranslateModule, CommonModule, ReactiveFormsModule],
})
export class DialogEditUserComponent implements OnDestroy {
  title!: string;
  organizationId!: number;
  unitId!: number;
  form!: FormGroup;
  user!: User;
  isNew!: boolean;
  departments$!: Observable<Department[]>;
  departments?: Department[];
  permissions!: {
    canUpdateUser: boolean;
    canUpdateMembersDepartment: boolean;
    canDeleteMember: boolean;
  };

  private subscriptions: Subscription = new Subscription();

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    private store$: Store<State>,
    public dialog: DialogService,
    @Inject(DIALOG_DATA)
    public data: {
      user: User;
      title: string;
      organizationId: number;
      unitId: number;
      isNew: boolean;
      permissions: {
        canUpdateUser: boolean;
        canUpdateMembersDepartment: boolean;
        canDeleteMember: boolean;
      };
    },
  ) {
    if (data) {
      this.user = Object.assign({}, data.user);
      this.title = data.title;
      this.organizationId = data.organizationId;
      this.unitId = data.unitId;
      this.isNew = data.isNew;
      this.permissions = data.permissions;

      this.form = this.fb.group({
        givenName: new FormControl(
          {
            value: this.isNew ? undefined : this.user.givenName,
            disabled: !this.permissions.canUpdateUser,
          },
          Validators.required,
        ),
        familyName: new FormControl(
          {
            value: this.isNew ? undefined : this.user.familyName,
            disabled: !this.permissions.canUpdateUser,
          },
          Validators.required,
        ),
        email: new FormControl(
          {
            value: this.isNew ? undefined : this.user.email,
            disabled: !this.permissions.canUpdateUser || !this.isNew,
          },
          [Validators.required, Validators.email],
        ),
        departmentId: new FormControl(
          {
            value: this.isNew ? undefined : this.user.department.id,
            disabled: !this.permissions.canUpdateMembersDepartment,
          },
          Validators.required,
        ),
      });

      this.departments$ = this.store$.select(
        (state) => state.Organization.departments as Department[],
      );

      this.subscriptions.add(
        this.departments$.subscribe((departments) => {
          if (departments) {
            this.departments = departments;
          }
        }),
      );
    }
  }

  get givenName(): AbstractControl | null {
    return this.form.get('givenName');
  }

  get familyName(): AbstractControl | null {
    return this.form.get('familyName');
  }

  get email(): AbstractControl | null {
    return this.form.get('email');
  }

  get departmentId(): AbstractControl | null {
    return this.form.get('departmentId');
  }

  reset2fa() {
    this.store$.dispatch(new Reset2faAction(this.unitId, this.user.id));
    this.dialogRef.close();
  }

  resetPassword() {
    this.store$.dispatch(new ChangePasswordAction(this.unitId, this.user.id));
    this.dialogRef.close();
  }

  blockUser() {
    this.store$.dispatch(new BlockUserAction(this.unitId, this.user.id));
    this.dialogRef.close();
  }

  unblockUser() {
    this.store$.dispatch(new UnblockUserAction(this.unitId, this.user.id));
    this.dialogRef.close();
  }

  async addDepartment() {
    const dialogRef = this.dialog.open(DialogEditDepartmentComponent, {
      data: {
        title: 'departmentPopup.addDepartmentTitle',
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as string;
    if (!result) {
      return;
    } else {
      this.store$.dispatch(
        new AddDepartmentAction(this.organizationId, result),
      );
    }
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const user = {
        ...this.user,
        givenName: this.givenName?.value,
        familyName: this.familyName?.value,
        email: this.email?.value,
        department: {
          id: this.departmentId?.value,
        },
      };
      this.dialogRef.close(user);
    }
  }

  async deleteMember(): Promise<void> {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: `deletePopup.deleteUser.title`,
        message: `deletePopup.deleteUser.message`,
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new DeleteMemberAction(this.unitId, this.user.id));
      this.dialogRef.close();
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
