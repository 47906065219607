import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable, catchError, map, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { LoggedUserPermissionsMisc } from '../../models/loggedUserPermissionsMisc.model';
import { User } from '../../models/user.model';
import { UserRole } from '../../models/userRole.model';

@Injectable({
  providedIn: 'root',
})
export class UserService {
  constructor(private http: HttpClient) {}

  GetUsers(): Observable<User[]> {
    return this.http.get<User[]>(`${environment.backendApiUrl}/api/users`).pipe(
      map((response: User[]) => {
        return response;
      }),
      catchError((error: HttpErrorResponse) => {
        return throwError(error);
      }),
    );
  }

  GetUser(userId: number): Observable<User> {
    return this.http
      .get<User>(`${environment.backendApiUrl}/api/users/details/${userId}`)
      .pipe(
        map((response: User) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetUserRoles(userId: number): Observable<UserRole[]> {
    return this.http
      .get<
        UserRole[]
      >(`${environment.backendApiUrl}/api/users/${userId}/member-roles`)
      .pipe(
        map((response: UserRole[]) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetLoggedUserPermissions(): Observable<LoggedUserPermissionsMisc> {
    return this.http
      .get<LoggedUserPermissionsMisc>(
        `${environment.backendApiUrl}/api/permissions/misc`,
      )
      .pipe(
        map((response: LoggedUserPermissionsMisc) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteUser(userId: number): Observable<undefined> {
    return this.http
      .delete<undefined>(`${environment.backendApiUrl}/api/users/${userId}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  BlockUser(unitId: number, userId: number): Observable<undefined> {
    return this.http
      .put<undefined>(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/block-user`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  UnblockUser(unitId: number, userId: number): Observable<undefined> {
    return this.http
      .put<undefined>(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/unblock-user `,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  Reset2fa(unitId: number, userId: number): Observable<undefined> {
    return this.http
      .delete<undefined>(
        `${environment.backendApiUrl}/api/organizations/${unitId}/users/${userId}/reset-totp`,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  PasswordReset(userId: number): Observable<undefined> {
    return this.http
      .post<undefined>(
        `${environment.backendApiUrl}/api/users/${userId}/change-password`,
        {},
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
