<!-- Clusters Collapsed -->
<button class="flex min-h-16 w-full items-center pl-8 pr-4" (click)="toggle()">
  <div class="flex w-2/12 shrink-0 items-center gap-5 py-2 pr-4">
    <img
      src="/assets/icons/Reishauer_Icon_Apps.svg"
      width="30px"
      alt="applications"
    />
    <h2
      class="hyphens-auto text-wrap break-words text-left font-brand-regular text-xl text-[#7a93cb]"
    >
      {{ "unitApplications.title" | translate }}
    </h2>
  </div>
  @let clustersForOverview = getClustersForOverview();

  @if (clustersForOverview) {
    <div class="flex grow gap-5">
      <div class="flex w-1/2 items-center gap-5 font-medium">
        <ng-container
          [ngTemplateOutlet]="tplApplicationOverview"
          [ngTemplateOutletContext]="{
            a: clustersForOverview[0],
          }"
        />
      </div>
      <div class="flex w-1/2 items-center gap-5 font-medium">
        <ng-container
          [ngTemplateOutlet]="tplApplicationOverview"
          [ngTemplateOutletContext]="{
            a: clustersForOverview[1],
          }"
        />
      </div>
    </div>
  }

  <div class="flex w-[50px] shrink-0 justify-end">
    <img
      src="/assets/icons/chevron-down.svg"
      width="22px"
      alt="chevron"
      [ngClass]="isOpen ? 'rotate-180' : 'rotate-0'"
    />
  </div>
</button>

<!-- Clusters Open -->
@if (isOpen) {
  <div class="flex flex-col gap-8 py-4 pl-[82px] pr-8">
    @for (cluster of filteredClusters; track cluster.id) {
      <div class="flex w-full flex-col gap-4">
        <h3 class="w-full uppercase text-[#3c5792]">{{ cluster.name }}</h3>
        <div class="flex w-full flex-col gap-4">
          @for (application of cluster.applications; track application.id) {
            <!-- <div class="flex w-full flex-col gap-6 border p-4">
              <div class="flex w-full gap-4">
                <div class="w-[200px] shrink-0 uppercase text-[#3c5792]">
                  {{ application.name }}
                </div>
                <div class="flex grow gap-4">
                  <div
                    class="relative flex h-10 w-[200px] items-center rounded-md border border-brand-blue-240 pl-2 font-brand-regular text-brand-blue-240"
                  >
                    <img
                      src="/assets/icons/calendar.svg"
                      alt="Calendar"
                      class="z-10 mr-2 aspect-auto h-6"
                    />
                    <span class="z-10 text-[10px] uppercase">
                      {{ "unitApplications.validTo" | translate }}
                    </span>
                    <input
                      type="date"
                      id="startDate"
                      class="absolute left-0 top-0 z-10 h-full w-full border-none bg-transparent pr-2 text-end text-[14px] focus:border-0 focus:bg-transparent focus:outline-none focus:ring-0"
                      [value]="getValidTo(application.id) | date: 'yyyy-MM-dd'"
                      (change)="editLicenceValidTo(application.id, $event)"
                      required
                    />
                  </div>
                  @if (canUpdateLicences && getValidTo(application.id)) {
                    <button
                      (click)="updateIsInvoiceSent(application.id)"
                      class="flex h-10 cursor-pointer items-center gap-2 rounded-md border border-brand-blue-240 p-2"
                    >
                      <img
                        [id]="'img-application-' + application.id"
                        [src]="
                          applications.get(application.id)?.isInvoiceSent
                            ? '/assets/icons/Icon_Invoice_warning.svg'
                            : '/assets/icons/Icon_Invoice_default.svg'
                        "
                        alt="Invoice"
                        class="h-6"
                      />
                      <div
                        class="flex aspect-square w-6 shrink-0 items-center border bg-white p-1 has-[img]:border-none has-[img]:bg-brand-warning"
                      >
                        @if (applications.get(application.id)?.isInvoiceSent) {
                          <img
                            src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                            alt="Checkmark"
                            class="aspect-auto w-full"
                          />
                        }
                      </div>
                    </button>
                  }
                </div>
              </div>
              <div class="w-full break-all font-medium uppercase">
                @if (
                  application.features.length > 0 &&
                  application.features[0].subFeatures.length > 0
                ) {
                  <div class="flex flex-col divide-y">
                    @for (feature of application.features; track feature.id) {
                      <div class="flex gap-4 py-4 first:pt-0 last:pb-0">
                        <div class="w-[200px] shrink-0 text-[#7a93cb]">
                          {{ feature.name }}
                        </div>
                        <div class="grid grow grid-cols-4 gap-2">
                          @for (
                            subFeature of feature.subFeatures;
                            track subFeature.id
                          ) {
                            @if (
                              canUpdateLicences ||
                              (!canUpdateLicences &&
                                findSubFeature(subFeature.id))
                            ) {
                              <button
                                (click)="
                                  updateLicence(
                                    application.id,
                                    subFeature.id,
                                    false
                                  )
                                "
                                [disabled]="!canUpdateLicences"
                                class="flex h-full w-full items-center justify-between gap-2 bg-[#f4f4f4] p-2 text-[#949494] has-[img]:bg-[#E3F1D1] has-[img]:text-[#69932f]"
                              >
                                <p class="w-full text-left">
                                  {{ subFeature.name }}
                                </p>
                                <div
                                  class="flex aspect-square w-[34px] shrink-0 items-center rounded-sm bg-white p-2 has-[img]:bg-[#86a337]"
                                >
                                  @if (findSubFeature(subFeature.id)) {
                                    <img
                                      src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                                      alt="Checkmark"
                                      class="aspect-auto w-full"
                                    />
                                  }
                                </div>
                              </button>
                            }
                          }
                        </div>
                      </div>
                    }
                  </div>
                } @else if (
                  application.features.length > 0 &&
                  application.features[0].subFeatures.length === 0
                ) {
                  <div class="flex gap-4">
                    <div class="w-[200px] shrink-0">&nbsp;</div>
                    <div class="grid h-fit grow grid-cols-4 gap-2">
                      @for (feature of application.features; track feature) {
                        @if (
                          canUpdateLicences ||
                          (!canUpdateLicences && findFeature(feature.id))
                        ) {
                          <button
                            (click)="
                              updateLicence(application.id, feature.id, true)
                            "
                            [disabled]="!canUpdateLicences"
                            class="flex h-full w-full items-center justify-between gap-2 bg-[#f4f4f4] p-2 text-[#949494] has-[img]:bg-[#E3F1D1] has-[img]:text-[#69932f]"
                          >
                            <p class="w-full text-left">{{ feature.name }}</p>
                            <div
                              class="flex aspect-square w-[34px] shrink-0 items-center rounded-sm bg-white p-2 has-[img]:bg-[#86a337]"
                            >
                              @if (findFeature(feature.id)) {
                                <img
                                  src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                                  alt="Checkmark"
                                  class="aspect-auto w-full"
                                />
                              }
                            </div>
                          </button>
                        }
                      }
                    </div>
                  </div>
                }
              </div>
            </div> -->

            @let licenceApplication = applications.get(application.id);

            <rh-admincenter-unit-application
              [unitId]="unitId"
              [application]="application"
              [licenceApplication]="licenceApplication"
              [canUpdateLicences]="canUpdateLicences"
              [machines]="machines"
              (validToChanged)="
                editLicenceValidTo(
                  application.id,
                  $event.validTo,
                  licenceApplication
                )
              "
              (isInvoiceSentUpdated)="
                updateIsInvoiceSent(application.id, licenceApplication)
              "
            />
          }
        </div>
      </div>
    }
  </div>
}

<ng-template #tplApplicationOverview let-cluster="a">
  @for (application of cluster.applications; track application) {
    <div class="flex w-1/2 items-center">
      <p
        class="min-h-12 w-full p-2 text-left"
        [ngClass]="getApplicationColor(application.id)"
      >
        {{ application.name }}
      </p>
    </div>
  }
</ng-template>
