import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Role } from 'src/app/core/models/role.model';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-admincenter-dialog-edit-role',
  templateUrl: './dialog-edit-role.component.html',
  styleUrls: ['./dialog-edit-role.component.scss'],
  imports: [TranslateModule, CommonModule, ReactiveFormsModule],
})
export class DialogEditRoleComponent implements OnInit {
  form!: FormGroup;
  role?: Role;
  title!: string;

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      role: Role;
      title: string;
    },
  ) {
    if (data) {
      this.role = Object.assign({}, data.role);
      this.title = data.title;
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.role?.name, Validators.required],
      description: [this.role?.description, Validators.required],
      isDefault: [this.role?.isDefault],
    });
  }

  get name() {
    return this.form.get('name');
  }

  get description() {
    return this.form.get('description');
  }

  get isDefault() {
    return this.form.get('isDefault');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const role = {
        ...this.role,
        name: this.name?.value,
        description: this.description?.value,
        isDefault: this.isDefault?.value,
      } as Role;
      this.dialogRef.close(role);
    }
  }
}
