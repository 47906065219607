import { Component, inject, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { lastValueFrom, Subscription } from 'rxjs';
import { Machine } from 'src/app/core/models/machine.model';
import { OrganizationFolded } from 'src/app/core/models/organizationFolded.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
  AddMachineAction,
  DeleteMachineAction,
  GetMachineTypesAction, GetProxiesAction,
  LoadMachinesAction,
  UpdateMachineAction,
} from 'src/app/core/store/machine/actions';
import { LoadOrganizationsAction } from 'src/app/core/store/organization/actions';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { MachinesTableComponent } from '../../../../shared/components/machines-table/machines-table.component';
import { AsyncPipe } from '@angular/common';
import {TranslatePipe} from "@ngx-translate/core";

@Component({
  selector: 'rh-admincenter-machines',
  imports: [MachinesTableComponent, LoadingComponent, AsyncPipe, TranslatePipe],
  templateUrl: './machines.component.html',
  styleUrl: './machines.component.scss',
})
export class MachinesComponent implements OnInit {
  protected readonly emptyMap = new Map<number, string[]>();
  private store = inject(Store);
  private subscriptions = new Subscription();
  private dialog = inject(DialogService);

  private machines$ = this.store.select((state) => {
    return state.Machine.machines as Machine[];
  });
  machines?: Machine[];

  private machineTypes$ = this.store.select((state) => {
    return state.Machine.types as string[];
  });
  machineTypes?: string[];

  organizations$ = this.store.select((state) => {
    return state.Organization.organizations as OrganizationFolded[];
  });

  proxiesPerUnit$ = this.store.select((state) => {
    return state.Machine.proxies as Map<number, string[]>;
  });

  newMachine: Machine | null = null;

  ngOnInit(): void {
    this.subscriptions.add(
      this.machines$.subscribe((machines) => {
        this.machines = machines;
      }),
    );
    this.subscriptions.add(
      this.machineTypes$.subscribe((types) => {
        this.machineTypes = types;
      }),
    );

    this.store.dispatch(new LoadMachinesAction());
    this.store.dispatch(new GetMachineTypesAction());
    this.store.dispatch(new LoadOrganizationsAction());
    this.store.dispatch(new GetProxiesAction());
  }

  onMachineAdded(machine: Machine) {
    this.newMachine = null;
    this.machines = undefined;
    this.store.dispatch(new AddMachineAction(machine));
  }

  onMachineUpdated(machine: Machine) {
    this.machines = undefined;
    this.store.dispatch(new UpdateMachineAction(machine));
  }

  async onMachineDeleted(machineId: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: `deletePopup.deleteMachine.title`,
        message: `deletePopup.deleteMachine.message`,
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    }

    this.machines = undefined;
    this.store.dispatch(new DeleteMachineAction(machineId));
  }

  async addMachine() {
    if (!this.machineTypes) {
      return;
    }

    this.newMachine = {
      id: 0,
      machineNumber: 0,
      type: this.machineTypes[0],
      organizationalContext: {
        companyId: 0,
        companyName: '',
        unitId: 0,
        unitName: '',
      },
      proxyId: '',
      ipAddress: '',
      creationDate: new Date().toISOString(),
      argusInvoice: false,
      maasInvoice: false,
      machineAlias: '',
      argusLicenseValidTo: new Date().toISOString(),
      maasLicenseValidTo: new Date().toISOString(),
      isMobile: false,
    };
  }
}
