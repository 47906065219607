import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { DropdownComponent } from '../dropdown/dropdown.component';
import {TranslatePipe} from "@ngx-translate/core";
import {BaseMachinesTableComponent} from "../base-machine-table/base-machine-table.component";

@Component({
  selector: 'rh-admincenter-unit-machines-table',
  imports: [CommonModule, FormsModule, DropdownComponent, TranslatePipe],
  templateUrl: './unit-machines-table.component.html',
  styleUrl: './unit-machines-table.component.scss',
})
export class UnitMachinesTableComponent extends BaseMachinesTableComponent {

  protected override onMachinesSet(): void {
  }
}
