<div
  id="main"
  [hidden]="isLoading$ | async"
  class="flex h-screen"
  [lang]="language$ | async"
  >
  <!-- sidebar -->
  <rh-admincenter-sidebar
    (toggleSidebarEvent)="toggleSidebarEvent()"
    [sidebarOpen]="sidebarOpen"
    />
    @if (loggedUserPermissionsMisc$ | async) {
      <rh-admincenter-vertical-menu
        (toggleVerticalMenuEvent)="toggleVerticalMenuEvent()"
        [verticalMenuOpen]="verticalMenuOpen"
        [enableVerticalMenu]="enableVerticalMenu"
        />
    }

    @if (userMenuOpen) {
      <rh-admincenter-user-menu
        (toggleUserMenuEvent)="toggleUserMenuEvent()"
        />
    }

    <!-- main content-->
    <div [ngClass]="backgroundColor" class="flex h-screen w-full flex-col">
      <!-- header -->
      <rh-admincenter-header
        [enableVerticalMenu]="enableVerticalMenu"
        (toggleSidebarEvent)="toggleSidebarEvent()"
        (toggleVerticalMenuEvent)="toggleVerticalMenuEvent()"
        (toggleUserMenuEvent)="toggleUserMenuEvent()"
        />
        <!-- main container area -->
        <main
      [ngClass]="{
        'px-small pb-small': isMobile || isTabletPortrait,
        'px-big pb-big': isWeb || isTabletLandscape,
      }"
          class="h-full overflow-auto"
          >
          <router-outlet
            (toggleBackgroundEvent)="toggleBackgroundEvent()"
            class="router-outlet"
          ></router-outlet>
        </main>
      </div>
    </div>

    @if (isLoading$ | async) {
      <rh-admincenter-loading
        class="loading-spinner-container"
      ></rh-admincenter-loading>
    }
