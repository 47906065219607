import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_MACHINES: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_MACHINES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        machines: action.machines,
      };
    }
    case ActionTypes.LOAD_MACHINES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        machines: [],
      };
    }
    case ActionTypes.RESET_MACHINE_STORE: {
      return {
        ...state,
        machines: undefined,
        isLoading: false,
        error: undefined,
      };
    }
    default: {
      return state;
    }
  }
}
