import { Machine } from '../../models/machine.model';

export interface State {
  machines?: Machine[];
  isLoading: boolean;
  error?: string;
  types?: string[];
  proxies?: Map<number,  string[]>;
}

export const initialState: State = {
  isLoading: true,
};
