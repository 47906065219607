<div class="relative z-10 flex h-10 flex-col">
  <div
    [ngClass]="
      showLanguageDropdown
        ? 'absolute right-0 top-0 w-[200px] origin-top-right'
        : ''
    "
    >
    <button
      type="button"
      [ngClass]="
        showLanguageDropdown ? 'w-full rounded-t-md bg-brand-blue-550' : ''
      "
      class="flex h-10 items-center justify-between gap-2 p-2 text-[13px]"
      (click)="toggleLanguageDropdown()"
      >
      <img
        src="/assets/icons/Reishauer_Icons-Language.svg"
        alt="Language"
        class="h-5 w-5"
        />

        @if (!showLanguageDropdown) {
          <span class="text-[13px] text-brand-blue">
            {{ selectedLanguage.name }}
          </span>
        }

        @if (showLanguageDropdown) {
          <img
            src="/assets/icons/Reishauer_Icons_Close_White.svg"
            alt="Close"
            class="h-4 w-4"
            />
        }
      </button>

      @if (showLanguageDropdown) {
        <div>
          <div
            class="options-list w-full overflow-y-auto rounded-b-md bg-brand-blue-550 text-[13px] leading-[13px] text-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none"
            >
            @for (language of languages; track language) {
              <button
                (click)="changeLanguage(language)"
                class="flex h-11 w-full cursor-pointer items-center justify-between px-4 text-white"
          [ngClass]="
            selectedLanguage.code === language.code
              ? ' bg-[#7994C9]'
              : ' hover:bg-[#536EA9]'
          "
                >
                <span>{{ language.name }}</span>
                @if (selectedLanguage.code === language.code) {
                  <img
                    src="/assets/icons/icon-check.png"
                    alt="Language selected"
                    class="h-[24px] w-[24px]"
                    />
                }
              </button>
            }
          </div>
        </div>
      }
    </div>
  </div>
