import { CommonModule } from '@angular/common';
import { Component } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { Machine } from 'src/app/core/models/machine.model';
import { DropdownComponent } from '../dropdown/dropdown.component';
import {TranslatePipe} from "@ngx-translate/core";
import {BaseMachinesTableComponent} from "../base-machine-table/base-machine-table.component";

@Component({
  selector: 'rh-admincenter-machines-table',
  imports: [CommonModule, FormsModule, DropdownComponent, TranslatePipe],
  templateUrl: './machines-table.component.html',
  styleUrl: './machines-table.component.scss',
})
export class MachinesTableComponent extends BaseMachinesTableComponent {
  protected override onMachinesSet(machines: Machine[]): void {
    const compSet = new Set(
      machines.map((m) => m.organizationalContext.companyId),
    );
    this.filterCompanies = Array.from(compSet).map((c) => ({
      id: c,
      name:
        machines.find((m) => m.organizationalContext.companyId === c)
          ?.organizationalContext.companyName || '',
    }));
  }
}
