<button
  [ngClass]="
    showLanguageDropdown
      ? 'justify-end rounded-t-md bg-[#808fad] text-white'
      : 'justify-between rounded-md bg-white text-[#2c4476]'
  "
  class="flex h-10 w-full items-center px-4"
  (click)="toggleLanguageDropdown()"
  >
  @if (!showLanguageDropdown) {
    <p>{{ selectedLanguage.name }}</p>
  }
  @if (!showLanguageDropdown) {
    <img
      src="/assets/icons/Reishauer_Icons-Down.svg"
      alt="Arrow down"
      class="w-4"
      />
  }
  @if (showLanguageDropdown) {
    <img
      src="/assets/icons/Reishauer_Icons-Up.svg"
      alt="Arrow up"
      class="w-4"
      />
  }
</button>
@if (showLanguageDropdown) {
  <div class="flex flex-col">
    @for (language of languages; track language) {
      <button
    [ngClass]="
      language.code === selectedLanguageKey
        ? 'bg-white text-[#2c4476]'
        : 'bg-[#808fad] text-white'
    "
        class="flex h-10 w-full items-center justify-start px-4 last:rounded-b-md"
        (click)="changeLanguage(language)"
        >
        {{ language.name }}
      </button>
    }
  </div>
}
