<!-- Users Header -->
<button class="flex h-16 w-full items-center py-4 pl-8 pr-4" (click)="toggle()">
  <div class="flex w-2/12 items-center gap-5">
    <img
      src="/assets/icons/Reishauer_Icon_Users.svg"
      width="30px"
      alt="Users"
      />
      <h2 class="font-brand-regular text-xl text-[#7a93cb]">
        {{ "unitUsers.title" | translate }}
      </h2>
    </div>

    <ng-container>
      @if (!isToggled) {
        @if (unitUsers) {
          <div class="flex w-1/12 gap-2">
            <div class="flex items-center">
              <img src="/assets/icons/person.svg" width="30px" alt="person logo" />
              <p class="text-[16px] text-[#7a93cb]">
                {{ unitUsers.userCount.total + "/" + unitUsers.userCount.max }}
              </p>
            </div>
          </div>
        }
        <div class="flex w-8/12 items-center gap-2 text-left">
          @if (getCiamAdmins()) {
            <h3 class="text-base text-brand-blue-900">
              {{ "unitUsers.admins" | translate }}:
            </h3>
          }
          <p class="overflow-hidden text-ellipsis whitespace-nowrap">
            {{ getCiamAdmins() }}
          </p>
        </div>
      }

      @if (isToggled) {
        <div class="flex w-9/12 gap-2">
          <div class="flex items-center gap-4">
            @if (canAddUsers) {
              <button
          (click)="
            $event.preventDefault(); $event.stopPropagation(); addMember()
          "
                class="flex items-center gap-2 rounded-md border bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
                >
                <div>{{ "unitUsers.addUserButton" | translate }}</div>
                <div>
                  <img
                    class="h-6 w-6"
                    src="/assets/icons/Reishauer_Icons-Add.svg"
                    alt="Icon add"
                    />
                  </div>
                </button>
              }
            </div>
          </div>
        }
      </ng-container>

      <div class="flex w-1/12 justify-end">
        <img
          src="/assets/icons/chevron-down.svg"
          width="22px"
          alt="chevron"
          [ngClass]="isToggled ? 'rotate-180' : 'rotate-0'"
          />
        </div>
      </button>

      <!-- Users Content  -->
      @if (isToggled && unitUsers) {
        <div class="py-4 pl-[82px] pr-8">
          @if (unitUsers.userCount.total > 0) {
            <div>
              <table class="w-full table-fixed divide-y text-[#3c5792]">
                <thead class="text-sm uppercase text-[#7a93cb]">
                  <tr>
                    <th class="w-[10%] p-2 pl-0 text-left" (click)="sortName()">
                      <div class="flex items-center gap-2">
                        <p>{{ "unitUsers.name" | translate }}</p>
                        <img
                          src="/assets/icons/chevron-down.svg"
                          width="20px"
                          alt=""
                          class="cursor-pointer"
                          [ngClass]="sortNameOrder === 'asc' ? 'rotate-0' : 'rotate-180'"
                          />
                        </div>
                      </th>
                      <th class="w-[12%] p-2 text-left" (click)="sortDepartment()">
                        <div class="flex items-center gap-2">
                          <p>{{ "unitUsers.department" | translate }}</p>
                          <img
                            src="/assets/icons/chevron-down.svg"
                            width="20px"
                            alt=""
                            class="cursor-pointer"
                [ngClass]="
                  sortDepartmentOrder === 'asc' ? 'rotate-0' : 'rotate-180'
                "
                            />
                          </div>
                        </th>
                        <th class="w-[14%] p-2 text-left">
                          {{ "unitUsers.ciamRole" | translate }}
                        </th>
                        <th class="w-[38%] p-2 text-left">Academy</th>
                        <th class="w-[19%] p-2 text-left">Monitoring</th>
                        <th></th>
                      </tr>
                    </thead>
                    <tbody class="divide-y">
                      @for (user of unitUsers.users; track user) {
                        <tr>
                          <td class="p-2 pl-0 text-left">
                            <p
                              class="overflow-hidden text-ellipsis whitespace-nowrap"
                              [title]="user.email"
                              >
                              {{ user.givenName }} {{ user.familyName }}
                            </p>
                          </td>
                          <td class="p-2 text-left">
                            <p class="overflow-hidden text-ellipsis whitespace-nowrap">
                              {{ user.department.name }}
                            </p>
                          </td>
                          <td class="p-2">
                            <rh-admincenter-multi-select
                              class="w-full"
                              [allowMultiSelect]="false"
                              [roles]="getRolesByApplicationId(getCiamId())"
              [selectedRoles]="
                getSelectedRolesByApplicationId(user, getCiamId())
              "
              (selectionChange)="
                onRoleChangeMulti(user.id, getCiamId(), $event)
              "
                              />
                            </td>
                            <td class="p-2">
                              <ng-container
                                [ngTemplateOutlet]="tplApplication"
              [ngTemplateOutletContext]="{
                a: getClustersForOverview()[0],
                b: user,
              }"
                                />
                              </td>
                              <td class="p-2">
                                <ng-container
                                  [ngTemplateOutlet]="tplApplication"
              [ngTemplateOutletContext]="{
                a: getClustersForOverview()[1],
                b: user,
              }"
                                  />
                                </td>
                                <td class="items-center p-2">
                                  <div class="flex items-center justify-end gap-2">
                                    @if (user.isBlocked) {
                                      <img
                                        src="/assets/icons/Reishauer_Icon_Block_User.svg"
                                        alt="Icon block user"
                                        class="h-6 w-auto shrink-0"
                                        />
                                    }
                                    <button
                                      (click)="editMember(user)"
                                      class="flex h-9 shrink-0 items-center rounded-md border bg-white px-3 py-2 hover:bg-brand-blue-light"
                                      >
                                      <img
                                        class="h-6 w-auto"
                                        src="/assets/icons/Reishauer_Icons-Edit.svg"
                                        alt="Icon edit"
                                        />
                                      </button>
                                    </div>
                                  </td>
                                </tr>
                              }
                            </tbody>
                          </table>
                        </div>
                      }
                      @if (unitUsers.userCount.total === 0) {
                        <div>
                          <p class="text-brand-blue-200">
                            {{ "unitUsers.noUsers" | translate }}
                          </p>
                        </div>
                      }
                    </div>
                  }

                  <ng-template #tplApplication let-cluster="a" let-user="b">
                    <div class="flex h-full w-full flex-wrap gap-2 2xl:divide-x-2">
                      @for (application of cluster.applications; track application) {
                        <div
                          class="flex h-full items-center gap-1 font-medium 2xl:last:pl-2"
                          >
                          @for (role of getRolesByApplicationId(application.id); track role) {
                            <button
        (click)="
          onRoleChange(
            user,
            application.id,
            role.id,
            application.multiRolesAllowed
          )
        "
                              class="flex h-[50px] w-full items-center justify-between gap-2 bg-[#f4f4f4] p-2 font-medium text-[#949494] has-[img]:bg-[#E3F1D1] has-[img]:text-[#69932f]"
                              >
                              @if (role.key.includes('_viewer')) {
                                <p class="w-full">
                                  {{ application.name }}
                                </p>
                              }
                              @if (role.key.includes('elearning_admin')) {
                                <p class="w-full">A</p>
                              }
                              @if (role.key.includes('elearning_editor')) {
                                <p class="w-full">E</p>
                              }
                              @if (role.key.includes('elearning_approver')) {
                                <p class="w-full">P</p>
                              }
                              <div
                                class="flex aspect-square w-[34px] shrink-0 items-center bg-white p-2 has-[img]:bg-[#86a337]"
                                >
                                @if (userHasRoleForApplication(user, application.id, role.id)) {
                                  <img
                                    src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                                    alt="Checkmark"
                                    class="aspect-auto w-full"
                                    />
                                }
                              </div>
                            </button>
                          }
                        </div>
                      }
                    </div>
                  </ng-template>
