<section class="flex flex-col gap-12">
  <header class="flex justify-between">
    <h1
      class="flex items-center font-brand-light text-2xl uppercase leading-6 text-brand-blue-800"
    >
      {{ 'machines.title' | translate }}
    </h1>
    <button
      (click)="addMachine()"
      class="flex w-fit items-center gap-2 rounded-md border bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
    >
      {{ 'machines.add' | translate }}
      <img
        class="h-6 w-6"
        src="/assets/icons/Reishauer_Icons-Add.svg"
        alt="Icon add"
      />
    </button>
  </header>

  @let organizations = organizations$ | async;
  @let proxiesPerUnit = proxiesPerUnit$ | async;
  @if (machines && machineTypes && organizations && proxiesPerUnit) {
    <rh-admincenter-machines-table
      [global]="true"
      [machines]="machines"
      [machineTypes]="machineTypes"
      [newMachine]="newMachine"
      [organizations]="organizations"
      [proxiesPerUnit]="proxiesPerUnit"
      (machineAdded)="onMachineAdded($event)"
      (machineUpdated)="onMachineUpdated($event)"
      (machineDeleted)="onMachineDeleted($event)"
    ></rh-admincenter-machines-table>
  } @else {
    <rh-admincenter-loading
      class="loading-spinner-container"
    ></rh-admincenter-loading>
  }
</section>
