<div class="relative z-30">
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div
      class="flex min-h-full items-center justify-center p-4 text-center sm:p-0"
      >
      <div
        class="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-3xl"
        >
        <!--Box side-->
        <div
          class="absolute left-0 top-0 z-40 h-full w-[30px] bg-brand-blue-550"
        ></div>

        <button
          (click)="onClose()"
          class="absolute right-[18px] top-[18px] z-[100]"
          >
          <img
            src="/assets/icons/Reishauer_Icons_Close_Blue.svg"
            alt="Close"
            class="h-[20px] w-[20px] fill-brand-gray-dark"
            />
          </button>
          <form [formGroup]="form" (submit)="onSubmit()" class="relative">
            <div
              class="px-4 pb-4 pt-5 sm:pb-[60px] sm:pl-[90px] sm:pr-[60px] sm:pt-10"
              >
              <legend
                class="mb-[55px] flex items-center justify-center text-center font-brand-light text-2xl uppercase leading-6 text-brand-blue-700"
                >
                <img
                  src="/assets/icons/Reishauer_Icon_Edit.svg"
                  alt="Edit"
                  class="fill-brand-primary-selected mr-2 h-8 w-8"
                  />

                  {{ title | translate }}
                </legend>

                <div class="grid grid-cols-6 gap-4">
                  <label
                    for="firstname"
                    class="col-span-1 flex w-full items-center align-middle text-[11px] uppercase leading-[11px] text-brand-blue"
                    >
                    {{ "userPopup.firstname" | translate }}
                  </label>
                  <div class="col-span-5 flex flex-col">
                    <input
                      type="text"
                      class="h-9 w-full rounded-none border border-brand-input-primary px-2 py-2 text-sm leading-[17px] text-brand-title hover:border-brand-input-primary-hover focus:rounded-none focus:border-2 focus:outline-brand-input-primary-focus"
                      placeholder=""
                      formControlName="givenName"
                      />
                      @if (givenName?.errors?.['required']) {
                        <p
                          class="mt-2 text-red-600"
                          id="name-error"
                          >
                          {{ "errors.forms.invalidGivenName" | translate }}
                        </p>
                      }
                    </div>
                    <label
                      for="lastname"
                      class="col-span-1 flex w-full items-center align-middle text-[11px] uppercase leading-[11px] text-brand-blue"
                      >
                      {{ "userPopup.lastname" | translate }}
                    </label>
                    <div class="col-span-5 flex flex-col">
                      <input
                        type="text"
                        class="h-9 w-full rounded-none border border-brand-input-primary px-2 py-2 text-sm leading-[17px] text-brand-title hover:border-brand-input-primary-hover focus:rounded-none focus:border-2 focus:outline-brand-input-primary-focus"
                        placeholder=""
                        formControlName="familyName"
                        />
                        @if (familyName?.errors?.['required']) {
                          <p
                            class="mt-2 text-red-600"
                            id="name-error"
                            >
                            {{ "errors.forms.invalidFamilyName" | translate }}
                          </p>
                        }
                      </div>

                      <label
                        for="email"
                        class="col-span-1 flex w-full items-center align-middle text-[11px] uppercase leading-[11px] text-brand-blue"
                        >
                        {{ "userPopup.email" | translate }}
                      </label>
                      <div class="col-span-5 flex flex-col">
                        <input
                          type="text"
                          class="h-9 w-full rounded-none border border-brand-input-primary px-2 py-2 text-sm leading-[17px] text-brand-title hover:border-brand-input-primary-hover focus:rounded-none focus:border-2 focus:outline-brand-input-primary-focus"
                          placeholder=""
                          formControlName="email"
                          [title]="isNew ? '' : ('userPopup.emailDisabled' | translate)"
                          />
                          @if (email?.errors) {
                            <p
                              class="mt-2 text-red-600"
                              id="email-error"
                              >
                              {{ "errors.forms.invalidEmail" | translate }}
                            </p>
                          }
                        </div>
                        <label
                          for="departmentId"
                          class="col-span-1 flex w-full items-center align-middle text-[11px] uppercase leading-[11px] text-brand-blue"
                          >
                          {{ "userPopup.department" | translate }}
                        </label>
                        <div class="col-span-5 flex gap-2">
                          <div class="flex w-full flex-col">
                            <select
                              formControlName="departmentId"
                              name="departmentId"
                              id="departmentId"
                              class="focus:ring-brand-primary rounded-md border-0 p-2 text-sm leading-[17px] text-brand-title shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset sm:block sm:w-full"
                              >
                              @for (department of departments; track department) {
                                <option
                                  [value]="department.id"
                                  >
                                  {{ department.name }}
                                </option>
                              }
                            </select>
                            @if (departmentId?.errors?.['required']) {
                              <p
                                class="mt-2 text-red-600"
                                id="name-error"
                                >
                                {{ "errors.forms.invalidDepartment" | translate }}
                              </p>
                            }
                          </div>
                          <button
                            class="h-8 w-8 align-top"
                            type="button"
                            (click)="addDepartment()"
                            >
                            <img
                              src="/assets/icons/Reishauer_Icons-Add.svg"
                              alt="Add"
                              class="fill-brand-primary-selected h-6 w-6"
                              />
                            </button>
                          </div>

                          @if (!isNew && permissions.canUpdateUser) {
                            <div
                              class="col-span-6 grid grid-cols-6 items-center border-t border-gray-200 pt-4"
                              >
                              <label
                                for="password"
                                class="col-span-1 w-6 text-[11px] uppercase leading-[11px] text-brand-blue"
                                >
                                {{ "userPopup.password" | translate }}
                              </label>
                              <div
                                class="col-span-5 flex items-center justify-between gap-4"
                                >
                                <button
                                  type="button"
                                  (click)="resetPassword()"
                                  class="flex h-10 w-full items-center gap-4 rounded-md border border-brand-gray-light bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
                                  >
                                  <img
                                    src="/assets/icons/Reishauer_Icon_Request_Password.svg"
                                    alt="Icon request password"
                                    class="h-6 w-auto"
                                    />
                                    <p class="w-full text-center">
                                      {{ "userPopup.requestNewPassword" | translate }}
                                    </p>
                                  </button>
                                  <button
                                    type="button"
                                    (click)="reset2fa()"
                                    class="flex h-10 w-full items-center gap-4 rounded-md border border-brand-gray-light bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
                                    >
                                    <img
                                      src="/assets/icons/Reishauer_Icon_Request_TOTP.svg"
                                      alt="Icon request TOTP"
                                      class="h-6 w-auto"
                                      />
                                      <p class="w-full text-center">
                                        {{ "userPopup.requestNewTOTPPassword" | translate }}
                                      </p>
                                    </button>
                                  </div>
                                </div>
                                <div
                                  class="col-span-6 grid grid-cols-6 items-center border-t border-gray-200 pt-4"
                                  >
                                  <label
                                    for="functions"
                                    class="col-span-1 w-6 pt-2 text-[11px] uppercase leading-[11px] text-brand-blue"
                                    >
                                    {{ "userPopup.functions" | translate }}
                                  </label>
                                  <div
                                    class="col-span-5 flex items-center justify-between gap-4"
                                    >
                                    <ng-container>
                                      @if (!user.isBlocked) {
                                        <button
                                          type="button"
                                          (click)="blockUser()"
                                          class="flex h-10 w-full items-center gap-4 rounded-md border border-brand-gray-light bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
                                          >
                                          <img
                                            src="/assets/icons/Reishauer_Icon_Block_User.svg"
                                            alt="Icon block user"
                                            class="h-6 w-auto"
                                            />
                                            <p class="w-full text-center">
                                              {{ "userPopup.blockUser" | translate }}
                                            </p>
                                          </button>
                                        }
                                        @if (user.isBlocked) {
                                          <button
                                            type="button"
                                            (click)="unblockUser()"
                                            class="flex h-10 w-full items-center gap-4 rounded-md border border-brand-gray-light bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
                                            >
                                            <img
                                              src="/assets/icons/Reishauer_Icon_Block_User.svg"
                                              alt="Icon unblock user"
                                              class="h-6 w-auto"
                                              />
                                              <p class="w-full text-center">
                                                {{ "userPopup.unblockUser" | translate }}
                                              </p>
                                            </button>
                                          }
                                        </ng-container>
                                        @if (permissions.canDeleteMember) {
                                          <button
                                            type="button"
                                            (click)="deleteMember()"
                                            class="flex h-10 w-full items-center gap-4 rounded-md border border-brand-gray-light bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
                                            >
                                            <img
                                              src="/assets/icons/Reishauer_Icon_Delete_User.svg"
                                              alt="Icon delete user"
                                              class="h-6 w-auto"
                                              />
                                              <p class="w-full text-center">
                                                {{ "userPopup.deleteUser" | translate }}
                                              </p>
                                            </button>
                                          }
                                        </div>
                                      </div>
                                    }
                                  </div>
                                </div>
                                <div
                                  class="mt-5 flex gap-4 border-t border-brand-gray py-6 sm:mt-0 sm:justify-center"
                                  >
                                  <button
                                    (click)="onClose()"
                                    type="button"
                                    class="flex h-9 w-full items-center justify-between rounded-md bg-brand-red pl-5 pr-2 font-brand-regular text-sm uppercase leading-[14px] text-white md:w-auto"
                                    >
                                    {{ "shared.cancelButton" | translate }}
                                    <img
                                      src="/assets/icons/Reishauer_Icons_Close_White.svg"
                                      alt="Close"
                                      class="ml-[70px] inline-block h-4 w-4 fill-white"
                                      />
                                    </button>
                                    <button
                                      [disabled]="!form.valid"
                                      [ngClass]="form.valid ? '' : 'cursor-not-allowed opacity-50'"
                                      type="submit"
                                      class="flex h-9 w-full items-center justify-between rounded-md bg-brand-green pl-5 pr-2 font-brand-regular text-sm uppercase leading-[14px] text-white md:w-auto"
                                      >
                                      {{ "shared.saveButton" | translate }}
                                      <img
                                        src="/assets/icons/Reishauer_Icons_Check_White.svg"
                                        alt="Check"
                                        class="ml-[70px] inline-block h-5 w-5 fill-white"
                                        />
                                      </button>
                                    </div>
                                  </form>
                                </div>
                              </div>
                            </div>
                          </div>
