import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { Subscription } from 'rxjs';

@Component({
  selector: 'rh-admincenter-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ width: '300px' })),
      state('false', style({ width: '20px' })),
      transition('0 => 1', [animate('500ms ease-in')]),
      transition('1 => 0', [animate('500ms ease-in')]),
    ]),
  ],
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Output() toggleVerticalMenuEvent = new EventEmitter<void>();
  @Input() verticalMenuOpen!: boolean;
  @Input() enableVerticalMenu!: boolean;

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  animationDone = false;

  constructor(private responsive: BreakpointObserver) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];

          const isVerticalMenuOpen =
            (this.isWeb || this.isTabletLandscape) && !this.verticalMenuOpen;
          if (isVerticalMenuOpen) {
            this.toggleVerticalMenu();
          }
        }),
    );
  }

  toggleVerticalMenu(): void {
    this.toggleVerticalMenuEvent.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
