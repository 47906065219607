import { ComponentType, Overlay } from '@angular/cdk/overlay';
import { ComponentPortal } from '@angular/cdk/portal';
import { Injectable, Injector } from '@angular/core';
import { ISnackbarData } from './core/snackbar-data.interface';
import { SNACKBAR_DATA } from './snackbar-tokens';

export interface DialogConfig {
  data?: any;
}

@Injectable({
  providedIn: 'root',
})
export class SnackbarService {
  constructor(
    private overlay: Overlay,
    private injector: Injector,
  ) {}

  /**
   * Open a custom component in an overlay
   */
  show<T>(
    component: ComponentType<T>,
    data: ISnackbarData,
    timeout = 4000,
  ): void {
    // Create the overlay with customizable options
    const overlayRef = this.overlay.create({
      positionStrategy: undefined, // prevent css 'position: static'
      hasBackdrop: false,
      panelClass: `custom-snackbar-panel`,
    });

    // Create injector to be able to reference the DialogRef from within the component
    const injector = Injector.create({
      parent: this.injector,
      providers: [{ provide: SNACKBAR_DATA, useValue: data }],
    });

    // Attach component portal to the overlay
    const portal = new ComponentPortal(component, null, injector);
    overlayRef.attach(portal);

    // Auto-remove the overlay/component after a specific time
    setTimeout(() => {
      overlayRef.dispose();
    }, timeout);
  }
}
