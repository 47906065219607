@if (
  (isLoading$ | async) === false &&
  (permissions$ | async) &&
  sortedOrganizations
) {
  <div
    [ngClass]="{
      'w-[200vw] pr-small': isMobilePortrait || isTablePortrait,
      'w-full': !(isMobilePortrait || isTablePortrait),
    }"
    class="bg-white"
  >
    <ol
      class="grid w-full gap-x-2 divide-y divide-brand-gray-light font-brand-regular text-xs leading-none"
      [ngClass]="{
        'grid-cols-[auto,min-content,auto,min-content,auto,min-content,auto,auto,min-content,auto,min-content]':
          !showUnits,
        'grid-cols-[auto,auto,min-content,auto,min-content,auto,min-content,auto,auto,min-content,auto,min-content]':
          showUnits,
      }"
    >
      <li
        class="col-span-full grid grid-cols-subgrid items-end pb-2 font-bold uppercase text-brand-blue"
      >
        @if ((permissions$ | async)?.canUpdateCompany && !isMobile) {
          <button
            (click)="addOrganization()"
            [ngClass]="{
              'px-2 py-1 text-xs': isMobilePortrait || isTablePortrait,
              'p-2 text-sm': !(isMobilePortrait || isTablePortrait),
            }"
            class="flex w-fit items-center gap-2 rounded-md border bg-white font-bold uppercase leading-none text-[#1F3D7D] hover:bg-brand-blue-light"
          >
            <div>{{ "organizations.addCompanyButton" | translate }}</div>
            <div>
              <img
                [ngClass]="{
                  'h-4': isMobilePortrait,
                  'h-5': !isMobilePortrait,
                }"
                class="aspect-square"
                src="/assets/icons/Reishauer_Icons-Add.svg"
                alt="Icon add"
              />
            </div>
          </button>
        }
        @if (!(permissions$ | async)?.canUpdateCompany || isMobile) {
          <p class="text-brand-blue">
            {{ "organizations.addCompanyButton" | translate }}
          </p>
        }
        @if (showUnits) {
          <p>Unit name</p>
        }
        <div class="col-span-4 grid grid-cols-subgrid gap-y-3 truncate">
          <p class="col-span-3 col-start-2 text-[#1F3D7D]">Academy</p>
          <p class="col-start-2">E-Learning</p>
          <p class="col-start-4">Video Instructions</p>
        </div>
        <div class="col-span-5 grid grid-cols-subgrid gap-y-3 truncate">
          <p class="col-span-full col-start-2 text-[#1F3D7D]">Monitoring</p>
          <p class="col-start-2">Argus</p>
          <p class="col-start-3">Maas</p>
          <p class="col-start-5">Metrology</p>
        </div>
        <p class=""></p>
      </li>
      @for (organization of sortedOrganizations; track organization.id) {
        <li
          [ngClass]="{
            'cursor-pointer': !(isMobile || isTablePortrait),
            'cursor-default': isMobile || isTablePortrait,
          }"
          class="col-span-full grid grid-cols-subgrid items-center text-brand-blue-800 hover:bg-brand-blue-lighter"
          (click)="navigateToOrganization(organization.id)"
        >
          <p class="min-w-32">
            {{ organization.name }}
          </p>

          @if (showUnits) {
            <div
              class="col-span-10 grid h-full grid-cols-subgrid divide-y divide-brand-gray-light"
            >
              @for (unit of organization.units; track unit.id) {
                <div
                  class="col-span-full grid h-9 grid-cols-subgrid items-center"
                >
                  <p>
                    {{ unit.name }}
                  </p>
                  <div
                    class="col-span-4 grid h-full grid-cols-subgrid items-center"
                  >
                    <ng-container
                      [ngTemplateOutlet]="tplApplication"
                      [ngTemplateOutletContext]="{
                        invoiceIcon: getInvoiceIcon(
                          organization.id,
                          'Elearning',
                          unit.id
                        ),
                        isInvoiceSent: isInvoiceSent(
                          organization.id,
                          'Elearning',
                          unit.id
                        ),
                        applicationColor: getApplicationColor(
                          organization.id,
                          'Elearning',
                          unit.id
                        ),
                      }"
                    />
                    <ng-container
                      [ngTemplateOutlet]="tplApplication"
                      [ngTemplateOutletContext]="{
                        invoiceIcon: getInvoiceIcon(
                          organization.id,
                          'Video Instructions',
                          unit.id
                        ),
                        isInvoiceSent: isInvoiceSent(
                          organization.id,
                          'Video Instructions',
                          unit.id
                        ),
                        applicationColor: getApplicationColor(
                          organization.id,
                          'Video Instructions',
                          unit.id
                        ),
                      }"
                    />
                  </div>
                  <div
                    class="col-span-5 grid h-full grid-cols-subgrid items-center"
                  >
                    <ng-container
                      [ngTemplateOutlet]="tplApplication"
                      [ngTemplateOutletContext]="{
                        invoiceIcon: getInvoiceIcon(
                          organization.id,
                          'Argus',
                          unit.id
                        ),
                        isInvoiceSent: isInvoiceSent(
                          organization.id,
                          'Argus',
                          unit.id
                        ),
                        applicationColor: getApplicationColor(
                          organization.id,
                          'Argus',
                          unit.id
                        ),
                      }"
                    />
                    <div
                      class="flex h-6 w-6 items-center justify-center p-1 text-white"
                      [ngClass]="getMaasColor(unit.maasStatus)"
                    ></div>
                    <ng-container
                      [ngTemplateOutlet]="tplApplication"
                      [ngTemplateOutletContext]="{
                        invoiceIcon: getInvoiceIcon(
                          organization.id,
                          'Metrology',
                          unit.id
                        ),
                        isInvoiceSent: isInvoiceSent(
                          organization.id,
                          'Metrology',
                          unit.id
                        ),
                        applicationColor: getApplicationColor(
                          organization.id,
                          'Metrology',
                          unit.id
                        ),
                      }"
                    />
                  </div>
                </div>
              }
            </div>
          } @else {
            <div class="col-span-4 grid h-9 grid-cols-subgrid items-center">
              <ng-container
                [ngTemplateOutlet]="tplApplication"
                [ngTemplateOutletContext]="{
                  invoiceIcon: getInvoiceIcon(organization.id, 'Elearning'),
                  isInvoiceSent: isInvoiceSent(organization.id, 'Elearning'),
                  applicationColor: getApplicationColor(
                    organization.id,
                    'Elearning'
                  ),
                }"
              />
              <ng-container
                [ngTemplateOutlet]="tplApplication"
                [ngTemplateOutletContext]="{
                  invoiceIcon: getInvoiceIcon(
                    organization.id,
                    'Video Instructions'
                  ),
                  isInvoiceSent: isInvoiceSent(
                    organization.id,
                    'Video Instructions'
                  ),
                  applicationColor: getApplicationColor(
                    organization.id,
                    'Video Instructions'
                  ),
                }"
              />
            </div>

            <div class="col-span-5 grid h-full grid-cols-subgrid items-center">
              <ng-container
                [ngTemplateOutlet]="tplApplication"
                [ngTemplateOutletContext]="{
                  invoiceIcon: getInvoiceIcon(organization.id, 'Argus'),
                  isInvoiceSent: isInvoiceSent(organization.id, 'Argus'),
                  applicationColor: getApplicationColor(
                    organization.id,
                    'Argus'
                  ),
                }"
              />
              <div
                class="flex h-6 w-6 items-center justify-center p-1 text-white"
                [ngClass]="getCompanyMaasColor(organization.units)"
              ></div>
              <ng-container
                [ngTemplateOutlet]="tplApplication"
                [ngTemplateOutletContext]="{
                  invoiceIcon: getInvoiceIcon(organization.id, 'Metrology'),
                  isInvoiceSent: isInvoiceSent(organization.id, 'Metrology'),
                  applicationColor: getApplicationColor(
                    organization.id,
                    'Metrology'
                  ),
                }"
              />
            </div>
          }

          @if ((permissions$ | async)?.canUpdateCompany && !isMobile) {
            <button
              class="flex min-w-9 items-center rounded-md border border-brand-gray-light bg-white px-2 py-1 hover:bg-brand-blue-light"
              (click)="
                $event.preventDefault();
                $event.stopPropagation();
                editOrganization(organization)
              "
            >
              <img
                class="fill-brand-primary h-5 w-auto"
                src="/assets/icons/Reishauer_Icons-Edit.svg"
                alt="Icon edit"
              />
            </button>
          }
        </li>
      }
    </ol>
  </div>
}

<ng-template
  #tplApplication
  let-invoiceIcon="invoiceIcon"
  let-isInvoiceSent="isInvoiceSent"
  let-applicationColor="applicationColor"
>
  <div class="w-7">
    <img
      [src]="invoiceIcon"
      alt=""
      [ngClass]="{
        invisible: !isInvoiceSent || applicationColor === 'bg-brand-ok',
      }"
      class="h-6"
    />
  </div>
  <div
    class="flex h-6 w-6 items-center justify-center p-1 text-white"
    [ngClass]="applicationColor"
  >
    @if (isInvoiceSent && applicationColor !== "bg-brand-ok") {
      <img
        src="/assets/icons/Reishauer_Icon_Checkmark.svg"
        alt="Checkmark"
        class="aspect-auto w-full"
      />
    }
  </div>
</ng-template>
