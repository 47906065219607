import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { ApplicationRoles } from 'src/app/core/models/applicationRoles.model';
import { Cluster } from 'src/app/core/models/cluster.model';
import { Organization } from 'src/app/core/models/organization.model';
import { Role } from 'src/app/core/models/role.model';
import { UnitLicences } from 'src/app/core/models/unitLicences.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
  LoadApplicationsRolesAction,
  LoadClustersAction,
} from 'src/app/core/store/application/actions';
import {
  AddUnitAction,
  LoadDepartmentsAction,
  LoadOrganizationAction,
  LoadUnitUsersAction,
  LoadUnitsAction,
  ResetOrganizationStateAction,
  SaveUnitAction,
} from 'src/app/core/store/organization/actions';
import { State } from 'src/app/core/store/state';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import { DialogEditUnitComponent } from '../dialog-edit-unit/dialog-edit-unit.component';
import { UnitClustersComponent } from '../unit-clusters/unit-clusters.component';
import { UnitUsersComponent } from '../unit-users/unit-users.component';

@Component({
  selector: 'rh-admincenter-companydetail',
  templateUrl: './company-detail.component.html',
  styleUrls: ['./company-detail.component.scss'],
  imports: [
    UnitClustersComponent,
    UnitUsersComponent,
    LoadingComponent,
    TranslateModule,
  ],
})
export class CompanyDetailComponent implements OnInit, OnDestroy {
  @Output() toggleBackgroundEvent = new EventEmitter<void>();

  dropdownStates: { [key: string]: boolean } = {};
  selectedRoles: { [key: string]: number[] } = {};

  organization$!: Observable<Organization>;
  organization?: Organization;
  units$!: Observable<UnitLicences[]>;
  units?: UnitLicences[];
  clusters$!: Observable<Cluster[]>;
  clusters: Cluster[] = [];

  applicationsRoles$!: Observable<ApplicationRoles[]>;
  rolesByApplication?: Map<number, Role[]>;
  isLoading$!: Observable<boolean>;
  isLoading?: boolean;
  error$!: Observable<string>;
  success$!: Observable<string>;

  unitChanged = true;

  organizationId = 0;

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<State>,
    private route: ActivatedRoute,
    private router: Router,
    public dialog: DialogService,
    private snackbar: SnackbarService,
  ) {}

  ngOnInit(): void {
    const param = this.route.snapshot.paramMap.get('organizationId');

    if (param && !isNaN(Number(param))) {
      this.organizationId = Number(param);
    } else {
      this.router.navigate(['/organizations']);
    }

    this.organization$ = this.store$.select((state) => {
      return state.Organization.organization as Organization;
    });

    this.subscriptions.add(
      this.organization$?.subscribe((data: Organization) => {
        if (data) {
          this.organization = data;
        }
      }),
    );

    this.units$ = this.store$.select((state) => {
      return state.Organization.units as UnitLicences[];
    });

    this.subscriptions.add(
      this.units$?.subscribe((data: UnitLicences[]) => {
        if (data && this.unitChanged) {
          this.units = data.sort((a, b) => a.name.localeCompare(b.name));
          this.unitChanged = false;
        }
      }),
    );

    this.clusters$ = this.store$.select((state) => {
      return state.Application.clusters as Cluster[];
    });

    this.subscriptions.add(
      this.clusters$?.subscribe((clusters: Cluster[]) => {
        if (clusters) {
          clusters.forEach((cluster) => {
            cluster.applications.forEach((application) => {
              application.features.forEach((feature) => {
                feature.subFeatures.sort((a, b) =>
                  a.name.localeCompare(b.name),
                );
                application.features.sort((a, b) =>
                  a.name.localeCompare(b.name),
                );
              });
            });
            cluster.applications.sort((a, b) => a.name.localeCompare(b.name));
          });
          this.clusters = clusters.sort((a, b) => a.name.localeCompare(b.name));

          // Admin cluster should be the first one
          this.clusters = clusters.sort((a, b) => {
            if (a.name === 'Admin') {
              return -1;
            } else if (b.name === 'Admin') {
              return 1;
            } else {
              return a.name.localeCompare(b.name);
            }
          });
        }
      }),
    );

    this.applicationsRoles$ = this.store$.select((state) => {
      return state.Application.applicationsRoles as ApplicationRoles[];
    });

    this.subscriptions.add(
      this.applicationsRoles$?.subscribe((data: ApplicationRoles[]) => {
        if (data) {
          this.rolesByApplication = new Map<number, Role[]>(
            data.map((v) => [v.application.id, v.roles]),
          );
        }
      }),
    );

    this.isLoading$ = this.store$.select((state) => {
      return state.Organization.isLoading as boolean;
    });

    this.subscriptions.add(
      this.isLoading$?.subscribe((data: boolean) => {
        this.isLoading = data;
      }),
    );

    this.error$ = this.store$.select((state) => {
      return state.Organization.error as string;
    });

    this.subscriptions.add(
      this.error$?.subscribe((error: string) => {
        if (error) {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `${error}`,
          });
        }
      }),
    );

    this.success$ = this.store$.select((state) => {
      return state.Organization.success as string;
    });

    this.subscriptions.add(
      this.success$?.subscribe((success: string) => {
        if (success) {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: `${success}`,
          });
        }
      }),
    );

    this.store$.dispatch(new LoadOrganizationAction(this.organizationId));
    this.store$.dispatch(new LoadUnitsAction(this.organizationId));
    this.store$.dispatch(new LoadUnitUsersAction(this.organizationId));
    this.store$.dispatch(new LoadClustersAction());
    this.store$.dispatch(new LoadApplicationsRolesAction(this.organizationId));
    this.store$.dispatch(new LoadDepartmentsAction(this.organizationId));
  }

  async editUnit(unit: UnitLicences) {
    const dialogRef = this.dialog.open(DialogEditUnitComponent, {
      data: {
        title: 'unitPopup.editUnitTitle',
        unit: unit,
      },
    });

    const result = (await lastValueFrom(
      dialogRef.afterClosed(),
    )) as UnitLicences;
    if (!result) {
      return;
    } else {
      this.store$.dispatch(new SaveUnitAction(result));
      this.unitChanged = true;
    }
  }

  async addUnit() {
    const dialogRef = this.dialog.open(DialogEditUnitComponent, {
      data: {
        unit: {
          id: 0,
          name: '',
        } as UnitLicences,
        title: 'unitPopup.addUnitTitle',
      },
    });

    const result = (await lastValueFrom(
      dialogRef.afterClosed(),
    )) as UnitLicences;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new AddUnitAction(this.organizationId, result.name));
      this.unitChanged = true;
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dialog.close();
    this.store$.dispatch(new ResetOrganizationStateAction());
  }
}
