import { OverlayRef } from '@angular/cdk/overlay';
import { Observable, Subject } from 'rxjs';

/**
 * A reference to the dialog itself.
 * Can be injected into the component added to the overlay and then used to close itself.
 */
export class DialogRef {
  private afterClosedSubject = new Subject<any>();
  private previousBodyOverflowStyle = '';

  constructor(protected overlayRef: OverlayRef) {
    this.previousBodyOverflowStyle = document.body.style.overflow;
    document.body.style.overflow = 'hidden'; // prevent scrolling background
  }

  /**
   * Closes the overlay. You can optionally provide a result.
   */
  public close(result?: any) {
    this.overlayRef.dispose();
    this.afterClosedSubject.next(result);
    this.afterClosedSubject.complete();
    document.body.style.overflow = this.previousBodyOverflowStyle;
  }

  /**
   * An Observable that notifies when the overlay has closed
   */
  public afterClosed(): Observable<any> {
    return this.afterClosedSubject.asObservable();
  }
}
