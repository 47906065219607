import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, combineLatest, lastValueFrom } from 'rxjs';
import { Licence } from 'src/app/core/models/licence.model';
import { LoggedUserPermissionsMisc } from 'src/app/core/models/loggedUserPermissionsMisc.model';
import { Organization } from 'src/app/core/models/organization.model';
import { OrganizationFolded } from 'src/app/core/models/organizationFolded.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
  AddOrganizationAction,
  LoadOrganizationsAction,
  ResetOrganizationStateAction,
  SaveOrganizationAction,
} from 'src/app/core/store/organization/actions';
import { State } from 'src/app/core/store/state';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { DialogEditCompanyComponent } from '../dialog-edit-company/dialog-edit-company.component';

@Component({
  selector: 'rh-admincenter-company',
  templateUrl: './organizations.component.html',
  styleUrls: ['./organizations.component.scss'],
  imports: [TranslateModule, CommonModule],
})
export class OrganizationsComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  permissions$!: Observable<LoggedUserPermissionsMisc>;
  organizations$!: Observable<OrganizationFolded[]>;
  organizations?: OrganizationFolded[];
  sortedOrganizations?: OrganizationFolded[];
  isLoading$!: Observable<boolean>;
  error$!: Observable<string>;
  success$!: Observable<string>;

  filter = 'detailed';
  appsToCheck = ['Argus', 'Metrology', 'Elearning', 'Videos'];

  organizationUnitsLicences: Map<number, Map<number, Licence[]>> = new Map();
  organizationUnitsApplicationsColors: Map<
    number,
    Map<number, Map<string, string>>
  > = new Map();

  organizationLicences: Map<number, Licence[]> = new Map();
  organizationApplicationsColors: Map<number, Map<string, string>> = new Map();

  isMobile = false;
  isMobilePortrait = false;
  isMobileLandscape = false;
  isTablePortrait = false;
  isTableLandscape = false;

  showUnits = false;

  constructor(
    public dialog: DialogService,
    private store$: Store<State>,
    private snackbar: SnackbarService,
    private route: ActivatedRoute,
    private router: Router,
    private responsive: BreakpointObserver,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTablePortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isTableLandscape = breakpoints[Breakpoints.TabletLandscape];
        }),
    );

    this.route.queryParams.subscribe((params) => {
      this.filter = params['filter'];
    });

    this.isLoading$ = this.store$.select((state) => {
      return state.Organization.isLoading as boolean;
    });

    this.error$ = this.store$.select((state) => {
      return state.Organization.error as string;
    });

    this.subscriptions.add(
      this.error$?.subscribe((error: string) => {
        if (error) {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `${error}`,
          });
        }
      }),
    );

    this.success$ = this.store$.select((state) => {
      return state.Organization.success as string;
    });

    this.subscriptions.add(
      this.success$?.subscribe((success: string) => {
        if (success) {
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Info,
            text: `${success}`,
          });
        }
      }),
    );

    this.organizations$ = this.store$.select((state) => {
      return state.Organization.organizations as OrganizationFolded[];
    });

    this.subscriptions.add(
      this.organizations$?.subscribe((organizations: OrganizationFolded[]) => {
        if (organizations) {
          this.organizations = organizations.sort((a, b) => {
            return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
          });
        }
      }),
    );

    this.subscriptions.add(
      combineLatest([this.organizations$, this.route.queryParams]).subscribe(
        ([organizations, params]) => {
          this.organizations = organizations;
          this.filter = params['filter'];
          if (this.organizations) {
            switch (this.filter) {
              case 'detailed':
                this.sortedOrganizations = this.organizations;
                this.sortedOrganizations = this.sortedOrganizations.filter(
                  (organization) => {
                    return organization.units.length > 0;
                  },
                );
                this.showUnits = true;
                break;

              case 'expiring':
                this.sortedOrganizations = this.filterOrganzationByStatusList([
                  'WARNING',
                  'DANGER',
                ]);
                this.sortedOrganizations = this.sortedOrganizations.filter(
                  (organization) => {
                    return organization.units.length > 0;
                  },
                );
                this.showUnits = true;
                break;

              case 'expired':
                this.sortedOrganizations = this.filterOrganzationByStatusList([
                  'DANGER',
                ]);
                this.sortedOrganizations = this.sortedOrganizations.filter(
                  (organization) => {
                    return organization.units.length > 0;
                  },
                );
                this.showUnits = true;
                break;

              default:
                this.sortedOrganizations = this.organizations;
                this.showUnits = false;
            }

            this.prepareInfos();
          }
        },
      ),
    );

    this.permissions$ = this.store$.select((state) => {
      return state.Auth.permissions as LoggedUserPermissionsMisc;
    });

    this.store$.dispatch(new LoadOrganizationsAction());
  }

  private prepareInfos() {
    if (!this.sortedOrganizations) {
      return;
    }

    if (this.showUnits) {
      this.sortedOrganizations.forEach((organization) => {
        organization.units = organization.units.sort((a, b) => {
          return a.name.toLowerCase() < b.name.toLowerCase() ? -1 : 1;
        });
      });
      this.sortedOrganizations.forEach((organization) => {
        this.organizationUnitsLicences.set(organization.id, new Map());

        organization.units.forEach((unit) => {
          this.organizationUnitsLicences.get(organization.id)?.set(unit.id, []);
          unit.licences.forEach((licence) => {
            this.organizationUnitsLicences
              .get(organization.id)
              ?.get(unit.id)
              ?.push(licence);
          });
        });
      });
    } else {
      this.sortedOrganizations.forEach((organization) => {
        this.organizationLicences.set(organization.id, []);

        organization.units.forEach((unit) => {
          unit.licences.forEach((licence) => {
            this.organizationLicences.get(organization.id)?.push(licence);
          });
        });
      });
    }

    this.calculateColors();
  }

  private calculateColors() {
    const applicationsToFind = [
      'elearning',
      'video instructions',
      'argus',
      'metrology',
    ];

    if (this.showUnits) {
      this.calculateUnitColors(applicationsToFind);
      return;
    } else {
      this.calculateOrganizationColors(applicationsToFind);
      return;
    }
  }

  private calculateUnitColors(applicationsToFind: string[]) {
    this.sortedOrganizations?.forEach((organization) => {
      this.organizationUnitsApplicationsColors.set(organization.id, new Map());

      organization.units.forEach((unit) => {
        this.organizationUnitsApplicationsColors
          .get(organization.id)
          ?.set(unit.id, new Map());

        const applicationsColors = new Map<string, string>();

        applicationsToFind.forEach((application) => {
          const licences = this.getUnitLicences(organization.id, unit.id);
          const applicationLicences = licences.filter(
            (licence) => licence.applicationName.toLowerCase() === application,
          );

          if (applicationLicences.length === 0) {
            applicationsColors.set(application, 'bg-brand-default');
            return;
          }

          if (applicationLicences.length === 0) {
            applicationsColors.set(application, 'bg-brand-default');
            return;
          }

          const resDanger = applicationLicences.some(
            (licence) => licence.status === 'DANGER',
          );

          if (resDanger) {
            applicationsColors.set(application, 'bg-brand-danger');
            return;
          }

          const resWarning = applicationLicences.some(
            (licence) => licence.status === 'WARNING',
          );

          if (resWarning) {
            applicationsColors.set(application, 'bg-brand-warning');
            return;
          }

          const resOk = applicationLicences.some(
            (licence) => licence.status === 'OK',
          );

          if (resOk) {
            applicationsColors.set(application, 'bg-brand-ok');
            return;
          }

          applicationsColors.set(application, 'bg-brand-default');
        });

        this.organizationUnitsApplicationsColors
          .get(organization.id)
          ?.set(unit.id, applicationsColors);
      });
    });
  }

  private calculateOrganizationColors(applicationsToFind: string[]) {
    this.sortedOrganizations?.forEach((organization) => {
      const applicationsColors = new Map<string, string>();

      const licences = this.getOrganizationLicences(organization.id);

      applicationsToFind.forEach((application) => {
        const applicationLicences = licences.filter(
          (licence) =>
            licence.applicationName.toLowerCase() === application.toLowerCase(),
        );

        if (applicationLicences.length === 0) {
          applicationsColors.set(application, 'bg-brand-default');
          return;
        }

        const resDanger = applicationLicences.some(
          (licence) => licence.status === 'DANGER',
        );

        if (resDanger) {
          applicationsColors.set(application, 'bg-brand-danger');
          return;
        }

        const resWarning = applicationLicences.some(
          (licence) => licence.status === 'WARNING',
        );

        if (resWarning) {
          applicationsColors.set(application, 'bg-brand-warning');
          return;
        }

        const resOk = applicationLicences.some(
          (licence) => licence.status === 'OK',
        );

        if (resOk) {
          applicationsColors.set(application, 'bg-brand-ok');
          return;
        }

        applicationsColors.set(application, 'bg-brand-default');
      });

      this.organizationApplicationsColors.set(
        organization.id,
        applicationsColors,
      );
    });
  }

  private getOrganizationLicences(organizationId: number): Licence[] {
    return this.organizationLicences.get(organizationId) ?? [];
  }

  private getUnitLicences(organizationId: number, unitId: number): Licence[] {
    return this.organizationUnitsLicences.get(organizationId)?.get(unitId) as
      | Licence[]
      | [];
  }

  getInvoiceIcon(
    organizationId: number,
    applicationName: string,
    unitId?: number,
  ): string {
    const appColor = this.getApplicationColor(
      organizationId,
      applicationName,
      unitId,
    );

    if (appColor === 'bg-brand-danger') {
      return '/assets/icons/Icon_Invoice_danger.svg';
    }

    if (appColor === 'bg-brand-warning') {
      return '/assets/icons/Icon_Invoice_warning.svg';
    }

    return '/assets/icons/Icon_Invoice_default.svg';
  }

  getApplicationColor(
    organizationId: number,
    applicationName: string,
    unitId?: number,
  ): string {
    if (this.showUnits && unitId) {
      const colors =
        this.organizationUnitsApplicationsColors.get(organizationId);
      return (
        colors?.get(unitId)?.get(applicationName.toLowerCase()) ??
        'bg-brand-default'
      );
    } else {
      const colors = this.organizationApplicationsColors.get(organizationId);
      return colors?.get(applicationName.toLowerCase()) ?? 'bg-brand-default';
    }
  }

  isInvoiceSent(
    organizationId: number,
    applicationName: string,
    unitId?: number,
  ): boolean {
    let licences: Licence[] = [];
    if (this.showUnits) {
      licences = this.getUnitLicences(organizationId, unitId as number);
    } else {
      licences = this.getOrganizationLicences(organizationId);
    }

    return licences.some(
      (licence) =>
        licence.applicationName.toLowerCase() ===
          applicationName.toLowerCase() && licence.isInvoiceSent,
    );
  }

  private filterOrganzationByStatusList(
    statusList: string[],
  ): OrganizationFolded[] {
    let organizations = Object.assign(
      [],
      this.organizations,
    ) as OrganizationFolded[];

    organizations = organizations.map((organization) => ({
      ...organization,
      units: organization.units.filter((unit) => {
        if (!unit.licences || unit.licences.length === 0) {
          return false;
        }

        return unit.licences.some((licence) => {
          return (
            this.appsToCheck.some(
              (app) =>
                licence.applicationName.toLowerCase() === app.toLowerCase(),
            ) &&
            !!statusList.find(
              (status) => licence.status.toLowerCase() === status.toLowerCase(),
            )
          );
        });
      }),
    }));

    return organizations;
  }

  navigateToOrganization(organizationId: number): void {
    if (this.isMobile) return;

    this.router.navigate(['/organizations', organizationId]);
  }

  async addOrganization(): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditCompanyComponent, {
      data: {
        organization: {
          id: 0,
          name: '',
        } as Organization,
        title: 'companyPopup.addCompanyTitle',
      },
    });

    const result = (await lastValueFrom(
      dialogRef.afterClosed(),
    )) as Organization;
    if (!result) {
      return;
    } else {
      this.store$.dispatch(new AddOrganizationAction(result.name));
    }
  }

  async editOrganization(organization: OrganizationFolded): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditCompanyComponent, {
      data: {
        organization: {
          id: organization.id,
          name: organization.name,
        },
        title: 'companyPopup.editCompanyTitle',
      },
    });

    const result = (await lastValueFrom(
      dialogRef.afterClosed(),
    )) as Organization;

    if (!result) {
      return;
    } else {
      this.store$.dispatch(new SaveOrganizationAction(result));
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.store$.dispatch(new ResetOrganizationStateAction());
  }
}
