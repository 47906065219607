import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_USERS: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_USERS_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        users: action.users,
      };
    }
    case ActionTypes.LOAD_USERS_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        users: [],
      };
    }
    case ActionTypes.RESET_USER_STORE: {
      return {
        ...state,
        users: undefined,
        isLoading: false,
        error: undefined,
      };
    }
    default: {
      return state;
    }
  }
}
