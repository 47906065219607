import { Auth0User } from '../../models/auth0User.model';
import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_USER: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_USER_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        user: action.user,
      };
    }
    case ActionTypes.LOAD_USER_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
      };
    }
    case ActionTypes.CHANGE_LANGUAGE: {
      return {
        ...state,
        user: {
          ...state.user,
          language: action.languageKey,
        } as Auth0User,
      };
    }
    case ActionTypes.CHANGE_LANGUAGE_FAILURE: {
      return {
        ...state,
        error: action.error,
      };
    }
    case ActionTypes.LOAD_LOGGED_USER_PERMISSIONS_MISC: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_LOGGED_USER_PERMISSIONS_MISC_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        permissions: action.loggedUserPermissionsMisc,
      };
    }
    case ActionTypes.LOAD_LOGGED_USER_PERMISSIONS_MISC_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        permissions: undefined,
      };
    }
    default: {
      return state;
    }
  }
}
