import { CommonModule } from '@angular/common';
import { Component, EventEmitter, inject, Input, Output } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { lastValueFrom } from 'rxjs';
import { Application } from 'src/app/core/models/application.model';
import { Machine } from 'src/app/core/models/machine.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
  AddLicenceAction,
  DeleteLicenceAction,
} from 'src/app/core/store/organization/actions';
import { DeleteDialogComponent } from 'src/app/shared/components/delete-dialog/delete-dialog.component';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';
import {
  UnitMachinesTableComponent
} from "../../../../shared/components/unit-machines-table/unit-machines-table.component";

@Component({
  selector: 'rh-admincenter-unit-application',
  imports: [
    CommonModule,
    TranslateModule,
    LoadingComponent,
    UnitMachinesTableComponent,
  ],
  templateUrl: './unit-application.component.html',
  styleUrl: './unit-application.component.scss',
})
export class UnitApplicationComponent {
  private store = inject(Store);
  private snackbar = inject(SnackbarService);
  private dialog = inject(DialogService);

  @Input({ required: true }) companyId!: number;
  @Input({ required: true }) unitId!: number;
  @Input({ required: true }) application!: Application;
  @Input({ required: true }) licenceApplication?: Application;
  @Input({ required: true }) canUpdateLicences!: boolean;
  @Input() machines?: Machine[];
  @Input() machineTypes!: string[];
  @Input( {required:true })
  proxyMap!: Map<number, string[]>;
  @Output() validToChanged = new EventEmitter<{
    validTo: string;
  }>();
  @Output() isInvoiceSentUpdated = new EventEmitter();
  @Output() machineAdded = new EventEmitter<Machine>();
  @Output() machineUpdated = new EventEmitter<Machine>();
  @Output() machineDeleted = new EventEmitter<number>();

  isOpen = false;
  newMachine: Machine | null = null;

  editLicenceValidTo(validTo: Event) {
    const validToString = (validTo.target as HTMLInputElement).value;

    this.validToChanged.emit({
      validTo: validToString,
    });
  }

  onMachineAdded(machine: Machine) {
    this.newMachine = null;
    this.machines = undefined;
    this.machineAdded.emit(machine);
  }

  onMachineUpdated(machine: Machine) {
    this.machines = undefined;
    this.machineUpdated.emit(machine);
  }

  async onMachineDeleted(machineId: number) {
    const dialogRef = this.dialog.open(DeleteDialogComponent, {
      data: {
        title: `deletePopup.deleteMachine.title`,
        message: `deletePopup.deleteMachine.message`,
      },
    });
    const result = await lastValueFrom(dialogRef.afterClosed());

    if (!result) {
      return;
    }

    this.machines = undefined;
    this.machineDeleted.emit(machineId);
  }

  addMachine() {
    this.newMachine = {
      id: 0,
      machineNumber: 0,
      unitId: this.unitId,
      organizationalContext: {
        companyId: 0,
        companyName: '',
        unitId: 0,
        unitName: '',
      },
      type: this.machineTypes[0],
      proxyId: '',
      ipAddress: '',
      creationDate: new Date().toISOString(),
      argusInvoice: false,
      maasInvoice: false,
      machineAlias: '',
      argusLicenseValidTo: new Date().toISOString(),
      maasLicenseValidTo: new Date().toISOString(),
      isMobile: false,
    };
  }

  updateIsInvoiceSent() {
    this.isInvoiceSentUpdated.emit({ applicationId: this.application.id });
  }

  findFeature(featureId: number) {
    return this.licenceApplication?.features.find(
      (feature) => feature.id === featureId,
    );
  }

  findSubFeature(featureId: number, subFeatureId: number) {
    return this.findFeature(featureId)?.subFeatures.find(
      (subFeature) => subFeature.id === subFeatureId,
    );
  }

  updateLicence(id: number, licenceId?: number) {
    if (licenceId) {
      this.store.dispatch(
        new DeleteLicenceAction(this.application.id, licenceId),
      );
    } else {
      if (!this.licenceApplication?.validTo) {
        this.snackbar.show(SnackbarComponent, {
          type: SnackbarType.Error,
          text: 'errors.licences.noValidTo',
        });
        return;
      }

      this.store.dispatch(
        new AddLicenceAction(
          this.unitId,
          this.licenceApplication.validTo,
          id,
          this.application.id,
        ),
      );
    }
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }
}
