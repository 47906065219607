import { AsyncPipe } from '@angular/common';
import { Component, inject } from '@angular/core';
import { Store } from '@ngrx/store';
import { Machine } from 'src/app/core/models/machine.model';
import { State } from 'src/app/core/store/state';

@Component({
  selector: 'rh-admincenter-machines',
  imports: [AsyncPipe],
  templateUrl: './machines.component.html',
  styleUrl: './machines.component.scss',
})
export class MachinesComponent {
  private store = inject(Store<State>);

  machines$ = this.store.select((state) => {
    return state.Machine.machines as Machine[];
  });

  getMachineStatus(
    argusLicenseValidTo: Date,
    maasLicenseValidTo: Date,
  ): string {
    const currentDate = new Date();
    if (
      (argusLicenseValidTo &&
        argusLicenseValidTo.getTime() - currentDate.getTime() <=
          30 * 24 * 60 * 60 * 1000) ||
      (maasLicenseValidTo &&
        maasLicenseValidTo.getTime() - currentDate.getTime() <=
          30 * 24 * 60 * 60 * 1000)
    ) {
      return 'DANGER';
    } else if (
      (argusLicenseValidTo &&
        argusLicenseValidTo.getTime() - currentDate.getTime() <=
          90 * 24 * 60 * 60 * 1000) ||
      (maasLicenseValidTo &&
        maasLicenseValidTo.getTime() - currentDate.getTime() <=
          90 * 24 * 60 * 60 * 1000)
    ) {
      return 'WARNING';
    } else {
      return 'OK';
    }
  }
}
