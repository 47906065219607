<div class="w-full" [ngClass]="{ 'text-white/50': blueMode }">
  @if (title) {
    <div
      class="control-title font-brand-regular text-xs uppercase text-brand-blue-400"
      [ngClass]="{ 'text-[#bbc6de]': blueMode }"
    >
      {{ title | translate }}
    </div>
  }
  <div #dropdownContainer class="relative flex flex-col text-base" (focusout)="onFocusOutEvent($event)">
    <button
      class="flex h-8 items-center justify-between gap-2 rounded-md border border-brand-blue-240 px-3 font-brand-regular text-brand-blue-600"
      [ngClass]="{
        'border-[#ff0000]': !model && invalidIfEmpty,
        'bg-inherit text-white': blueMode,
      }"
      [disabled]="disabled"
      (click)="toggle()"
      [title]="model || placeholder | translate"
    >
      <p class="overflow-hidden text-ellipsis whitespace-nowrap">
        {{ model || placeholder | translate }}
      </p>

      <div class="flex items-center gap-2">
        @if (clearable && model) {
          <button
            class="flex h-8 w-2 items-center justify-center"
            (click)="clear(); $event.stopPropagation(); $event.preventDefault()"
          >
            <img src="./assets/icons/icon-close.svg" />
          </button>
        }
        <div class="w-3 transition duration-300">
          @if (blueMode) {
            <img
              [ngClass]="{ 'rotate-180 transition duration-300': listVisible }"
              src="./assets/icons/chevron-down-white.svg"
            />
          } @else {
            <img
              [ngClass]="{ 'rotate-180 transition duration-300': listVisible }"
              src="./assets/icons/chevron-down.svg"
            />
          }
        </div>
      </div>
    </button>

    @if (listVisible) {
      <div
        class="options-list absolute left-0 top-full z-50 max-h-[300px] min-w-full overflow-y-auto rounded-md bg-brand-blue-550 font-brand-regular text-base text-white"
        >
    @if (withInput) {
      <div class="p-2">
        <input
          class="mb-2 h-8 w-full rounded border border-brand-blue-240 px-3 text-brand-blue-600 focus:outline-none"
          type="text"
          [(ngModel)]="newEntry"
          (keyup.enter)="addEntry()"
        />
      </div>
    }

        @for (item of items; track item) {
          <div
            [ngClass]="{ 'bg-brand-blue-500': isSelected(item) }"
            class="flex h-9 w-full grow-0 cursor-pointer items-center px-3 hover:bg-brand-blue-500"
            [title]="item | translate"
            (click)="select(item)"
          >
            <p class="overflow-hidden text-ellipsis whitespace-nowrap">
              {{ item | translate }}
            </p>
          </div>
        }
      </div>
    }
  </div>
</div>
