import { CommonModule } from '@angular/common';
import { Component, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { Observable, Subscription } from 'rxjs';
import { User } from 'src/app/core/models/user.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { State } from 'src/app/core/store/state';
import {
  LoadUsersAction,
  ResetUserStoreAction,
} from 'src/app/core/store/user/actions';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { LoadingComponent } from '../../../../shared/components/loading/loading.component';

@Component({
  selector: 'rh-admincenter-user',
  templateUrl: './users.component.html',
  styleUrls: ['./users.component.scss'],
  imports: [LoadingComponent, CommonModule],
})
export class UsersComponent implements OnInit, OnDestroy {
  users$!: Observable<User[]>;
  users?: User[] = [];
  isLoading$!: Observable<boolean>;
  isLoading?: boolean;
  error$!: Observable<string>;
  error?: string;

  sortNameReverse = false;
  sortEmailReverse = false;
  sortCompanyReverse = false;
  sortUnitReverse = false;

  groupedUsers: { [fullName: string]: User[] } = {};

  private subscriptions: Subscription = new Subscription();

  sortField: 'email' | 'company' | 'unit' = 'email';
  sortDirection: 'asc' | 'desc' = 'asc';

  constructor(
    private store$: Store<State>,
    public dialog: DialogService,
    private snackbar: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.isLoading$ = this.store$.select((state) => {
      return state.User.isLoading as boolean;
    });

    this.subscriptions.add(
      this.isLoading$?.subscribe((data: boolean) => {
        this.isLoading = data;
      }),
    );

    this.error$ = this.store$.select((state) => {
      return state.User.error as string;
    });

    this.subscriptions.add(
      this.error$?.subscribe((data: string) => {
        if (data) {
          this.error = data;
          console.error(this.error);
          this.snackbar.show(SnackbarComponent, {
            type: SnackbarType.Error,
            text: `${this.error}`,
          });
        }
      }),
    );

    this.users$ = this.store$.select((state) => {
      return state.User.users as User[];
    });

    this.subscriptions.add(
      this.users$?.subscribe((data: User[]) => {
        if (data) {
          this.users = data;
          this.groupUsersByName();
        }
      }),
    );

    this.store$.dispatch(new LoadUsersAction());
  }

  private groupUsersByName() {
    this.groupedUsers = {};
    this.users?.forEach(user => {
      const fullName = `${user.givenName} ${user.familyName}`.trim();
      if (!this.groupedUsers[fullName]) {
        this.groupedUsers[fullName] = [];
      }
      this.groupedUsers[fullName].push(user);
    });
  }

  sort(field: 'email' | 'company' | 'unit') {
    if (this.sortField === field) {
      this.sortDirection = this.sortDirection === 'asc' ? 'desc' : 'asc';
    } else {
      this.sortField = field;
      this.sortDirection = 'asc';
    }

    // Sort users within each group
    Object.keys(this.groupedUsers).forEach(key => {
      this.groupedUsers[key].sort((a, b) => {
        let comparison = 0;
        switch (field) {
          case 'email':
            comparison = a.email.localeCompare(b.email);
            break;
          case 'company':
            comparison = a.companyName.localeCompare(b.companyName);
            break;
          case 'unit':
            comparison = a.unitName.localeCompare(b.unitName);
            break;
        }
        return this.sortDirection === 'asc' ? comparison : -comparison;
      });
    });
  }

  getSortIconClass(field: string): string {
    if (this.sortField !== field) return 'opacity-0';
    return this.sortDirection === 'asc' ? 'rotate-180' : 'rotate-0';
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
    this.dialog.close();
    this.store$.dispatch(new ResetUserStoreAction());
  }
}
