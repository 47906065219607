import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Machine } from '../../models/machine.model';

@Injectable({
  providedIn: 'root',
})
export class MachineService {
  private http = inject(HttpClient);

  GetMachines(): Observable<Machine[]> {
    return this.http
      .get<Machine[]>(`${environment.backendApiUrl}/api/machines`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
