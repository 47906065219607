import { Actions, ActionTypes } from './actions';
import { initialState, State } from './state';

export function featureReducer(state = initialState, action: Actions): State {
  switch (action.type) {
    case ActionTypes.LOAD_MACHINES: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.LOAD_MACHINES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        machines: action.machines,
      };
    }
    case ActionTypes.LOAD_MACHINES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        machines: [],
      };
    }
    case ActionTypes.ADD_MACHINE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.ADD_MACHINE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        machines: [action.machine, ...(state.machines || [])],
      };
    }
    case ActionTypes.ADD_MACHINE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        machines: [...(state.machines || [])],
      };
    }
    case ActionTypes.UPDATE_MACHINE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.UPDATE_MACHINE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        machines: state.machines?.map((machine) => {
          if (machine.id === action.machine.id) {
            return action.machine;
          }
          return machine;
        }),
      };
    }
    case ActionTypes.UPDATE_MACHINE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        machines: [...(state.machines || [])],
      };
    }
    case ActionTypes.DELETE_MACHINE: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.DELETE_MACHINE_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        machines: state.machines?.filter(
          (machine) => machine.id !== action.machineId,
        ),
      };
    }
    case ActionTypes.DELETE_MACHINE_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        machines: [...(state.machines || [])],
      };
    }
    case ActionTypes.GET_MACHINE_TYPES: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.GET_MACHINE_TYPES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        types: action.machineTypes,
      };
    }
    case ActionTypes.GET_MACHINE_TYPES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        types: [],
      };
    }
    case ActionTypes.GET_PROXIES: {
      return {
        ...state,
        isLoading: true,
        error: undefined,
      };
    }
    case ActionTypes.GET_PROXIES_SUCCESS: {
      return {
        ...state,
        isLoading: false,
        proxies: Object.entries(action.proxies).reduce((acc, [key, value]) => {
          acc.set(Number(key), value);
          return acc
        }, new Map<number, string[]>()),
      };
    }
    case ActionTypes.GET_PROXIES_FAILURE: {
      return {
        ...state,
        isLoading: false,
        error: action.error,
        proxies: undefined,
      };
    }
    case ActionTypes.RESET_MACHINE_STORE: {
      return {
        ...state,
        machines: undefined,
        isLoading: false,
        error: undefined,
      };
    }
    default: {
      return state;
    }
  }
}
