import { User } from '../../models/user.model';

export interface State {
  users?: User[];
  isLoading: boolean;
  error?: string;
}

export const initialState: State = {
  isLoading: true,
};
