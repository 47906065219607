import { CommonModule } from '@angular/common';
import { Component, Inject } from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { ISnackbarData } from '../../services/snackbar/core/snackbar-data.interface';
import { SnackbarType } from '../../services/snackbar/core/snackbar-type.enum';
import { SNACKBAR_DATA } from '../../services/snackbar/snackbar-tokens';

@Component({
  selector: 'rh-admincenter-snackbar',
  templateUrl: './snackbar.component.html',
  styleUrls: ['./snackbar.component.scss'],
  imports: [TranslateModule, CommonModule],
})
export class SnackbarComponent {
  public snackbarTypes = SnackbarType;

  constructor(@Inject(SNACKBAR_DATA) public data: ISnackbarData) {
    console.log('Snackbar', data);
  }
}
