@if (!isLoading) {
  <div class="flex flex-col gap-10">
    <div class="flex flex-col gap-12 rounded-lg bg-white p-10 shadow-md">
      <div class="flex justify-between">
        <div
          class="flex items-center font-brand-light text-2xl uppercase leading-6 text-brand-blue-800"
        >
          <span>{{ "applications.clusters" | translate }}</span>
        </div>
        <div class="flex items-center">
          <button
            (click)="addCluster()"
            class="flex items-center gap-2 rounded-md border bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
          >
            <div>{{ "applications.addClusterButton" | translate }}</div>
            <div>
              <img
                class="h-6 w-6"
                src="/assets/icons/Reishauer_Icons-Add.svg"
                alt="Icon add"
              />
            </div>
          </button>
        </div>
      </div>
      @if (clusters) {
        <div>
          <table
            class="min-w-full rounded-md font-brand-regular text-[13px] leading-[13px]"
          >
            <thead>
              <tr>
                <th
                  scope="col"
                  class="w-1/2 border-b border-brand-gray-light pl-2 pr-3 text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue sm:w-40 sm:px-2"
                >
                  {{ "shared.tables.tableHeaderDisplayName" | translate }}
                </th>
                <th
                  scope="col"
                  class="w-auto border-b border-brand-gray-light text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue"
                ></th>
              </tr>
            </thead>
            <tbody
              class="divide-y divide-brand-gray-light align-middle text-brand-blue-800"
            >
              @for (cluster of clusters; track cluster) {
                <tr class="cursor-pointer hover:bg-brand-blue-lighter">
                  <td class="w-full py-1 pl-2">
                    {{ cluster.name }}
                  </td>
                  <td
                    class="flex w-auto items-center justify-center border-0 py-1"
                  >
                    <div class="flex gap-4 pl-4">
                      <button
                        (click)="editCluster(cluster)"
                        class="relative flex h-6 w-12 items-center rounded-md border border-brand-gray-light bg-white p-4 px-3 text-sm uppercase hover:bg-brand-blue-light"
                      >
                        <img
                          src="/assets/icons/Reishauer_Icon_Edit.svg"
                          alt="Edit Cluster"
                          class="fill-brand-blue-900"
                        />
                      </button>
                      <button
                        (click)="deleteCluster(cluster)"
                        class="relative flex h-6 w-12 items-center rounded-md border border-brand-gray-light bg-white p-4 px-3 text-sm uppercase hover:bg-brand-blue-light"
                      >
                        <img
                          src="/assets/icon-delete.svg"
                          alt="Delete Cluster"
                          class="fill-brand-red"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              }
              @if (clusters.length === 0) {
                <tr>
                  <td colspan="2" class="py-4 text-center">
                    {{ "errors.tables.noClusters" | translate }}
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
    </div>
    @if (clusters) {
      <div class="flex flex-col gap-12 rounded-lg bg-white p-10 shadow-md">
        <div class="flex justify-between">
          <div
            class="flex items-center font-brand-light text-2xl uppercase leading-6 text-brand-blue-800"
          >
            <span> {{ "applications.applications" | translate }}</span>
          </div>
          <div class="flex items-center">
            <button
              (click)="addApplication()"
              class="flex items-center gap-2 rounded-md border bg-white p-2 text-sm uppercase text-[#1F3D7D] hover:bg-brand-blue-light"
            >
              <div>{{ "applications.addApplicationButton" | translate }}</div>
              <div>
                <img
                  class="h-6 w-6"
                  src="/assets/icons/Reishauer_Icons-Add.svg"
                  alt="Icon add"
                />
              </div>
            </button>
          </div>
        </div>
        <table
          class="min-w-full rounded-md font-brand-regular text-[13px] leading-[13px]"
        >
          <thead>
            <tr>
              <th
                scope="col"
                class="w-1/2 border-b border-brand-gray-light pl-2 pr-3 text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue sm:w-1/6 sm:px-2"
              >
                {{ "shared.tables.tableHeaderName" | translate }}
              </th>
              <th
                scope="col"
                class="w-1/2 border-b border-brand-gray-light text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue sm:w-auto"
              >
                {{ "shared.tables.tableHeaderCluster" | translate }}
              </th>
              <th
                scope="col"
                class="w-auto border-b border-brand-gray-light text-left align-bottom text-[11px] font-normal uppercase leading-[11px] text-brand-blue"
              ></th>
            </tr>
          </thead>
          <tbody
            class="divide-y divide-brand-gray-light align-middle text-brand-blue-800"
          >
            @for (cluster of clusters; track cluster) {
              @for (application of cluster.applications; track application) {
                <tr class="cursor-pointer hover:bg-brand-blue-lighter">
                  <td class="w-auto px-2 py-1">
                    <button
                      (click)="openApplicationDetails(application.id)"
                      class="w-full text-start"
                    >
                      {{ application.name }}
                    </button>
                  </td>
                  <td class="w-full py-1">
                    <button
                      (click)="openApplicationDetails(application.id)"
                      class="w-full text-start"
                    >
                      {{ cluster.name }}
                    </button>
                  </td>
                  <td
                    class="flex w-auto items-center justify-center border-0 py-1"
                  >
                    <div class="flex gap-4 pl-4">
                      <button
                        (click)="openApplicationDetails(application.id)"
                        class="relative flex h-6 w-12 items-center rounded-md border border-brand-gray-light bg-white p-4 px-3 text-sm uppercase hover:bg-brand-blue-light"
                      >
                        <img
                          src="/assets/icons/Reishauer_Icon_Edit.svg"
                          alt="Edit Application"
                          class="fill-brand-blue-900"
                        />
                      </button>
                      <button
                        (click)="deleteApplication(cluster.id, application.id)"
                        class="relative flex h-6 w-12 items-center rounded-md border border-brand-gray-light bg-white p-4 px-3 text-sm uppercase hover:bg-brand-blue-light"
                      >
                        <img
                          src="/assets/icon-delete.svg"
                          alt="Delete Application"
                          class="fill-brand-red"
                        />
                      </button>
                    </div>
                  </td>
                </tr>
              }
            }
            @if (clusters.length === 0) {
              <tr>
                <td colspan="2" class="py-4 text-center">
                  {{ "errors.tables.noApplications" | translate }}
                </td>
              </tr>
            }
          </tbody>
        </table>
      </div>
    }
  </div>
}

@if (isLoading) {
  <div class="loading-spinner-container">
    <rh-admincenter-loading></rh-admincenter-loading>
  </div>
}
