import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { ApplicationCluster } from 'src/app/core/models/applicationCluster.mode';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-admincenter-dialog-edit-cluster',
  templateUrl: './dialog-edit-cluster.component.html',
  styleUrls: ['./dialog-edit-cluster.component.scss'],
  imports: [TranslateModule, CommonModule, ReactiveFormsModule],
})
export class DialogEditClusterComponent implements OnInit {
  form!: FormGroup;
  applicationCluster?: ApplicationCluster;
  title!: string;

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      applicationCluster: ApplicationCluster;
      title: string;
    },
  ) {
    if (data) {
      this.applicationCluster = Object.assign({}, data.applicationCluster);
      this.title = data.title;
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.applicationCluster?.name, Validators.required],
    });
  }

  get name() {
    return this.form.get('name');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const applicationCluster = {
        ...this.applicationCluster,
        name: this.name?.value,
      } as ApplicationCluster;

      this.dialogRef.close(applicationCluster);
    }
  }
}
