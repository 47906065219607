import { Injectable } from '@angular/core';
import { Actions, createEffect, ofType } from '@ngrx/effects';
import { TranslateService } from '@ngx-translate/core';
import { of as observableOf } from 'rxjs';
import { catchError, exhaustMap, map, switchMap, tap } from 'rxjs/operators';
import { AuthenticateService } from '../../services/auth/auth.service';
import {
  ActionTypes,
  ChangeLanguageAction,
  ChangeLanguageFailureAction,
  ChangeLanguageSuccessAction,
  LoadLoggedUserPermissionsMiscAction,
  LoadLoggedUserPermissionsMiscFailureAction,
  LoadLoggedUserPermissionsMiscSuccessAction,
  LoadUserAction,
  LoadUserFailureAction,
  LoadUserSuccessAction,
  LogOutUserAction,
} from './actions';

@Injectable()
export class AuthStoreEffects {
  constructor(
    private authService: AuthenticateService,
    private actions$: Actions,
    private translate: TranslateService,
  ) {}

  loadUserEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadUserAction>(ActionTypes.LOAD_USER),
      exhaustMap(() =>
        this.authService.getUser().pipe(
          map((res) => new LoadUserSuccessAction(res)),
          catchError((error) =>
            observableOf(new LoadUserFailureAction(error.error.detail)),
          ),
        ),
      ),
    ),
  );

  loadUserSuccessEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LoadUserSuccessAction>(ActionTypes.LOAD_USER_SUCCESS),
        switchMap((action) => this.translate.use(action.user.language)),
      ),
    { dispatch: false },
  );

  logoutUserEffect$ = createEffect(
    () =>
      this.actions$.pipe(
        ofType<LogOutUserAction>(ActionTypes.LOGOUT_USER),
        tap(() => {
          this.authService.logout();
        }),
      ),
    { dispatch: false },
  );

  loadLoggedUserPermissionsMiscEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<LoadLoggedUserPermissionsMiscAction>(
        ActionTypes.LOAD_LOGGED_USER_PERMISSIONS_MISC,
      ),
      exhaustMap(() =>
        this.authService.GetLoggedUserPermissions().pipe(
          map((items) => new LoadLoggedUserPermissionsMiscSuccessAction(items)),
          catchError((error) =>
            observableOf(
              new LoadLoggedUserPermissionsMiscFailureAction(
                error.error.detail,
              ),
            ),
          ),
        ),
      ),
    ),
  );

  changeLanguageEffect$ = createEffect(() =>
    this.actions$.pipe(
      ofType<ChangeLanguageAction>(ActionTypes.CHANGE_LANGUAGE),
      tap((action) => {
        this.translate.use(action.languageKey);
      }),
      switchMap((action) =>
        this.authService.ChangeLanguage(action.languageKey).pipe(
          map(() => new ChangeLanguageSuccessAction()),
          catchError((error) =>
            observableOf(new ChangeLanguageFailureAction(error)),
          ),
        ),
      ),
    ),
  );
}
