import { Routes } from '@angular/router';
import { authGuardFn } from '@auth0/auth0-angular';
import { canSeeApplicationGuard } from './core/guards/can-see-application.guard';
import { ApplicationDetailComponent } from './features/applications/components/applicationdetail/applicationdetail.component';
import { ApplicationsComponent } from './features/applications/components/applications/applications.component';
import { MachinesComponent } from './features/machines/components/machines/machines.component';
import { CompanyDetailComponent } from './features/organizations/components/company-detail/company-detail.component';
import { OrganizationsComponent } from './features/organizations/components/organizations/organizations.component';
import { UsersComponent } from './features/users/components/users/users.component';

export const routes: Routes = [
  {
    path: 'applications',
    canActivate: [authGuardFn, canSeeApplicationGuard],
    children: [
      {
        path: '',
        component: ApplicationsComponent,
      },
      {
        path: ':id',
        component: ApplicationDetailComponent,
      },
    ],
  },
  {
    path: 'organizations',
    canActivate: [authGuardFn],
    children: [
      {
        path: '',
        component: OrganizationsComponent,
      },
      {
        path: ':organizationId',
        component: CompanyDetailComponent,
      },
    ],
  },
  {
    path: 'users',
    component: UsersComponent,
  },
  {
    path: 'machines',
    component: MachinesComponent,
  },
  {
    path: '',
    redirectTo: 'organizations',
    pathMatch: 'full',
  },
];
