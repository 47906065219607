import { Action, createAction, props } from '@ngrx/store';
import { ApplicationCluster } from '../../models/applicationCluster.mode';
import { ApplicationRoles } from '../../models/applicationRoles.model';
import { Cluster } from '../../models/cluster.model';
import { Feature } from '../../models/feature.model';
import { Role } from '../../models/role.model';
import { SubFeature } from '../../models/subFeature.model';

export enum ActionTypes {
  // Cluster
  LOAD_CLUSTERS = '[Application] Load Clusters Data',
  LOAD_CLUSTERS_SUCCESS = '[Application] Load Clusters Success',
  LOAD_CLUSTERS_FAILURE = '[Application] Load Clusters Failure',
  ADD_CLUSTER = '[Application] Add Cluster',
  ADD_CLUSTER_SUCCESS = '[Application] Add Cluster Success',
  ADD_CLUSTER_FAILURE = '[Application] Add Cluster Failure',
  SAVE_CLUSTER = '[Application] Save Cluster',
  SAVE_CLUSTER_SUCCESS = '[Application] Save Cluster Success',
  SAVE_CLUSTER_FAILURE = '[Application] Save Cluster Failure',
  DELETE_CLUSTER = '[Application] Delete Cluster',
  DELETE_CLUSTER_SUCCESS = '[Application] Delete Cluster Success',
  DELETE_CLUSTER_FAILURE = '[Application] Delete Cluster Failure',
  // Application
  ADD_APPLICATION = '[Application] Add Application',
  ADD_APPLICATION_SUCCESS = '[Application] Add Application Success',
  ADD_APPLICATION_FAILURE = '[Application] Add Application Failure',
  SAVE_APPLICATION = '[Application] Save Application',
  SAVE_APPLICATION_SUCCESS = '[Application] Save Application Success',
  SAVE_APPLICATION_FAILURE = '[Application] Save Application Failure',
  DELETE_APPLICATION = '[Application] Delete Application',
  DELETE_APPLICATION_SUCCESS = '[Application] Delete Application Success',
  DELETE_APPLICATION_FAILURE = '[Application] Delete Application Failure',
  // Role
  LOAD_APPLICATIONS_ROLES = '[Application] Load Applications Roles Data',
  LOAD_APPLICATIONS_ROLES_SUCCESS = '[Application] Load Applications Roles Success',
  LOAD_APPLICATIONS_ROLES_FAILURE = '[Application] Load Applications Roles Failure',
  LOAD_ROLES_BY_APPLICATION_ID = '[Application] Load Roles By Application Id Data',
  LOAD_ROLES_BY_APPLICATION_ID_SUCCESS = '[Application] Load Roles By Application Id Success',
  LOAD_ROLES_BY_APPLICATION_ID_FAILURE = '[Application] Load Roles By Application Id Failure',
  ADD_ROLE = '[Application] Add Role',
  ADD_ROLE_SUCCESS = '[Application] Add Role Success',
  ADD_ROLE_FAILURE = '[Application] Add Role Failure',
  SAVE_ROLE = '[Application] Save Role',
  SAVE_ROLE_SUCCESS = '[Application] Save Role Success',
  SAVE_ROLE_FAILURE = '[Application] Save Role Failure',
  DELETE_ROLE = '[Application] Delete Role',
  DELETE_ROLE_SUCCESS = '[Application] Delete Role Success',
  DELETE_ROLE_FAILURE = '[Application] Delete Role Failure',
  // Feature
  LOAD_FEATURES_BY_APPLICATION_ID = '[Application] Load Features By Application Id Data',
  LOAD_FEATURES_BY_APPLICATION_ID_SUCCESS = '[Application] Load Features By Application Id Success',
  LOAD_FEATURES_BY_APPLICATION_ID_FAILURE = '[Application] Load Features By Application Id Failure',
  ADD_FEATURE = '[Application] Add Feature',
  ADD_FEATURE_SUCCESS = '[Application] Add Feature Success',
  ADD_FEATURE_FAILURE = '[Application] Add Feature Failure',
  ADD_SUB_FEATURE = '[Application] Add SubFeature',
  ADD_SUB_FEATURE_SUCCESS = '[Application] Add SubFeature Success',
  ADD_SUB_FEATURE_FAILURE = '[Application] Add SubFeature Failure',
  SAVE_FEATURE = '[Application] Save Feature',
  SAVE_FEATURE_SUCCESS = '[Application] Save Feature Success',
  SAVE_FEATURE_FAILURE = '[Application] Save Feature Failure',
  SAVE_SUB_FEATURE = '[Application] Save SubFeature',
  SAVE_SUB_FEATURE_SUCCESS = '[Application] Save SubFeature Success',
  SAVE_SUB_FEATURE_FAILURE = '[Application] Save SubFeature Failure',
  DELETE_FEATURE = '[Application] Delete Feature',
  DELETE_FEATURE_SUCCESS = '[Application] Delete Feature Success',
  DELETE_FEATURE_FAILURE = '[Application] Delete Feature Failure',
  DELETE_SUB_FEATURE = '[Application] Delete SubFeature',
  DELETE_SUB_FEATURE_SUCCESS = '[Application] Delete SubFeature Success',
  DELETE_SUB_FEATURE_FAILURE = '[Application] Delete SubFeature Failure',

  RESET_APPLICATION_STATE = '[Application] Reset Application State',
  RESET_APPLICATION_MESSAGES = '[Application] Reset Application Messages',
}

// Cluster

export class LoadClustersAction implements Action {
  readonly type = ActionTypes.LOAD_CLUSTERS;
}

export class LoadClustersSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_CLUSTERS_SUCCESS;
  constructor(public clusters: Cluster[]) {}
}

export class LoadClustersFailureAction implements Action {
  readonly type = ActionTypes.LOAD_CLUSTERS_FAILURE;
  constructor(public error: string) {}
}

export class AddClusterAction implements Action {
  readonly type = ActionTypes.ADD_CLUSTER;
  constructor(public clusterName: string) {}
}

export class AddClusterSuccessAction implements Action {
  readonly type = ActionTypes.ADD_CLUSTER_SUCCESS;
  constructor(public cluster: { id: number; name: string }) {}
}

export class AddClusterFailureAction implements Action {
  readonly type = ActionTypes.ADD_CLUSTER_FAILURE;
  constructor(public error: string) {}
}

export class SaveClusterAction implements Action {
  readonly type = ActionTypes.SAVE_CLUSTER;
  constructor(public cluster: ApplicationCluster) {}
}

export class SaveClusterSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_CLUSTER_SUCCESS;
  constructor(public cluster: { id: number; name: string }) {}
}

export class SaveClusterFailureAction implements Action {
  readonly type = ActionTypes.SAVE_CLUSTER_FAILURE;
  constructor(public error: string) {}
}

export class DeleteClusterAction implements Action {
  readonly type = ActionTypes.DELETE_CLUSTER;
  constructor(public clusterId: number) {}
}

export class DeleteClusterSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_CLUSTER_SUCCESS;
  constructor(public clusterId: number) {}
}

export class DeleteClusterFailureAction implements Action {
  readonly type = ActionTypes.DELETE_CLUSTER_FAILURE;
  constructor(public error: string) {}
}

// Application

export class AddApplicationAction implements Action {
  readonly type = ActionTypes.ADD_APPLICATION;
  constructor(
    public clusterId: number,
    public name: string,
  ) {}
}

export class AddApplicationSuccessAction implements Action {
  readonly type = ActionTypes.ADD_APPLICATION_SUCCESS;
  constructor(
    public application: {
      id: number;
      applicationClusterId: number;
      name: string;
    },
  ) {}
}

export class AddApplicationFailureAction implements Action {
  readonly type = ActionTypes.ADD_APPLICATION_FAILURE;
  constructor(public error: string) {}
}

export class SaveApplicationAction implements Action {
  readonly type = ActionTypes.SAVE_APPLICATION;
  constructor(
    public applicationId: number,
    public clusterId: number,
    public name: string,
    public multiRolesAllowed: boolean,
    public hasMachines: boolean,
  ) {}
}

export class SaveApplicationSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_APPLICATION_SUCCESS;
  constructor(
    public application: {
      id: number;
      clusterId: number;
      name: string;
      multiRolesAllowed: boolean;
      hasMachines: boolean;
    },
  ) {}
}

export class SaveApplicationFailureAction implements Action {
  readonly type = ActionTypes.SAVE_APPLICATION_FAILURE;
  constructor(public error: string) {}
}

export class DeleteApplicationAction implements Action {
  readonly type = ActionTypes.DELETE_APPLICATION;
  constructor(
    public clusterId: number,
    public applicationId: number,
  ) {}
}

export class DeleteApplicationSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_APPLICATION_SUCCESS;
  constructor(
    public clusterId: number,
    public applicationId: number,
  ) {}
}

export class DeleteApplicationFailureAction implements Action {
  readonly type = ActionTypes.DELETE_APPLICATION_FAILURE;
  constructor(public error: string) {}
}

// Role

export class LoadApplicationsRolesAction implements Action {
  readonly type = ActionTypes.LOAD_APPLICATIONS_ROLES;
  constructor(public organizationId: number) {}
}

export class LoadApplicationsRolesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_APPLICATIONS_ROLES_SUCCESS;
  constructor(public applicationsRoles: ApplicationRoles[]) {}
}

export class LoadApplicationsRolesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_APPLICATIONS_ROLES_FAILURE;
  constructor(public error: string) {}
}

export class LoadRolesByApplicationIdAction implements Action {
  readonly type = ActionTypes.LOAD_ROLES_BY_APPLICATION_ID;
  constructor(public applicationId: number) {}
}

export class LoadRolesByApplicationIdSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_ROLES_BY_APPLICATION_ID_SUCCESS;
  constructor(public roles: Role[]) {}
}

export class LoadRolesByApplicationIdFailureAction implements Action {
  readonly type = ActionTypes.LOAD_ROLES_BY_APPLICATION_ID_FAILURE;
  constructor(public error: string) {}
}

export class AddRoleAction implements Action {
  readonly type = ActionTypes.ADD_ROLE;
  constructor(
    public applicationId: number,
    public role: Role,
  ) {}
}

export class AddRoleSuccessAction implements Action {
  readonly type = ActionTypes.ADD_ROLE_SUCCESS;
  constructor(public role: Role) {}
}

export class AddRoleFailureAction implements Action {
  readonly type = ActionTypes.ADD_ROLE_FAILURE;
  constructor(public error: string) {}
}

export class SaveRoleAction implements Action {
  readonly type = ActionTypes.SAVE_ROLE;
  constructor(public role: Role) {}
}

export class SaveRoleSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_ROLE_SUCCESS;
  constructor(public role: Role) {}
}

export class SaveRoleFailureAction implements Action {
  readonly type = ActionTypes.SAVE_ROLE_FAILURE;
  constructor(public error: string) {}
}

export class DeleteRoleAction implements Action {
  readonly type = ActionTypes.DELETE_ROLE;
  constructor(public roleId: number) {}
}

export class DeleteRoleSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_ROLE_SUCCESS;
  constructor(public roleId: number) {}
}

export class DeleteRoleFailureAction implements Action {
  readonly type = ActionTypes.DELETE_ROLE_FAILURE;
  constructor(public error: string) {}
}

// Feature

export class LoadFeaturesByApplicationIdAction implements Action {
  readonly type = ActionTypes.LOAD_FEATURES_BY_APPLICATION_ID;
  constructor(public applicationId: number) {}
}

export class LoadFeaturesByApplicationIdSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_FEATURES_BY_APPLICATION_ID_SUCCESS;
  constructor(public features: Feature[]) {}
}

export class LoadFeaturesByApplicationIdFailureAction implements Action {
  readonly type = ActionTypes.LOAD_FEATURES_BY_APPLICATION_ID_FAILURE;
  constructor(public error: string) {}
}

export class AddFeatureAction implements Action {
  readonly type = ActionTypes.ADD_FEATURE;
  constructor(
    public applicationId: number,
    public featureName: string,
  ) {}
}

export class AddFeatureSuccessAction implements Action {
  readonly type = ActionTypes.ADD_FEATURE_SUCCESS;
  constructor(
    public feature: {
      id: number;
      name: string;
    },
  ) {}
}

export class AddFeatureFailureAction implements Action {
  readonly type = ActionTypes.ADD_FEATURE_FAILURE;
  constructor(public error: string) {}
}

export class AddSubFeatureAction implements Action {
  readonly type = ActionTypes.ADD_SUB_FEATURE;
  constructor(
    public applicationId: number,
    public featureId: number,
    public subFeatureName: string,
  ) {}
}

export class AddSubFeatureSuccessAction implements Action {
  readonly type = ActionTypes.ADD_SUB_FEATURE_SUCCESS;
  constructor(
    public subFeature: {
      id: number;
      name: string;
      key: string;
      featureId: number;
    },
  ) {}
}

export class AddSubFeatureFailureAction implements Action {
  readonly type = ActionTypes.ADD_SUB_FEATURE_FAILURE;
  constructor(public error: string) {}
}

export class SaveFeatureAction implements Action {
  readonly type = ActionTypes.SAVE_FEATURE;
  constructor(public feature: Feature) {}
}

export class SaveFeatureSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_FEATURE_SUCCESS;
  constructor(
    public feature: {
      id: number;
      name: string;
      key: string;
    },
  ) {}
}

export class SaveFeatureFailureAction implements Action {
  readonly type = ActionTypes.SAVE_FEATURE_FAILURE;
  constructor(public error: string) {}
}

export class SaveSubFeatureAction implements Action {
  readonly type = ActionTypes.SAVE_SUB_FEATURE;
  constructor(public subFeature: SubFeature) {}
}

export class SaveSubFeatureSuccessAction implements Action {
  readonly type = ActionTypes.SAVE_SUB_FEATURE_SUCCESS;
  constructor(
    public subFeature: {
      id: number;
      name: string;
      featureId: number;
      key: string;
    },
  ) {}
}

export class SaveSubFeatureFailureAction implements Action {
  readonly type = ActionTypes.SAVE_SUB_FEATURE_FAILURE;
  constructor(public error: string) {}
}

export class DeleteFeatureAction implements Action {
  readonly type = ActionTypes.DELETE_FEATURE;
  constructor(public featureId: number) {}
}

export class DeleteFeatureSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_FEATURE_SUCCESS;
  constructor(public featureId: number) {}
}

export class DeleteFeatureFailureAction implements Action {
  readonly type = ActionTypes.DELETE_FEATURE_FAILURE;
  constructor(public error: string) {}
}

export class DeleteSubFeatureAction implements Action {
  readonly type = ActionTypes.DELETE_SUB_FEATURE;
  constructor(
    public featureId: number,
    public subFeatureId: number,
  ) {}
}

export class DeleteSubFeatureSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_SUB_FEATURE_SUCCESS;
  constructor(
    public featureId: number,
    public subFeatureId: number,
  ) {}
}

export class DeleteSubFeatureFailureAction implements Action {
  readonly type = ActionTypes.DELETE_SUB_FEATURE_FAILURE;
  constructor(public error: string) {}
}

export class ResetApplicationStateAction implements Action {
  readonly type = ActionTypes.RESET_APPLICATION_STATE;
}

export class ResetApplicationMessagesAction implements Action {
  readonly type = ActionTypes.RESET_APPLICATION_MESSAGES;
}

export type Actions =
  | AddApplicationAction
  | AddApplicationSuccessAction
  | AddApplicationFailureAction
  | SaveApplicationAction
  | SaveApplicationSuccessAction
  | SaveApplicationFailureAction
  | DeleteApplicationAction
  | DeleteApplicationSuccessAction
  | DeleteApplicationFailureAction
  | LoadClustersAction
  | LoadClustersSuccessAction
  | LoadClustersFailureAction
  | SaveClusterAction
  | SaveClusterSuccessAction
  | SaveClusterFailureAction
  | DeleteClusterAction
  | DeleteClusterSuccessAction
  | DeleteClusterFailureAction
  | AddClusterAction
  | AddClusterSuccessAction
  | AddClusterFailureAction
  | LoadApplicationsRolesAction
  | LoadApplicationsRolesSuccessAction
  | LoadApplicationsRolesFailureAction
  | AddRoleAction
  | AddRoleSuccessAction
  | AddRoleFailureAction
  | SaveRoleAction
  | SaveRoleSuccessAction
  | SaveRoleFailureAction
  | DeleteRoleAction
  | DeleteRoleSuccessAction
  | DeleteRoleFailureAction
  | LoadRolesByApplicationIdAction
  | LoadRolesByApplicationIdSuccessAction
  | LoadRolesByApplicationIdFailureAction
  | LoadFeaturesByApplicationIdAction
  | LoadFeaturesByApplicationIdSuccessAction
  | LoadFeaturesByApplicationIdFailureAction
  | AddFeatureAction
  | AddFeatureSuccessAction
  | AddFeatureFailureAction
  | AddSubFeatureAction
  | AddSubFeatureSuccessAction
  | AddSubFeatureFailureAction
  | SaveFeatureAction
  | SaveFeatureSuccessAction
  | SaveFeatureFailureAction
  | SaveSubFeatureAction
  | SaveSubFeatureSuccessAction
  | SaveSubFeatureFailureAction
  | DeleteFeatureAction
  | DeleteFeatureSuccessAction
  | DeleteFeatureFailureAction
  | DeleteSubFeatureAction
  | DeleteSubFeatureSuccessAction
  | DeleteSubFeatureFailureAction
  | ResetApplicationStateAction
  | ResetApplicationMessagesAction;
