import { inject } from '@angular/core';
import { CanActivateFn, Router } from '@angular/router';
import { Store } from '@ngrx/store';
import { firstValueFrom } from 'rxjs';
import { State } from 'src/app/core/store/state';
import { LoggedUserPermissionsMisc } from '../models/loggedUserPermissionsMisc.model';

export const canSeeApplicationGuard: CanActivateFn = async () => {
  const store$ = inject(Store<State>) as Store<State>;
  const router = inject(Router);

  const loggedUserPermissionsMisc$ = store$.select((state) => {
    return state.Auth.permissions as LoggedUserPermissionsMisc;
  });

  const loggedUserPermissionsMisc = await firstValueFrom(
    loggedUserPermissionsMisc$,
  );

  if (loggedUserPermissionsMisc?.canReadApplications) {
    return true;
  } else {
    router.navigate(['/']);
    return false;
  }
};
