import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription, lastValueFrom } from 'rxjs';
import { Cluster } from 'src/app/core/models/cluster.model';
import { Role } from 'src/app/core/models/role.model';
import { UnitUsers } from 'src/app/core/models/unitUsers.model';
import { User } from 'src/app/core/models/user.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import {
  AddMemberAction,
  SaveMemberAction,
  SaveMemberRolesAction,
} from 'src/app/core/store/organization/actions';
import { State } from 'src/app/core/store/state';
import { MultiSelectComponent } from '../../../../shared/components/multi-select/multi-select.component';
import { DialogEditUserComponent } from '../dialog-edit-user/dialog-edit-user.component';

@Component({
  selector: 'rh-admincenter-unitusers',
  templateUrl: './unit-users.component.html',
  styleUrls: ['./unit-users.component.scss'],
  imports: [TranslateModule, CommonModule, MultiSelectComponent],
})
export class UnitUsersComponent implements OnInit, OnDestroy {
  @Input({ required: true }) organizationId!: number;
  @Input({ required: true }) unitId!: number;
  @Input({ required: true }) clusters!: Cluster[];
  @Input({ required: true }) rolesByApplication?: Map<number, Role[]>;
  @Input({ required: true }) canAddUsers!: boolean;

  unitUsers$!: Observable<UnitUsers>;
  unitUsers?: UnitUsers;

  isToggled = false;
  error$!: Observable<string>;

  sortNameOrder = 'asc';
  sortDepartmentOrder = 'asc';

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<State>,
    public dialog: DialogService,
  ) {}

  ngOnInit(): void {
    this.unitUsers$ = this.store$.select(
      (state) =>
        state.Organization.unitsUsers?.find(
          (unitUsers) => unitUsers.unitId === this.unitId,
        ) as UnitUsers,
    );

    this.subscriptions.add(
      this.unitUsers$.subscribe((unitUsers) => {
        this.unitUsers = unitUsers;
        this.sortUsers();
      }),
    );
  }

  toggle() {
    this.isToggled = !this.isToggled;
  }

  sortUsers() {
    if (!this.unitUsers) return;

    this.unitUsers.users.sort((a, b) => {
      const nameA = (a.givenName + ' ' + a.familyName).toLowerCase().trim();
      const nameB = (b.givenName + ' ' + b.familyName).toLowerCase().trim();

      let sortName;
      if (this.sortNameOrder === 'asc') {
        sortName = nameA.localeCompare(nameB);
      } else {
        sortName = nameB.localeCompare(nameA);
      }

      if (sortName !== 0) return sortName;

      const departmentA = a.department?.name.toLowerCase() ?? '';
      const departmentB = b.department?.name.toLowerCase() ?? '';

      if (this.sortDepartmentOrder === 'asc') {
        return departmentA.localeCompare(departmentB);
      } else {
        return departmentB.localeCompare(departmentA);
      }
    });
  }

  sortName() {
    if (!this.unitUsers) return;

    if (this.sortNameOrder === 'asc') {
      this.sortNameOrder = 'desc';
      this.unitUsers.users.sort((a, b) => {
        const nameA = (a.givenName + ' ' + a.familyName).toLowerCase().trim();
        const nameB = (b.givenName + ' ' + b.familyName).toLowerCase().trim();

        const sortName = nameB.localeCompare(nameA);

        if (sortName !== 0) return sortName;

        const departmentA = a.department?.name.toLowerCase() ?? '';
        const departmentB = b.department?.name.toLowerCase() ?? '';

        return departmentA.localeCompare(departmentB);
      });
    } else {
      this.sortNameOrder = 'asc';
      this.unitUsers.users.sort((a, b) => {
        const nameA = (a.givenName + ' ' + a.familyName).toLowerCase().trim();
        const nameB = (b.givenName + ' ' + b.familyName).toLowerCase().trim();

        const sortName = nameA.localeCompare(nameB);

        if (sortName !== 0) return sortName;

        const departmentA = a.department?.name.toLowerCase() ?? '';
        const departmentB = b.department?.name.toLowerCase() ?? '';

        return departmentA.localeCompare(departmentB);
      });
    }
  }

  sortDepartment() {
    if (!this.unitUsers) return;

    if (this.sortDepartmentOrder === 'asc') {
      this.sortDepartmentOrder = 'desc';
      this.unitUsers.users.sort((a, b) => {
        const departmentA = a.department?.name.toLowerCase() ?? '';
        const departmentB = b.department?.name.toLowerCase() ?? '';

        const sortDep = departmentB.localeCompare(departmentA);

        if (sortDep !== 0) return sortDep;

        const nameA = (a.givenName + ' ' + a.familyName).toLowerCase().trim();
        const nameB = (b.givenName + ' ' + b.familyName).toLowerCase().trim();

        return nameA.localeCompare(nameB);
      });
    } else {
      this.sortDepartmentOrder = 'asc';
      this.unitUsers.users.sort((a, b) => {
        const departmentA = a.department?.name.toLowerCase() ?? '';
        const departmentB = b.department?.name.toLowerCase() ?? '';

        const sortDep = departmentA.localeCompare(departmentB);

        if (sortDep !== 0) return sortDep;

        const nameA = (a.givenName + ' ' + a.familyName).toLowerCase().trim();
        const nameB = (b.givenName + ' ' + b.familyName).toLowerCase().trim();

        return nameA.localeCompare(nameB);
      });
    }
  }

  async addMember(): Promise<void> {
    // TODO: Enable as soon permissions working again
    // if (!this.loggedUserPermissionsMisc?.permissions.can_add_company) return;

    const dialogRef = this.dialog.open(DialogEditUserComponent, {
      data: {
        title: 'userPopup.title',
        user: undefined,
        organizationId: this.organizationId,
        unitId: this.unitId,
        isNew: true,
        permissions: {
          canUpdateUser: true,
          canUpdateMembersDepartment: true,
        },
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as User;
    if (!result) {
      return;
    } else {
      if (!this.unitUsers) {
        return;
      }
      this.store$.dispatch(
        new AddMemberAction(this.organizationId, this.unitUsers.unitId, result),
      );
    }
  }

  getCiamAdmins(): string {
    if (!this.unitUsers) {
      return '';
    }
    return this.unitUsers.users
      .filter((member) =>
        member.roles?.some((role) => role.key === 'admincenter_customeradmin'),
      )
      .map((member) => member.email)
      .join(', ');
  }

  getRolesByApplicationId(applicationId: number) {
    return this.rolesByApplication
      ?.get(applicationId)
      ?.filter((role) => {
        const roleKey = role.key.toLowerCase();

        return (
          roleKey.includes('_viewer') ||
          roleKey.includes('elearning_editor') ||
          roleKey.includes('elearning_admin') ||
          roleKey.includes('elearning_approver') ||
          roleKey.includes('admincenter_')
        );
      })
      .sort((a, b) => {
        if (a.key.includes('_viewer')) {
          return -1;
        } else if (b.key.includes('_viewer')) {
          return 1;
        } else {
          return (
            a.key.localeCompare(b.key) ||
            a.name.localeCompare(b.name) ||
            a.id - b.id
          );
        }
      });
  }

  userHasRoleForApplication(user: User, applicationId: number, roleId: number) {
    return (
      user.roles?.some(
        (role) => role.applicationId === applicationId && role.id === roleId,
      ) || false
    );
  }

  getSelectedRolesByApplicationId(user: User, applicationId: number) {
    return user.roles?.filter((role) => role.applicationId === applicationId);
  }

  async editMember(user: User): Promise<void> {
    const dialogRef = this.dialog.open(DialogEditUserComponent, {
      data: {
        title: 'userPopup.title',
        user: {
          ...user,
          isBlocked: user.isBlocked || false,
        } as User,
        organizationId: this.organizationId,
        unitId: this.unitId,
        isNew: false,
        permissions: {
          canUpdateUser: true,
          canUpdateMembersDepartment: true,
          canDeleteMember: true,
        },
      },
    });

    const result = (await lastValueFrom(dialogRef.afterClosed())) as User;
    if (!result) {
      return;
    } else {
      this.store$.dispatch(new SaveMemberAction(this.unitId, result as User));
    }
  }

  getClustersForOverview() {
    const clustersToFind = ['monitoring', 'academy'];
    const applicationsToFind = [
      'argus',
      'metrology',
      'videoplattform',
      'elearning',
    ];

    const clusters = this.clusters
      .filter((cluster) => clustersToFind.includes(cluster.name.toLowerCase()))
      .sort((a, b) => a.name.toLowerCase().localeCompare(b.name.toLowerCase()));

    clusters.forEach((cluster) => {
      cluster.applications.filter((application) => {
        applicationsToFind.includes(application.name.toLowerCase());
      });
    });

    return clusters;
  }

  findRoleForApplication(user: User, applicationId: number, roleName: string) {
    return user.roles?.find(
      (role) =>
        role.applicationId === applicationId &&
        role.key.toLowerCase() === roleName,
    );
  }

  getCiamId() {
    let applicationId = 0;
    this.clusters.forEach((cluster) => {
      cluster.applications.forEach((application) => {
        if (application.name.toLowerCase().indexOf('admin') > -1) {
          applicationId = application.id;
        }
      });
    });
    return applicationId;
  }

  onRoleChangeMulti(userId: number, applicationId: number, roles: Role[]) {
    this.store$.dispatch(
      new SaveMemberRolesAction(
        this.unitId,
        userId,
        applicationId,
        roles.map((role) => role.id),
      ),
    );
  }

  onRoleChange(
    user: User,
    applicationId: number,
    roleId: number,
    multiRolesAllowed: boolean,
  ) {
    const selectedUserRoles =
      this.getSelectedRolesByApplicationId(user, applicationId) || [];

    let roles: number[] = selectedUserRoles.map((r) => r.id);

    if (multiRolesAllowed) {
      if (selectedUserRoles.find((r) => r.id === roleId)) {
        roles = selectedUserRoles
          .filter((r) => r.id !== roleId)
          .map((r) => r.id);
      } else {
        roles.push(roleId);
      }
    } else {
      if (selectedUserRoles.find((r) => r.id === roleId)) {
        roles = [];
      } else {
        roles = [roleId];
      }
    }

    this.store$.dispatch(
      new SaveMemberRolesAction(this.unitId, user.id, applicationId, roles),
    );
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
