import { Action } from '@ngrx/store';
import { Machine } from '../../models/machine.model';

export enum ActionTypes {
  LOAD_MACHINES = '[Machine] Load Machines',
  LOAD_MACHINES_SUCCESS = '[Machine] Load Machines Success',
  LOAD_MACHINES_FAILURE = '[Machine] Load Machines Failure',
  ADD_MACHINE = '[Machine] Add Machine',
  ADD_MACHINE_SUCCESS = '[Machine] Add Machine Success',
  ADD_MACHINE_FAILURE = '[Machine] Add Machine Failure',
  UPDATE_MACHINE = '[Machine] Update Machine',
  UPDATE_MACHINE_SUCCESS = '[Machine] Update Machine Success',
  UPDATE_MACHINE_FAILURE = '[Machine] Update Machine Failure',
  DELETE_MACHINE = '[Machine] Delete Machine',
  DELETE_MACHINE_SUCCESS = '[Machine] Delete Machine Success',
  DELETE_MACHINE_FAILURE = '[Machine] Delete Machine Failure',
  GET_MACHINE_TYPES = '[Machine] Get Machine Types',
  GET_MACHINE_TYPES_SUCCESS = '[Machine] Get Machine Types Success',
  GET_MACHINE_TYPES_FAILURE = '[Machine] Get Machine Types Failure',
  GET_PROXIES = '[Machine] Get Proxies',
  GET_PROXIES_SUCCESS = '[Machine] Get Proxies Success',
  GET_PROXIES_FAILURE = '[Machine] Get Proxies Failure',
  RESET_MACHINE_STORE = '[Machine] Reset Machine Store',
}

export class LoadMachinesAction implements Action {
  readonly type = ActionTypes.LOAD_MACHINES;
}

export class LoadMachinesSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_MACHINES_SUCCESS;
  constructor(public machines: Machine[]) {}
}

export class LoadMachinesFailureAction implements Action {
  readonly type = ActionTypes.LOAD_MACHINES_FAILURE;
  constructor(public error: string) {}
}

export class AddMachineAction implements Action {
  readonly type = ActionTypes.ADD_MACHINE;
  constructor(public machine: Machine) {}
}

export class AddMachineSuccessAction implements Action {
  readonly type = ActionTypes.ADD_MACHINE_SUCCESS;
  constructor(public machine: Machine) {}
}

export class AddMachineFailureAction implements Action {
  readonly type = ActionTypes.ADD_MACHINE_FAILURE;
  constructor(public error: string) {}
}

export class UpdateMachineAction implements Action {
  readonly type = ActionTypes.UPDATE_MACHINE;
  constructor(public machine: Machine) {}
}

export class UpdateMachineSuccessAction implements Action {
  readonly type = ActionTypes.UPDATE_MACHINE_SUCCESS;
  constructor(public machine: Machine) {}
}

export class UpdateMachineFailureAction implements Action {
  readonly type = ActionTypes.UPDATE_MACHINE_FAILURE;
  constructor(public error: string) {}
}

export class DeleteMachineAction implements Action {
  readonly type = ActionTypes.DELETE_MACHINE;
  constructor(public machineId: number) {}
}

export class DeleteMachineSuccessAction implements Action {
  readonly type = ActionTypes.DELETE_MACHINE_SUCCESS;
  constructor(public machineId: number) {}
}

export class DeleteMachineFailureAction implements Action {
  readonly type = ActionTypes.DELETE_MACHINE_FAILURE;
  constructor(public error: string) {}
}

export class GetMachineTypesAction implements Action {
  readonly type = ActionTypes.GET_MACHINE_TYPES;
}

export class GetMachineTypesSuccessAction implements Action {
  readonly type = ActionTypes.GET_MACHINE_TYPES_SUCCESS;
  constructor(public machineTypes: string[]) {}
}

export class GetMachineTypesFailureAction implements Action {
  readonly type = ActionTypes.GET_MACHINE_TYPES_FAILURE;
  constructor(public error: string) {}
}

export class GetProxiesAction implements Action {
  readonly type = ActionTypes.GET_PROXIES;
}

export class GetProxiesSuccessAction implements Action {
  readonly type = ActionTypes.GET_PROXIES_SUCCESS;
  constructor(public proxies: {[key: number]: string[]}) {}
}

export class GetProxiesFailureAction implements Action {
  readonly type = ActionTypes.GET_PROXIES_FAILURE;
  constructor(public error: string) {}
}

export class ResetUserStoreAction implements Action {
  readonly type = ActionTypes.RESET_MACHINE_STORE;
}

export type Actions =
  | LoadMachinesAction
  | LoadMachinesSuccessAction
  | LoadMachinesFailureAction
  | AddMachineAction
  | AddMachineSuccessAction
  | AddMachineFailureAction
  | UpdateMachineAction
  | UpdateMachineSuccessAction
  | UpdateMachineFailureAction
  | DeleteMachineAction
  | DeleteMachineSuccessAction
  | DeleteMachineFailureAction
  | GetMachineTypesAction
  | GetMachineTypesSuccessAction
  | GetMachineTypesFailureAction
  | GetProxiesAction
  | GetProxiesSuccessAction
  | GetProxiesFailureAction
  | ResetUserStoreAction;
