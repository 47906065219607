import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  HostListener,
  Input,
  OnInit,
  Output,
} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import { Role } from 'src/app/core/models/role.model';

@Component({
  selector: 'rh-admincenter-multi-select',
  templateUrl: './multi-select.component.html',
  styleUrls: ['./multi-select.component.scss'],
  imports: [TranslateModule, CommonModule],
})
export class MultiSelectComponent implements OnInit {
  @Input() roles?: Role[];
  @Input() selectedRoles?: Role[];
  @Input() allowMultiSelect = false;
  @Output() selectionChange = new EventEmitter<Role[]>();
  isToggled = false;
  changed = false;
  selectedRoleName = '';

  private wasInside = false;

  @HostListener('click')
  clickInside() {
    this.wasInside = true;
  }

  @HostListener('document:click')
  clickout() {
    if (!this.wasInside) {
      this.isToggled = false;
    }
    this.wasInside = false;
  }

  ngOnInit(): void {
    this.selectedRoleName = this.getSelectedRolesNames();
  }

  toggleDropdown() {
    this.isToggled = !this.isToggled;
  }

  onRoleChange(role: Role) {
    if (this.allowMultiSelect) {
      this.selectedRoles = this.selectedRoles?.find((r) => r.id === role.id)
        ? this.selectedRoles?.filter((r) => r.id !== role.id)
        : [...(this.selectedRoles || []), role];
    } else {
      this.selectedRoles = this.selectedRoles?.find((r) => r.id === role.id)
        ? []
        : [role];
    }

    this.isToggled = false;
    this.selectionChange.emit(this.selectedRoles);
  }

  findRole(role: Role) {
    return this.selectedRoles?.find((r) => r.id === role.id);
  }

  getSelectedRolesNames() {
    const selectedRoleNames = this.roles
      ?.filter((role) =>
        this.selectedRoles?.some((selectedRole) => selectedRole.id === role.id),
      )
      .map((role) => role.name) || ['rolesSelect.selectRoles'];

    if (selectedRoleNames.length === 0) {
      return 'rolesSelect.selectRoles';
    }

    return selectedRoleNames.join(', ');
  }
}
