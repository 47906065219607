<div
  class="relative z-50"
  aria-labelledby="modal-title"
  role="dialog"
  aria-modal="true"
>
  <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity"></div>

  <div class="fixed inset-0 z-10 overflow-y-auto">
    <div
      class="flex min-h-full items-center justify-center p-4 text-center sm:p-0"
    >
      <div
        class="relative w-full transform overflow-hidden rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:max-w-3xl"
      >
        <!--Box side-->
        <div
          class="absolute left-0 top-0 z-40 h-full w-[30px] bg-brand-red"
        ></div>

        <form (submit)="onConfirm()" class="relative">
          <button (click)="onClose()" class="absolute right-[18px] top-[18px]">
            <svg
              id="icon-close"
              class="h-[20px] w-[20px] fill-brand-gray-dark"
              viewBox="0 0 33 33"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                d="M17.8867 16.3786L32.4064 1.84911C32.7422 1.513 32.7422 0.965633 32.4064 0.629523C32.0705 0.293414 31.5235 0.293414 31.1876 0.629523L16.6679 15.159L2.14819 0.629523C1.81231 0.293414 1.26528 0.293414 0.929402 0.629523C0.59352 0.965633 0.59352 1.513 0.929402 1.84911L15.4491 16.3786L0.929402 30.9082C0.59352 31.2443 0.59352 31.7916 0.929402 32.1278C1.10214 32.3006 1.32288 32.3774 1.5436 32.3774C1.76432 32.3774 1.98506 32.291 2.1578 32.1278L16.6775 17.5982L31.1972 32.1278C31.3699 32.3006 31.5907 32.3774 31.8114 32.3774C32.0321 32.3774 32.2528 32.291 32.4256 32.1278C32.7615 31.7916 32.7615 31.2443 32.4256 30.9082L17.9059 16.3786H17.8867Z"
              />
            </svg>
          </button>
          <div
            class="px-4 pb-4 pt-5 sm:pb-4 sm:pl-[90px] sm:pr-[60px] sm:pt-10"
          >
            <legend
              class="mb-[55px] flex items-center justify-center text-center font-brand-light text-2xl leading-6 text-brand-red"
            >
              <svg
                class="mr-2 h-8 w-8 fill-brand-red"
                viewBox="0 0 32 36"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M31.3057 6.86823H23.5685V3.74264C23.5685 1.67914 21.9219 0 19.8983 0H12.1711C10.1475 0 8.50091 1.67914 8.50091 3.74264V6.86823H0.694312C0.307454 6.86823 0 7.18178 0 7.57628C0 7.97077 0.307454 8.28435 0.694312 8.28435H3.56104C3.45193 8.40573 3.38244 8.56757 3.38244 8.74964V31.2459C3.38244 33.8657 5.47552 36 8.04465 36H23.8959C26.465 36 28.558 33.8657 28.558 31.2459V8.74964C28.558 8.56757 28.4886 8.41585 28.3795 8.28435H31.3057C31.6925 8.28435 32 7.97077 32 7.57628C32 7.18178 31.6925 6.86823 31.3057 6.86823ZM9.88965 3.74264C9.88965 2.45801 10.9113 1.41612 12.1711 1.41612H19.8983C21.1581 1.41612 22.1799 2.45801 22.1799 3.74264V6.86823H9.88965V3.74264ZM27.1692 8.74964V31.2459C27.1692 33.0868 25.7012 34.5839 23.8959 34.5839H8.04465C6.23931 34.5839 4.77119 33.0868 4.77119 31.2459V8.74964C4.77119 8.56757 4.7017 8.41585 4.59258 8.28435H27.3478C27.2387 8.40573 27.1692 8.56757 27.1692 8.74964Z"
                />
                <path
                  d="M12.5976 27.6954C12.9845 27.6954 13.292 27.3818 13.292 26.9873V15.6179C13.292 15.2234 12.9845 14.9098 12.5976 14.9098C12.2108 14.9098 11.9033 15.2234 11.9033 15.6179V26.9873C11.9033 27.3818 12.2108 27.6954 12.5976 27.6954Z"
                />
                <path
                  d="M19.333 27.6954C19.7198 27.6954 20.0273 27.3818 20.0273 26.9873V15.6179C20.0273 15.2234 19.7198 14.9098 19.333 14.9098C18.9461 14.9098 18.6385 15.2234 18.6385 15.6179V26.9873C18.6385 27.3818 18.9461 27.6954 19.333 27.6954Z"
                />
              </svg>

              {{ data.title | translate }}
            </legend>
          </div>
          <div class="mt-5 flex gap-4 py-6 sm:mt-0 sm:justify-center">
            <button
              (click)="onClose()"
              type="button"
              class="relative flex h-9 items-center rounded-md border border-brand-gray-light bg-white px-6 py-2 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
            >
              <svg
                class="h-4 w-auto fill-brand-red"
                viewBox="0 0 32 32"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M30.3916 32C29.9849 32 29.5675 31.8394 29.2571 31.529L0.473658 2.74319C-0.157886 2.11159 -0.157886 1.09459 0.473658 0.473697C1.1052 -0.157899 2.12209 -0.157899 2.74293 0.473697L31.5263 29.2595C32.1579 29.8911 32.1579 30.9081 31.5263 31.529C31.2159 31.8394 30.7984 32 30.3916 32Z"
                />
                <path
                  d="M1.60823 32C1.20147 32 0.784077 31.8394 0.473658 31.529C-0.157886 30.8974 -0.157886 29.8804 0.473658 29.2595L29.2571 0.473697C29.8886 -0.157899 30.9055 -0.157899 31.5263 0.473697C32.1579 1.10529 32.1579 2.1223 31.5263 2.74319L2.74293 31.529C2.43251 31.8394 2.01498 32 1.60823 32Z"
                />
              </svg>
            </button>
            <button
              type="submit"
              class="relative flex h-9 items-center rounded-md border border-brand-gray-light bg-white px-6 py-2 text-sm uppercase text-brand-blue-800 hover:bg-brand-blue-light"
            >
              <svg
                class="h-4 w-auto fill-brand-green"
                viewBox="0 0 32 26"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  d="M13.0939 26C12.7581 26 12.4319 25.8797 12.1729 25.6492L0.517649 15.5052C-0.0962941 14.9739 -0.173098 14.0217 0.335324 13.3902C0.843745 12.7487 1.75505 12.6685 2.3594 13.1998L12.8731 22.3614L29.4304 0.569888C29.9293 -0.081652 30.8311 -0.191933 31.4546 0.329299C32.0782 0.850531 32.1837 1.79277 31.6848 2.44431L14.2066 25.4387C13.9667 25.7594 13.6119 25.9599 13.2282 26C13.1802 26 13.1322 26 13.0843 26H13.0939Z"
                />
              </svg>
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</div>
