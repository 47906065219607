<!-- Mobile & tablet portrait -->
@if ((isMobile || isTabletPortrait) && sidebarOpen) {
  <nav
    class="fixed z-50 flex h-full w-full flex-col gap-10 bg-brand-blue-800 p-small"
  >
    <div class="flex flex-row items-center justify-between bg-brand-blue-800">
      <a
        [href]="'https://www.myreishauer.com/#/home'"
        class="flex h-12 w-fit items-center"
      >
        <img
          class="aspect-auto h-10"
          src="/assets/icons/Reishauer_Icons-LogoWhite.svg"
          alt="Logo"
        />
      </a>
      <button (click)="toggleSidebar()" type="button" class="p-2">
        <img
          src="/assets/icons/Reishauer_Icons-Close.svg"
          alt="Close"
          class="aspect-square h-8"
        />
      </button>
    </div>
    <div class="flex h-full flex-col gap-4 overflow-y-auto text-2xl uppercase">
      <a
        [routerLink]="['/organizations']"
        (click)="toggleSidebar()"
        class="group flex items-center gap-3 uppercase text-white"
      >
        <img
          src="/assets/icons/Reishauer_Icons-Company.svg"
          alt="Organizations"
          class="aspect-auto h-10"
        />
        <span>
          {{ "sidebar.organizations" | translate }}
        </span>
      </a>
    </div>
  </nav>
}

<!-- desktop & tablet landscape -->
@if (isWeb || isTabletLandscape) {
  <nav class="z-50 block h-full w-[90px] bg-brand-blue-800">
    <div class="flex h-24 items-center justify-center px-4">
      <button (click)="onLogoClick()">
        <img
          class="w-full"
          src="/assets/icons/Reishauer_Icons-LogoWhite.svg"
          alt="Reishauer Admin Center"
        />
      </button>
    </div>
    <ul class="flex flex-col items-center">
      <li class="flex cursor-pointer self-stretch">
        <a
          [routerLink]="['/organizations']"
          routerLinkActive="text-white bg-brand-blue-550"
          class="group flex w-full flex-col items-center justify-center gap-2 py-4 text-[10px] uppercase text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
        >
          <img
            src="/assets/icons/Reishauer_Icons-Company.svg"
            alt="Organizations"
            class="h-8 w-8"
          />
          <span>
            {{ "sidebar.organizations" | translate }}
          </span>
        </a>
      </li>
      @if ((permissions$ | async)?.canReadApplications) {
        <li class="flex cursor-pointer self-stretch">
          <a
            [routerLink]="['/applications']"
            routerLinkActive="text-white bg-brand-blue-550"
            class="group flex w-full flex-col items-center justify-center gap-2 py-4 text-[10px] uppercase text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
          >
            <img
              src="/assets/icons/Reishauer_Icons-Applications.svg"
              alt="Applications"
              class="h-8 w-8"
            />
            <span>
              {{ "sidebar.applications" | translate }}
            </span>
          </a>
        </li>
      }
      <li class="flex cursor-pointer self-stretch">
        <a
          [routerLink]="['/users']"
          routerLinkActive="text-white bg-brand-blue-550"
          class="group flex w-full flex-col items-center justify-center gap-2 py-4 text-[10px] uppercase text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
        >
          <img
            src="/assets/icons/Reishauer_Icons-Applications.svg"
            alt="Users"
            class="h-8 w-8"
          />
          <span>
            {{ "sidebar.users" | translate }}
          </span>
        </a>
      </li>
      <li class="flex cursor-pointer self-stretch">
        <a
          [routerLink]="['/machines']"
          routerLinkActive="text-white bg-brand-blue-550"
          class="group flex w-full flex-col items-center justify-center gap-2 py-4 text-[10px] uppercase text-brand-blue-360 hover:bg-brand-blue-550 hover:text-white"
        >
          <img
            src="/assets/icons/Reishauer_Icons-Company.svg"
            alt="Organizations"
            class="h-8 w-8"
          />
          <span>
            {{ "sidebar.machines" | translate }}
          </span>
        </a>
      </li>
    </ul>
  </nav>
}
