import { CommonModule } from '@angular/common';
import { Component, HostListener, Inject, OnInit } from '@angular/core';
import {
  AbstractControl,
  FormBuilder,
  FormControl,
  FormGroup,
  FormsModule,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { UnitLicences } from 'src/app/core/models/unitLicences.model';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-admincenter-dialog-edit-unit',
  templateUrl: './dialog-edit-unit.component.html',
  styleUrls: ['./dialog-edit-unit.component.scss'],
  imports: [TranslateModule, CommonModule, FormsModule, ReactiveFormsModule],
})
export class DialogEditUnitComponent implements OnInit {
  title!: string;
  unit?: UnitLicences;
  form!: FormGroup;

  @HostListener('document:keydown.enter', ['$event'])
  onEnter(event: KeyboardEvent): void {
    event.preventDefault();
    this.onSubmit();
  }

  constructor(
    private fb: FormBuilder,
    public dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      title: string;
      unit: UnitLicences;
    },
  ) {
    if (data) {
      this.unit = Object.assign({}, data.unit);
      this.title = data.title;
    }
  }

  get name(): AbstractControl | null {
    return this.form.get('name');
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: new FormControl(this.unit?.name, Validators.required),
    });
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid) {
      const unit = {
        ...this.unit,
        name: this.name?.value,
      } as UnitLicences;
      this.dialogRef.close(unit);
    }
  }
}
