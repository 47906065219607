import { CommonModule } from '@angular/common';
import { Component, Inject, OnInit } from '@angular/core';
import {
  FormBuilder,
  FormGroup,
  ReactiveFormsModule,
  Validators,
} from '@angular/forms';
import { TranslateModule } from '@ngx-translate/core';
import { Cluster } from 'src/app/core/models/cluster.model';
import { DialogRef } from 'src/app/core/services/dialog/dialog-ref';
import { DIALOG_DATA } from 'src/app/core/services/dialog/dialog-tokens';

@Component({
  selector: 'rh-admincenter-dialog-edit-application',
  templateUrl: './dialog-edit-application.component.html',
  styleUrls: ['./dialog-edit-application.component.scss'],
  imports: [TranslateModule, CommonModule, ReactiveFormsModule],
})
export class DialogEditApplicationComponent implements OnInit {
  form!: FormGroup;
  applicationName?: string;
  selectedClusterId?: number;
  clusters?: Cluster[];
  title!: string;

  constructor(
    private fb: FormBuilder,
    private dialogRef: DialogRef,
    @Inject(DIALOG_DATA)
    public data: {
      applicationName: string;
      clusters: Cluster[];
      title: string;
      selectedClusterId?: number;
    },
  ) {
    if (data) {
      this.applicationName = data.applicationName;
      this.clusters = data.clusters;
      this.title = data.title;
      this.selectedClusterId = data.selectedClusterId;
    }
  }

  ngOnInit(): void {
    this.form = this.fb.group({
      name: [this.applicationName, Validators.required],
      clusterId: [this.selectedClusterId, [Validators.required]],
    });
  }

  get name() {
    return this.form.get('name');
  }

  get clusterId() {
    return this.form.get('clusterId');
  }

  onClose(): void {
    this.dialogRef.close();
  }

  onSubmit(): void {
    if (this.form.valid && this.clusterId?.value !== 0) {
      this.dialogRef.close({
        clusterId: this.clusterId?.value,
        name: this.name?.value,
      });
    }
  }
}
