import { Action } from '@ngrx/store';
import { Auth0User } from '../../models/auth0User.model';
import { LoggedUserPermissionsMisc } from '../../models/loggedUserPermissionsMisc.model';

export enum ActionTypes {
  SET_USER = '[Auth] Set User',
  LOAD_USER = '[Auth] Load User',
  LOGOUT_USER = '[Auth] Logout User',
  LOAD_USER_SUCCESS = '[Auth] Load User Success',
  LOAD_USER_FAILURE = '[Auth] Load User Failure',
  CHANGE_LANGUAGE = '[Auth] Change Language',
  CHANGE_LANGUAGE_SUCCESS = '[Auth] Change Language Success',
  CHANGE_LANGUAGE_FAILURE = '[Auth] Change Language Failure',
  LOAD_LOGGED_USER_PERMISSIONS_MISC = '[Auth] Load Logged User Permissions Misc',
  LOAD_LOGGED_USER_PERMISSIONS_MISC_SUCCESS = '[Auth] Load Logged User Permissions Misc Success',
  LOAD_LOGGED_USER_PERMISSIONS_MISC_FAILURE = '[Auth] Load Logged User Permissions Misc Failure',
}

export class LoadUserAction implements Action {
  readonly type = ActionTypes.LOAD_USER;
}

export class LogOutUserAction implements Action {
  readonly type = ActionTypes.LOGOUT_USER;
}

export class LoadUserSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_USER_SUCCESS;
  constructor(public user: Auth0User) {}
}

export class LoadUserFailureAction implements Action {
  readonly type = ActionTypes.LOAD_USER_FAILURE;
  constructor(public error: string) {}
}

export class ChangeLanguageAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE;
  constructor(public languageKey: string) {}
}

export class ChangeLanguageSuccessAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE_SUCCESS;
}

export class ChangeLanguageFailureAction implements Action {
  readonly type = ActionTypes.CHANGE_LANGUAGE_FAILURE;
  constructor(public error: string) {}
}

export class LoadLoggedUserPermissionsMiscAction implements Action {
  readonly type = ActionTypes.LOAD_LOGGED_USER_PERMISSIONS_MISC;
}

export class LoadLoggedUserPermissionsMiscSuccessAction implements Action {
  readonly type = ActionTypes.LOAD_LOGGED_USER_PERMISSIONS_MISC_SUCCESS;
  constructor(public loggedUserPermissionsMisc: LoggedUserPermissionsMisc) {}
}

export class LoadLoggedUserPermissionsMiscFailureAction implements Action {
  readonly type = ActionTypes.LOAD_LOGGED_USER_PERMISSIONS_MISC_FAILURE;
  constructor(public error: string) {}
}

export type Actions =
  | LoadUserAction
  | LogOutUserAction
  | LoadUserSuccessAction
  | LoadUserFailureAction
  | ChangeLanguageAction
  | ChangeLanguageSuccessAction
  | ChangeLanguageFailureAction
  | LoadLoggedUserPermissionsMiscAction
  | LoadLoggedUserPermissionsMiscSuccessAction
  | LoadLoggedUserPermissionsMiscFailureAction;
