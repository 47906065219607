import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { inject, Injectable } from '@angular/core';
import { catchError, map, Observable, throwError } from 'rxjs';
import { environment } from 'src/environments/environment';
import { Machine } from '../../models/machine.model';

@Injectable({
  providedIn: 'root',
})
export class MachineService {
  private http = inject(HttpClient);

  GetMachines(): Observable<Machine[]> {
    return this.http
      .get<Machine[]>(`${environment.backendApiUrl}/api/machines`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  UpdateMachine(machine: Machine): Observable<Machine> {
    return this.http
      .put<Machine>(
        `${environment.backendApiUrl}/api/machines/${machine.id}`,
        machine,
      )
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  AddMachine(machine: Machine): Observable<Machine> {
    return this.http
      .post<Machine>(`${environment.backendApiUrl}/api/machines`, machine)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  DeleteMachine(machineId: number): Observable<Machine> {
    return this.http
      .delete<Machine>(`${environment.backendApiUrl}/api/machines/${machineId}`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetMachineTypes(): Observable<string[]> {
    return this.http
      .get<string[]>(`${environment.backendApiUrl}/api/machines/types`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }

  GetProxies(): Observable<{[key: number]: string[]}> {
    return this.http
      .get<{[key: number]: string[]}>(`${environment.backendApiUrl}/api/machines/proxies`)
      .pipe(
        map((response) => {
          return response;
        }),
        catchError((error: HttpErrorResponse) => {
          return throwError(error);
        }),
      );
  }
}
