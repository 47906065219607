import { ApplicationStoreState } from './application';
import { AuthStoreState } from './auth';
import { MachineStoreState } from './machine';
import { OrganizationStoreState } from './organization';
import { UserStoreState } from './user';

export interface State {
  Auth: AuthStoreState.State;
  Organization: OrganizationStoreState.State;
  Application: ApplicationStoreState.State;
  User: UserStoreState.State;
  Machine: MachineStoreState.State;
}
