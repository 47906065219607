<section class="flex flex-col gap-12 bg-white p-10">
  <header>
    <h1
      class="font-brand-light text-2xl uppercase leading-6 text-brand-blue-800"
    >
      Users
    </h1>
  </header>
  @let machines = machines$ | async;

  <table class="table-fixed border-collapse divide-y">
    <thead>
      <tr>
        <th>
          <div>
            <p>M-Nr.</p>
          </div>
        </th>
        <th>
          <div>
            <p>Client Alias</p>
          </div>
        </th>
        <th>
          <div>
            <p>Type</p>
          </div>
        </th>
        <th>
          <div>
            <p>IP</p>
          </div>
        </th>
        <th>
          <div>
            <p>Proxy-ID</p>
          </div>
        </th>
        <th>
          <div>
            <p>Mobile</p>
          </div>
        </th>
        <th>
          <div>
            <p>Argus licence duration</p>
          </div>
        </th>
        <th>
          <div>
            <p>Argus invoice</p>
          </div>
        </th>
        <th>
          <div>
            <p>MaaS licence duration</p>
          </div>
        </th>
        <th>
          <div>
            <p>MaaS invoice</p>
          </div>
        </th>
        <th>
          <div>
            <p>Status</p>
          </div>
        </th>
      </tr>
    </thead>
    <tbody class="divide-y">
      @for (machine of machines; track $index) {
        @let machineStatus =
          getMachineStatus(
            machine.argusLicenseValidTo,
            machine.maasLicenseValidTo
          );
        <tr>
          <td class="py-1">{{ machine.machineNumber }}</td>
          <td class="py-1">{{ machine.customerName }}</td>
          <td class="py-1">{{ machine.type }}</td>
          <td class="py-1">{{ machine.ipAddress }}</td>
          <td class="py-1">{{ machine.proxyId }}</td>
          <td class="py-1">{{ machine.isMobile }}</td>
          <td class="py-1">{{ machine.argusLicenseValidTo }}</td>
          <td class="py-1">{{ machine.argusInvoice }}</td>
          <td class="py-1">{{ machine.maasLicenseValidTo }}</td>
          <td class="py-1">{{ machine.maasInvoice }}</td>
          <td class="py-1">
            @switch (machineStatus) {
              @case ("DANGER") {
                <span class="status-danger">Danger</span>
              }
              @case ("WARNING") {
                <span class="status-warning">Warning</span>
              }
              @case ("OK") {
                <span class="status-info">OK</span>
              }
              @default {
                <span class="status-info"></span>
              }
            }
          </td>
        </tr>
      }
    </tbody>
  </table>
</section>
