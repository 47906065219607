import { Department } from '../../models/department.model';
import { Organization } from '../../models/organization.model';
import { OrganizationFolded } from '../../models/organizationFolded.model';
import { UnitLicences } from '../../models/unitLicences.model';
import { UnitUsers } from '../../models/unitUsers.model';

export interface State {
  organizations?: OrganizationFolded[];
  organization?: Organization;
  units?: UnitLicences[];
  unitsUsers?: UnitUsers[];
  departments?: Department[];
  isLoading?: boolean;
  error?: string;
  success?: string;
}

export const initialState: State = {};
