@if (!isLoading) {
  <div class="flex flex-col gap-8">
    <h1 class="font-brand-light text-2xl uppercase leading-6 text-brand-blue-800">
      Users
    </h1>

    <div class="flex flex-col gap-4">
      @for (userGroup of groupedUsers | keyvalue; track userGroup.key) {
        <div class="flex flex-col divide-y rounded-lg bg-white shadow-xl">

          <div class="flex min-h-16 items-baseline py-3.5 pl-8 pr-4">
            <div class="flex shrink-0 items-center gap-5 pr-4" [title]="userGroup.key">
              <img src="/assets/icons/Reishauer_Icon_User.svg" width="30px" alt="user" />
              <h2 class="font-brand-regular text-xl text-brand-blue-800">
                {{ userGroup.key }}
              </h2>
            </div>
          </div>

          <div class="bg-gray-50 px-4 py-3">
            <div class="flex gap-8">
              <div class="w-1/3">
                <button (click)="sort('email')" class="flex items-center gap-1 text-sm uppercase text-brand-blue-360 hover:text-brand-blue-600">
                  <p>Email</p>
                  <img src="/assets/icons/chevron-down.svg" width="20px" alt=""
                    class="cursor-pointer" [ngClass]="getSortIconClass('email')" />
                </button>
              </div>
              <div class="w-1/3">
                <button (click)="sort('company')" class="flex items-center gap-1 text-sm uppercase text-brand-blue-360 hover:text-brand-blue-600">
                  <p>Company</p>
                  <img src="/assets/icons/chevron-down.svg" width="20px" alt=""
                    class="cursor-pointer" [ngClass]="getSortIconClass('company')" />
                </button>
              </div>
              <div class="w-1/3">
                <button (click)="sort('unit')" class="flex items-center gap-1 text-sm uppercase text-brand-blue-360 hover:text-brand-blue-600">
                  <p>Unit</p>
                  <img src="/assets/icons/chevron-down.svg" width="20px" alt=""
                    class="cursor-pointer" [ngClass]="getSortIconClass('unit')" />
                </button>
              </div>
            </div>
          </div>

          <div class="divide-y divide-brand-gray-light">
            @for (user of userGroup.value; track user) {
              <div class="flex gap-8 px-4 py-2 text-brand-blue-800 hover:bg-brand-blue-lighter">
                <div class="w-1/3">{{ user.email }}</div>
                <div class="w-1/3">{{ user.companyName }}</div>
                <div class="w-1/3">{{ user.unitName }}</div>
              </div>
            }
          </div>
        </div>
      }
    </div>
  </div>
} @else {
  <div class="loading-spinner-container">
    <rh-admincenter-loading></rh-admincenter-loading>
  </div>
}
