import { NgClass } from '@angular/common';
import {Component, ElementRef, EventEmitter, Input, Output, ViewChild} from '@angular/core';
import { TranslateModule } from '@ngx-translate/core';
import {FormsModule} from "@angular/forms";

@Component({
  selector: 'rh-dropdown',
  templateUrl: './dropdown.component.html',
  styleUrls: ['./dropdown.component.scss'],
  standalone: true,
  imports: [NgClass, TranslateModule, FormsModule],
})
export class DropdownComponent {
  @ViewChild('dropdownContainer', {static: true})
  dropdownContainer!: ElementRef;

  public listVisible = false;

  @Input()
  public model?: string;

  @Input()
  public title?: string;

  private _items?: string[];
  @Input()
  public get items(): string[] | undefined {
    return this._items;
  }
  public set items(v: string[] | undefined) {
    this._items = v;
  }

  @Input()
  public placeholder = '';

  @Input()
  public clearable = false;

  @Input()
  public disabled = false;

  @Input()
  public invalidIfEmpty = true;

  @Input()
  public blueMode = false;

  @Input()
  public withInput = false;

  @Output()
  public modelChange: EventEmitter<string> = new EventEmitter<string>();

  public toggle() {
    this.listVisible = !this.listVisible;
  }

  public select(item: string) {
    this.listVisible = false;

    if (this.model === item) {
      return;
    }

    this.model = item;
    this.modelChange?.emit(item);
  }

  isSelected(item: string): boolean {
    return this.model === item;
  }

  clear() {
    this.model = '';
    this.modelChange?.emit('');
  }

  public onFocusOutEvent(event: FocusEvent) {
    // We need to give the click-event precedence, otherwise it will not be fired
    const nextTarget = event.relatedTarget as Node;
    if (!this.dropdownContainer.nativeElement.contains(nextTarget)) {
      setTimeout(() => {
        this.listVisible = false;
      }, 200);
    }
  }

    protected newEntry = '';

    protected addEntry(): void {
      if (!this._items) {
        this._items = [];
      }
      if (this.newEntry && !this._items.includes(this.newEntry)) {
        this._items.push(this.newEntry);
        this.select(this.newEntry);
      }
      this.newEntry = '';
    }
}
