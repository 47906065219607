import {
  animate,
  state,
  style,
  transition,
  trigger,
} from '@angular/animations';
import { BreakpointObserver, Breakpoints } from '@angular/cdk/layout';
import { CommonModule } from '@angular/common';
import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import {
  NavigationEnd,
  Router,
  RouterLink,
  RouterLinkActive,
} from '@angular/router';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { filter, Observable, Subscription } from 'rxjs';
import { Application } from 'src/app/core/models/application.model';
import { Cluster } from 'src/app/core/models/cluster.model';
import { State } from 'src/app/core/store/state';

@Component({
  selector: 'rh-admincenter-vertical-menu',
  templateUrl: './vertical-menu.component.html',
  styleUrls: ['./vertical-menu.component.scss'],
  animations: [
    trigger('openClose', [
      state('true', style({ width: '300px' })),
      state('false', style({ width: '20px' })),
      transition('0 => 1', [animate('500ms ease-in')]),
      transition('1 => 0', [animate('500ms ease-in')]),
    ]),
  ],
  imports: [CommonModule, TranslateModule, RouterLink, RouterLinkActive],
})
export class VerticalMenuComponent implements OnInit, OnDestroy {
  private subscriptions: Subscription = new Subscription();

  @Output() toggleVerticalMenuEvent = new EventEmitter<void>();
  @Input() verticalMenuOpen!: boolean;
  @Input() enableVerticalMenu!: boolean;

  isMobilePortrait = false;
  isMobileLandscape = false;
  isMobile = false;
  isTabletPortrait = false;
  isTabletLandscape = false;
  isWeb = true;

  organizationsPage = true;
  applicationsPage = false;
  applicationsDetailPage = false;

  animationDone = false;

  clusters$!: Observable<Cluster[]>;
  applications: Application[] = [];

  constructor(
    private responsive: BreakpointObserver,
    private router: Router,
    private store$: Store<State>,
  ) {}

  ngOnInit(): void {
    this.subscriptions.add(
      this.responsive
        .observe([
          Breakpoints.HandsetPortrait,
          Breakpoints.HandsetLandscape,
          Breakpoints.TabletPortrait,
          Breakpoints.TabletLandscape,
          Breakpoints.Web,
        ])
        .subscribe((result) => {
          const breakpoints = result.breakpoints;
          this.isMobilePortrait = breakpoints[Breakpoints.HandsetPortrait];
          this.isMobileLandscape = breakpoints[Breakpoints.HandsetLandscape];
          this.isMobile = this.isMobilePortrait || this.isMobileLandscape;
          this.isTabletPortrait = breakpoints[Breakpoints.TabletPortrait];
          this.isTabletLandscape = breakpoints[Breakpoints.TabletLandscape];
          this.isWeb =
            breakpoints[Breakpoints.WebLandscape] ||
            breakpoints[Breakpoints.WebPortrait];

          const isVerticalMenuOpen =
            (this.isWeb || this.isTabletLandscape) && !this.verticalMenuOpen;
          if (isVerticalMenuOpen) {
            this.toggleVerticalMenu();
          }
        }),
    );

    this.subscriptions.add(
      this.router.events
        .pipe(
          filter(
            (event): event is NavigationEnd => event instanceof NavigationEnd,
          ),
        )
        .subscribe((val) => {
          this.organizationsPage = false;
          this.applicationsPage = false;
          this.applicationsDetailPage = false;
          if (val.url.includes('/organizations') || val.url === '/') {
            this.organizationsPage = true;
          } else if (val.url.includes('/applications')) {
            this.applicationsPage = true;
            if (val.url.includes('/applications/')) {
              this.applicationsDetailPage = true;
            }
          }
        }),
    );

    this.clusters$ = this.store$.select((state) => {
      return state.Application.clusters as Cluster[];
    });

    this.subscriptions.add(
      this.clusters$?.subscribe((data: Cluster[]) => {
        if (data) {
          this.applications = data
            .map((cluster) => cluster.applications)
            .flat()
            .sort((a, b) => a.name.localeCompare(b.name));
        }
      }),
    );
  }

  openApplicationDetails(applicationId: number): void {
    this.router.navigate(['/applications', applicationId]);
  }

  toggleVerticalMenu(): void {
    this.toggleVerticalMenuEvent.emit();
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
