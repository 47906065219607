import { CommonModule } from '@angular/common';
import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateModule } from '@ngx-translate/core';
import { Observable, Subscription } from 'rxjs';
import { Application } from 'src/app/core/models/application.model';
import { Cluster } from 'src/app/core/models/cluster.model';
import { Feature } from 'src/app/core/models/feature.model';
import { Machine } from 'src/app/core/models/machine.model';
import { SubFeature } from 'src/app/core/models/subFeature.model';
import { UnitLicences } from 'src/app/core/models/unitLicences.model';
import { DialogService } from 'src/app/core/services/dialog/dialog.service';
import { EditLicencesAction } from 'src/app/core/store/organization/actions';
import { State } from 'src/app/core/store/state';
import { SnackbarComponent } from 'src/app/shared/components/snackbar/snackbar.component';
import { SnackbarType } from 'src/app/shared/services/snackbar/core/snackbar-type.enum';
import { SnackbarService } from 'src/app/shared/services/snackbar/snackbar.service';
import { UnitApplicationComponent } from '../unit-application/unit-application.component';

@Component({
  selector: 'rh-admincenter-unit-clusters',
  templateUrl: './unit-clusters.component.html',
  styleUrls: ['./unit-clusters.component.scss'],
  imports: [CommonModule, TranslateModule, UnitApplicationComponent],
})
export class UnitClustersComponent implements OnInit, OnDestroy {
  @Input({ required: true }) organizationId!: number;
  @Input({ required: true }) unitId!: number;
  @Input({ required: true }) clusters!: Cluster[];
  @Input({ required: true }) canUpdateLicences!: boolean;
  @Input({ required: true }) machines!: Machine[];

  features: Map<number, Feature> = new Map();
  subFeatures: Map<number, SubFeature> = new Map();
  applications: Map<number, Application> = new Map();

  unit$!: Observable<UnitLicences>;
  unit?: UnitLicences;
  error$!: Observable<string>;

  lastOperation?: {
    isAssign: boolean;
    isFeature: boolean;
    featureId: number;
  } = undefined;

  isOpen = false;

  filteredClusters: Cluster[] = [];

  private subscriptions: Subscription = new Subscription();

  constructor(
    private store$: Store<State>,
    public dialog: DialogService,
    private snackbar: SnackbarService,
  ) {}

  ngOnInit(): void {
    this.unit$ = this.store$.select((state) => {
      return state.Organization.units?.find(
        (unit) => unit.id === this.unitId,
      ) as UnitLicences;
    });

    this.subscriptions.add(
      this.unit$.subscribe((data) => {
        if (!data) return;
        this.unit = data;

        if (!this.unit) return;

        this.features = new Map(
          this.unit.licences.flatMap((licence) =>
            licence.applications.flatMap((application) =>
              application.features.map((feature) => [feature.id, feature]),
            ),
          ),
        );

        this.subFeatures = new Map(
          this.unit.licences.flatMap((licence) =>
            licence.applications.flatMap((application) =>
              application.features.flatMap((feature) =>
                feature.subFeatures.map((subFeature) => [
                  subFeature.id,
                  subFeature,
                ]),
              ),
            ),
          ),
        );

        this.applications = new Map(
          this.unit.licences.flatMap((licence) =>
            licence.applications.map((application) => [
              application.id,
              application,
            ]),
          ),
        );

        if (!this.canUpdateLicences) {
          this.filteredClusters = this.clusters.filter((cluster) =>
            cluster.applications.some((application) =>
              this.applications.has(application.id),
            ),
          );

          this.filteredClusters.forEach((cluster) => {
            cluster.applications.forEach((application) => {
              application.features.forEach((feature) => {
                if (feature.key === 'elearning_brochure') {
                  console.log(feature);
                }
                feature.subFeatures = feature.subFeatures.filter((subFeature) =>
                  this.findSubFeature(subFeature.id),
                );
              });

              application.features = application.features.filter(
                (feature) =>
                  feature.subFeatures.length > 0 ||
                  this.findFeature(feature.id),
              );
            });
          });

          console.log(this.filteredClusters);
        } else {
          this.filteredClusters = this.clusters;
        }
      }),
    );

    this.error$ = this.store$.select(
      (state) => state.Organization.error as string,
    );

    this.subscriptions.add(
      this.error$.subscribe((error) => {
        if (!error) return;

        if (this.lastOperation) {
          const inputField = document.querySelector(
            this.lastOperation.isFeature
              ? `#unit${this.unitId}feature${this.lastOperation.featureId}`
              : `#unit${this.unitId}subFeature${this.lastOperation.featureId}`,
          ) as HTMLInputElement;
          if (inputField) {
            if (this.lastOperation.isAssign) inputField.checked = false;
            else inputField.checked = true;
          }

          this.lastOperation = undefined;
        }
      }),
    );
  }

  toggle() {
    this.isOpen = !this.isOpen;
  }

  findFeature(featureId: number) {
    return this.features.has(featureId);
  }

  findSubFeature(subFeatureId: number) {
    return this.subFeatures.has(subFeatureId);
  }

  getClustersForOverview() {
    const clustersToFind = ['academy', 'monitoring'];
    const applicationsToFind = ['elearning', 'videos', 'argus', 'metrology'];

    const clusters = this.clusters
      .filter((cluster) => clustersToFind.includes(cluster.name.toLowerCase()))
      .sort();

    clusters.forEach((cluster) => {
      cluster.applications
        .filter((application) => {
          applicationsToFind.includes(application.name.toLowerCase());
        })
        .sort();
    });

    return clusters;
  }

  getFeatureLicenceId(featureId: number) {
    return this.features.get(featureId)?.licenceId;
  }

  getSubFeatureLicenceId(subFeatureId: number) {
    return this.subFeatures.get(subFeatureId)?.licenceId;
  }

  getValidTo(applicationId: number) {
    return this.applications.get(applicationId)?.validTo;
  }

  editLicenceValidTo(
    applicationId: number,
    validTo: string,
    licenceApplication?: Application,
  ) {
    if (!licenceApplication) {
      this.applications.set(applicationId, {
        id: applicationId,
        features: [] as Feature[],
        validTo: validTo,
      } as Application);
      return;
    }

    if (licenceApplication.validTo === validTo) return;

    if (licenceApplication.features.length === 0) return;

    this.store$.dispatch(
      new EditLicencesAction(
        this.unitId,
        validTo,
        licenceApplication.isInvoiceSent || false,
        licenceApplication.id,
      ),
    );
  }

  updateIsInvoiceSent(applicationId: number, licenceApplication?: Application) {
    if (!licenceApplication) {
      this.snackbar.show(SnackbarComponent, {
        type: SnackbarType.Error,
        text: 'errors.licences.noValidTo',
      });
      return;
    }

    if (licenceApplication.features.length === 0) return;
    const isInvoiceSent = !licenceApplication.isInvoiceSent;

    const imgField = document.getElementById(
      `img-application-${applicationId}`,
    ) as HTMLImageElement;
    if (imgField) {
      imgField.src = isInvoiceSent
        ? '/assets/icons/Icon_Invoice_warning.svg'
        : '/assets/icons/Icon_Invoice_default.svg';
    }

    this.store$.dispatch(
      new EditLicencesAction(
        this.unitId,
        licenceApplication.validTo || '',
        isInvoiceSent,
        licenceApplication.id,
      ),
    );
  }

  getApplicationColor(applicationId: number) {
    const status = this.applications.get(applicationId)?.status;

    switch (status) {
      case 'DANGER':
        return 'bg-[#f5d2d0] text-[#b60b00]';
      case 'WARNING':
        return 'bg-[#fdeabd] text-[#ce8400]';
      case 'OK':
        return 'bg-[#e3f1d1] text-[#69932f]';
      default:
        return 'bg-[#f4f4f4] text-[#949494]';
    }
  }

  ngOnDestroy(): void {
    this.subscriptions.unsubscribe();
  }
}
