<div class="flex w-full flex-col gap-6 border p-4">
  <!-- Application Collapsed -->
  <button class="flex w-full gap-4" (click)="toggle()">
    <div class="w-[200px] shrink-0 text-left uppercase text-[#3c5792]">
      {{ application.name }}
    </div>
    <div class="flex grow gap-4">
      <!-- Valid to -->
      <div
        class="relative flex h-10 w-[230px] items-center rounded-md border border-brand-blue-240 pl-2 font-brand-regular text-brand-blue-240"
      >
        <img
          src="/assets/icons/calendar.svg"
          alt="Calendar"
          class="z-10 mr-2 aspect-auto h-6"
        />
        <span class="z-10 text-[10px] uppercase">
          {{ "unitApplications.validTo" | translate }}
        </span>
        <input
          (click)="$event.stopPropagation()"
          type="date"
          id="startDate"
          class="absolute left-0 top-0 z-10 h-full w-full border-none bg-transparent pr-2 text-end text-[14px] focus:border-0 focus:bg-transparent focus:outline-none focus:ring-0"
          [value]="licenceApplication?.validTo | date: 'yyyy-MM-dd'"
          (change)="editLicenceValidTo($event)"
          required
        />
      </div>
      <!-- Invoice checkbox -->
      @if (
        canUpdateLicences && licenceApplication && licenceApplication.validTo
      ) {
        <button
          (click)="updateIsInvoiceSent(); $event.stopPropagation()"
          class="flex h-10 cursor-pointer items-center gap-2 rounded-md border border-brand-blue-240 p-2"
        >
          <img
            [id]="'img-application-' + application.id"
            [src]="
              licenceApplication.isInvoiceSent
                ? '/assets/icons/Icon_Invoice_warning.svg'
                : '/assets/icons/Icon_Invoice_default.svg'
            "
            alt="Invoice"
            class="h-6"
          />
          <div
            class="flex aspect-square w-6 shrink-0 items-center border bg-white p-1 has-[img]:border-none has-[img]:bg-brand-warning"
          >
            @if (licenceApplication.isInvoiceSent) {
              <img
                src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                alt="Checkmark"
                class="aspect-auto w-full"
              />
            }
          </div>
        </button>
      }
    </div>

    <div class="flex w-[50px] shrink-0 justify-end">
      <img
        src="/assets/icons/chevron-down.svg"
        width="22px"
        alt="chevron"
        [ngClass]="isOpen ? 'rotate-180' : 'rotate-0'"
      />
    </div>
  </button>

  @if (isOpen) {
    <div class="w-full break-all font-medium uppercase">
      <!-- With subfeatures -->
      @if (
        application.features.length > 0 &&
        application.features[0].subFeatures.length > 0
      ) {
        <div class="flex flex-col divide-y">
          @for (feature of application.features; track feature) {
            <div class="flex gap-4 py-4 first:pt-0 last:pb-0">
              <div class="w-[200px] shrink-0 text-[#7a93cb]">
                {{ feature.name }}
              </div>
              <div class="grid grow grid-cols-4 gap-2">
                @for (subFeature of feature.subFeatures; track subFeature) {
                  @let licenceSubFeature =
                    findSubFeature(feature.id, subFeature.id);
                  @if (
                    canUpdateLicences ||
                    (!canUpdateLicences && licenceSubFeature)
                  ) {
                    <button
                      (click)="
                        updateLicence(
                          subFeature.id,
                          licenceSubFeature?.licenceId
                        )
                      "
                      [disabled]="!canUpdateLicences"
                      class="flex h-full w-full items-center justify-between gap-2 bg-[#f4f4f4] p-2 text-[#949494] has-[img]:bg-[#E3F1D1] has-[img]:text-[#69932f]"
                    >
                      <p class="w-full text-left">
                        {{ subFeature.name }}
                      </p>
                      <div
                        class="flex aspect-square w-[34px] shrink-0 items-center rounded-sm bg-white p-2 has-[img]:bg-[#86a337]"
                      >
                        @if (licenceSubFeature) {
                          <img
                            src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                            alt="Checkmark"
                            class="aspect-auto w-full"
                          />
                        }
                      </div>
                    </button>
                  }
                }
              </div>
            </div>
          }
        </div>
      } @else if (
        application.features.length > 0 &&
        application.features[0].subFeatures.length === 0
      ) {
        <div class="flex gap-4">
          <div class="w-[200px] shrink-0">&nbsp;</div>
          <div class="grid h-fit grow grid-cols-4 gap-2">
            @for (feature of application.features; track feature) {
              @let licenceFeature = findFeature(feature.id);
              @if (
                canUpdateLicences || (!canUpdateLicences && licenceFeature)
              ) {
                <button
                  (click)="updateLicence(feature.id, licenceFeature?.licenceId)"
                  [disabled]="!canUpdateLicences"
                  class="flex h-full w-full items-center justify-between gap-2 bg-[#f4f4f4] p-2 text-[#949494] has-[img]:bg-[#E3F1D1] has-[img]:text-[#69932f]"
                >
                  <p class="w-full text-left">{{ feature.name }}</p>
                  <div
                    class="flex aspect-square w-[34px] shrink-0 items-center rounded-sm bg-white p-2 has-[img]:bg-[#86a337]"
                  >
                    @if (licenceFeature) {
                      <img
                        src="/assets/icons/Reishauer_Icon_Checkmark.svg"
                        alt="Checkmark"
                        class="aspect-auto w-full"
                      />
                    }
                  </div>
                </button>
              }
            }
          </div>
        </div>
      }

      @if (application.name.toLowerCase() === "argus") {
        <div class="flex gap-4 py-4 first:pt-0 last:pb-0">
          <div class="w-[200px] shrink-0 text-[#7a93cb]">Machines</div>
          <table class="w-full table-fixed border-collapse divide-y">
            <thead>
              <tr>
                <th>
                  <div class="text-left">
                    <p>M-Nr.</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>Client Alias</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>Type</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>IP</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>Proxy-ID</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>Mobile</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>Argus licence duration</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>Argus invoice</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>MaaS licence duration</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>MaaS invoice</p>
                  </div>
                </th>
                <th>
                  <div class="text-left">
                    <p>Status</p>
                  </div>
                </th>
              </tr>
            </thead>
            <tbody class="divide-y">
              @for (machine of machines; track $index) {
                @let machineStatus =
                  getMachineStatus(
                    machine.argusLicenseValidTo,
                    machine.maasLicenseValidTo
                  );
                <tr>
                  <td class="py-1">{{ machine.machineNumber }}</td>
                  <td class="py-1">{{ machine.customerName }}</td>
                  <td class="py-1">{{ machine.type }}</td>
                  <td class="py-1">{{ machine.ipAddress }}</td>
                  <td class="py-1">{{ machine.proxyId }}</td>
                  <td class="py-1">{{ machine.isMobile }}</td>
                  <td class="py-1">{{ machine.argusLicenseValidTo }}</td>
                  <td class="py-1">{{ machine.argusInvoice }}</td>
                  <td class="py-1">{{ machine.maasLicenseValidTo }}</td>
                  <td class="py-1">{{ machine.maasInvoice }}</td>
                  <td class="py-1">
                    @switch (machineStatus) {
                      @case ("DANGER") {
                        <span class="status-danger">Danger</span>
                      }
                      @case ("WARNING") {
                        <span class="status-warning">Warning</span>
                      }
                      @case ("OK") {
                        <span class="status-info">OK</span>
                      }
                      @default {
                        <span class="status-info"></span>
                      }
                    }
                  </td>
                </tr>
              }
            </tbody>
          </table>
        </div>
      }
    </div>
  }
</div>
